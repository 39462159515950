import React from 'react'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'

function Dashboard() {
  return (
    <div className='row justify-content-center m-0 pt-2' >
        <div className='col-12 mb-2' >
            <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center primary-gradient text-white rounded-3 mt-1 mx-1 py-1" >
                    <Heading className="text-white">
                Operations
                    </Heading>
                    <div>Alerts</div>
                </CardHeader>
                <CardBody className="row p-2">
                    <div className='col-4'>
                        <InnerCard className="text-center">3226 <br/> Devices</InnerCard>
                    </div>
                    <div className='col-4'>
                        <InnerCard className="text-center">68 <br/> Data Points</InnerCard>
                    </div>
                    <div className='col-4'>
                        <InnerCard className="text-center">240 <br/>users</InnerCard>
                    </div>
                </CardBody>
            </Card>
           
        </div>
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Devices
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   power
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Hub
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Agri
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Water
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Atmosphere
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
        
        <div className='col-4 mb-2'>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                   Identifier
                    <i className='fa fa-angle-down'></i>
                </CardHeader>
                <CardBody>
                </CardBody>
            </Card>
        </div>
    </div>
  )
}


export default Dashboard