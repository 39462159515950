import React, { useEffect, useState } from 'react'
import CardLoader from '../../components/loader/cardLoader'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import Select from '../../components/select/select'
import { GetRoots, getAllEvents, getHubsByLevelId } from '../../services/utilities/events'
import { Sort, SortItems, getDate, getTime } from '../../functions/functions'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Events } from '../../services/reducers/lastEventReducer'
import moment from 'moment'
import Modal from './tab/modal'
import Mypopup from '../../components/popup/mypopup'
import Input from '../../components/input/input'

const MQTT = (props) => {
    const [popup, setPopup] = useState(null)
    const [searchHubs, setSearchHubs] = useState('')
    const dispatch = useDispatch()
    const lastEvents = useSelector(state => state.getLastEvent)
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');

    // const [loader, setLoader] = useState(true)
    const [rootLevel, setRootLevel] = useState([])
    const [id, setId] = useState()

    const [state, setState] = useState({
        loader: true,
        hubList: []
    })

    useEffect(() => {
        const fetchEvents = async () => {
            if (id) {
                const response = await getAllEvents(id);
                dispatch(Get_Events(response));
            }
        };
        if (id) {
            fetchEvents();
        }
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            await rootLevelGet();
        };
        fetchData();
    }, []);


    const rootLevelGet = async () => {
        let root = await GetRoots()
        let organization = []
        root.length && root.map(org => {
            organization.push({ label: org, value: org })
        })

        setRootLevel(organization)
        setState((prevState) => ({
            ...prevState,          // Spread the previous state to keep other properties
            loader: false           // Set the loader state to false
        }));
    }


    const onChangeHandler = async (e) => {
        setState((prevState) => ({
            ...prevState,          // Spread the previous state to keep other properties
            loader: true           // Set the loader state to false
        }));

        let name = e.name
        if (name === "organization") {
            const List = await getHubsByLevelId(e.target.value)
            setId(e.target.value)
            setState((prevState) => ({
                ...prevState,          // Spread the previous state to keep other properties
                hubList: List           // Set the loader state to false
            }));
        }

        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,          // Spread the previous state to keep other properties
                loader: false           // Set the loader state to false
            }));
        }, 2000)
    }
    const OpenModal = (index) => {
        let state1 = { ...state }
        if ("modal" in state1.hubList[index]) {
            state1.hubList[index]["modal"] = !state1.hubList[index].modal
        } else {
            state1.hubList[index]["modal"] = true
        }
        setState(state1)
    }
    useEffect(() => {
        let state1 = { ...state }
        state1?.hubList?.map((hub, key) => {
            lastEvents?.lastEvent?.devices?.map(event => {
                const eventId = event._id.split("_")
                if (eventId[0] + "_" + eventId[1] === hub._id) {
                    hub["event"] = event.events
                }
            })
        })
        setState(state1)
    }, [lastEvents])

    let filterHubs = []
    if (state.hubList.length !== 0) {

        filterHubs = state.hubList.filter(item => {
            if (item.name) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) || item.name.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        })
    }
    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = SortItems(type, filterHubs, objkey)
        // console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        // Set sortedUsers when filterHubs changes
        setSortedUsers(filterHubs);
    }, [state.hubList, searchHubs]);

    return (
        <div className='p-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className="d-flex justify-content-between">
                    <Heading>
                        MQTT
                    </Heading>
                </CardHeader>
                <div className='col-12 mb-3 '>
                    <Select label="Select Organization" name="organization" onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                </div>
                {state.hubList.length ?
                    <div className='col-12 mb-3'>
                        <Input label="Serach Hub" name="search" onChange={(e) => setSearchHubs(e.target.value)} />
                    </div>
                    : null}</Card>

            {state.loader ? <CardLoader /> :
                <InnerCard>
                    <Card className="mb-3 position-sticky" >

                        <CardHeader className="row m-0">
                            <div className='col-1 col-sm-1 col-lg-1' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('status') ? 'status-asc' : 'status-' + (sortType.includes('asc') ? 'des' : 'asc')), 'status')}>
                                <Heading className="d-flex gap-2">Status
                                    <div className='ml-2'>
                                        {sortType.includes('status') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }
                                    </div>
                                </Heading>
                            </div>

                            <div className='col-2 col-sm-2 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-id-asc' : 'text-id-' + (sortType.includes('asc') ? 'des' : 'asc')), '_id')}>

                                <Heading className="d-flex gap-2">ID
                                    <div className='ml-2'>

                                        {sortType.includes('id') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>

                                <Heading className="d-flex gap-2">Name
                                    <div className='ml-2'>

                                        {sortType.includes('nam') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 px-0 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-typ-asc' : 'text-typ-' + (sortType.includes('asc') ? 'des' : 'asc')), 'type')}>

                                <Heading className="d-flex gap-2">Type
                                    <div className='ml-2'>

                                        {sortType.includes('typ') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-level-asc' : 'text-level-' + (sortType.includes('asc') ? 'des' : 'asc')), 'levelId')}>

                                <Heading className="d-flex gap-2">LevelID
                                    <div className='ml-2'>

                                        {sortType.includes('level') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-1 col-sm-1 col-lg-2 d-flex justify-content-center' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc')), 'created')}>

                                <Heading className="d-flex gap-2">Created
                                    <div className='ml-2'>

                                        {sortType.includes('date') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                        </CardHeader>
                    </Card>

                    <CardBody >

                        {id ?
                            filterHubs.length ?
                                sortedUsers.map((hub, index) =>
                                    <Card key={index} className="mb-2 position-sticky w-100">
                                        <CardHeader className='row  m-0 align-items-center'>
                                            <>
                                                <div className='col-1  col-sm-1 col-lg-1' onClick={() => OpenModal(index)}>
                                                    {/* <div className='align-self-center' style={{ width: 10, height: 10, borderRadius: 50, marginLeft: 10, backgroundColor: "green" }} title={"Connected"}></div> */}

                                                    {/* <div className='align-self-center' style={{ width: 10, height: 10, borderRadius: 50, marginLeft: 10, backgroundColor: "event" in hub ? hub?.event?.value === 1 ? "green" : "gray" : "gray" }} title={"event" in hub ? hub?.event?.value === 1 ? "Connected Since: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + "00:00:00 AM 00-00-0000"}></div> */}

                                                    <i
                                                        className="fas fa-circle"
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: 50,
                                                            marginLeft: 10,
                                                            color: "event" in hub ? hub?.event?.value === 1 ? "#2ec106" : "#d3d3d3" : "#d3d3d3"
                                                        }}
                                                        title={"event" in hub ? hub?.event?.value === 1 ? "Connected Since: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + "00:00:00 AM 00-00-0000"}
                                                    ></i>
                                                </div>
                                                <div className="col-2 col-sm-2 col-lg-2" onClick={() => OpenModal(index)}>
                                                    {hub._id}
                                                </div>
                                                <div className="col-3 col-sm-3 col-lg-2" onClick={() => OpenModal(index)}>
                                                    {hub.name}
                                                </div>
                                                <div className="col-3  col-sm-3 col-lg-2" onClick={() => OpenModal(index)}>
                                                    {hub.type}
                                                </div>
                                                <div className="col-2 col-sm-3 col-lg-3" onClick={() => OpenModal(index)}>
                                                    {hub.levelId}
                                                </div>
                                                <div className="col-2 col-sm-2 col-lg-2" onClick={() => OpenModal(index)}>
                                                    {moment(hub.created).format("ll LTS")}
                                                </div>

                                                {hub.modal ?
                                                    <Modal hub={hub} popup={(e) => { setPopup(e); }} /> : null}
                                            </>
                                        </CardHeader>
                                    </Card>
                                )
                                :
                                <p className='ml-3'>No Hubs Available</p>
                            : <p className='ml-3'>Select Organization</p>
                        }
                    </CardBody>
                </InnerCard>
            }
            {
                popup ?
                    <Mypopup title={"Event"} onClose={() => { setPopup(null) }}>
                        <div className='p-3'>
                            <code>
                                {JSON.stringify("data" in popup ? popup.data : popup.eve)}
                            </code>
                            {/* // className='form-control'
                            height={120}
                            type="textarea"
                            defaultValue={JSON.stringify(popup)} // Convert metadata object to JSON string
                            disabled
                            label='JSON' */}
                            {/* /> */}
                        </div>
                    </Mypopup> : null
            }
        </div >

    )
}


export default MQTT
