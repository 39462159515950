  import React, { useEffect, useState } from "react";
  import "./lightDarkButton.scss";
  import Cookies from 'js-cookie';
  import store from "../../services/utilities/store";


  function LightDarkButton() {
    const [isLight, setIsLight] = useState(true);
    const [CookieData, setCookieData] = useState('default');
    useEffect(() => {
      const cookie = Cookies.get('theme')
      if (cookie === "undefined" || cookie === "default") {
        changeTheme("default")
      } else {
        changeTheme(cookie)
      }
    }, []);
    useEffect(() => {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (event) => {
        setIsLight(!event.matches);
      };
      if (CookieData === 'default') {
        setIsLight(!mediaQuery.matches);
        mediaQuery.addListener(handleChange);
      }
      return () => {
        mediaQuery.removeListener(handleChange);
      };
    }, [CookieData])

    useEffect(() => {
      let element = document.getElementsByTagName('body')[0]
      if (isLight) {
        element.setAttribute("class", "")
      } else {
        element.setAttribute("class", "dark-theme")
      }
      store.dispatch({
        type: "themeMode",
        payload: isLight
      })
    }, [isLight])

    const changeTheme = (value) => {
      Cookies.set('theme', value)
      setCookieData(value)
      if (value === "default") {
        if (CookieData !== "default" && CookieData !== undefined) {
          setIsLight((prevIsLight) => !prevIsLight);
        } else {
          const systemPrefersDark=window.matchMedia('(prefers-color-scheme: dark)').matches
          setIsLight(!systemPrefersDark);
        }
      } else {
        setIsLight(value === 'light');
      }
    }

 
    return (
      <div className="d-flex w-100">
        <div className="w-100">
          <button className="w-100 userdropdown d-flex justify-content-between" onClick={() => changeTheme('light')}><span><i className="mr-2 fa fa-sun"></i> Light</span> {isLight ? <i className="ml-2 fa fa-check"></i> : null}</button>
          <button className="w-100 userdropdown d-flex justify-content-between" onClick={() => changeTheme('dark')}><span><i className="mr-2 fa fa-moon"></i> Dark</span> {!isLight ? <i className="ml-2 fa fa-check"></i> : null}</button>
        </div>
        <button className="userdropdown split-l text-center" onClick={() => changeTheme('default')}><span><i className={"far fa-" + (CookieData === 'default' ? "check-" : "") + "square"}></i><br /> System Default</span></button>
      </div>
      
    );
  }

  export default LightDarkButton;
