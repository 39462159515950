import React from 'react'
import './layout.scss'

import PoweredLogo from '../../assets/svgs/logo.svg'
import YouTube from '../../assets/img/youtube.png'
import LinkedIn from '../../assets/img/linkedin.webp'
import Facebook from '../../assets/img/facebook.webp'
import { Link, useLocation } from 'react-router-dom'

function Layout(props) {
    let { pathname } = useLocation();
  return (
    <div className='userPage'>
        {/* <svg style={{position: 'absolute'}}>
            <def>
                <filter id='goo'>
                    <feGaussianBlur in='SourceGraphic' stdDeviation='30'/>
                    <feColorMatrix in='name' mode='metrix' values='
                        1 0 0 0 0
                        0 1 0 0 0
                        0 0 1 0 0
                        0 0 0 100 -45
                    '/>
                    <feBlend in='SourceGraphic'/>
                </filter>
            </def>
        </svg> */}
        <div className="userContainer"
            style={{ minHeight: "100%", }}
        >
            <div className='bubbleCon'>
            <div className='bubbleConInner'>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
                <div className='bubble0'></div>
                <div className='bubble1'></div>
            </div>
            </div>
            <div className={"userCard"+(
                !pathname.match("/signin") 
                && !pathname.match("/forgot-password")
                && !pathname.match("/signup")
                && !pathname.match("/verify-email")
                && !pathname.match("/create-password")
                ?' fullScreen': '')}>
                <div className='userCardBlur'></div>
                {/* <div className='miniNav'>
                    <Link className={pathname.match("/plans")?'active':''} to='/plans'>Plans</Link>
                    <Link className={pathname.match("/contact")?'active':''} to='/contact'>Contact</Link>
                    <Link className={pathname.match("/about")?'active':''} to='/about'>About</Link>
                    {!pathname.match("/signin")?
                        <Link id='signIn' to='/signin'>Sign In</Link>
                    :null}
                </div> */}
                {/* <div className='miniFooter'>
                    <div>
                        <a href='https://linkedthings.ai'><img src={YouTube}/></a>
                        <a href='https://linkedthings.ai/contact/'><img src={LinkedIn}/></a>
                        <a href='https://linkedthings.ai/about-us/'><img src={Facebook}/></a>
                    </div>
                    <div className='miniFooterNav'>
                        <Link className={pathname.match("/privacy-policy")?'active':''} to='/privacy-policy'>Privacy & Policy</Link>|
                        <Link className={pathname.match("/terms-of-use")?'active':''} to='/terms-of-use'>Terms of use</Link>
                    </div>
                </div> */}
                {/* <a href='https://linkedthings.ai' className='poweredLogo'><h5>Powered By</h5><img src={PoweredLogo}/></a> */}
                {props.children}
            </div>
        </div>
    </div>
  )
}

export default Layout