import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const logReducer = createSlice({
    name: 'LOG',
    initialState,
    reducers: {
        ADD_LOGS: (state, action) => {
            state.deviceLogs = action.payload
        }
    }
})
export const { ADD_LOGS } = logReducer.actions

export default logReducer.reducer
