import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { useEffect, useState } from "react";
import { ConfigrationDevice, delHubs, getResource } from "../../services/utilities/events";
import Mypopup from "../../components/popup/mypopup";
import AddConfigLevel from "../../components/addConfigLevel/AddConfigLevel";
import Input from "../../components/input/input";
import { getImgUrl } from "../../services/config/serverAddresses";
import { ResponseMessageLocal } from "../../services/config/generic";

const Devices = (props) => {
    // State hooks
    const [loader, setLoader] = useState(false);
    const [addHub, setAddHub] = useState(null);
    const [hubList, setHubList] = useState([]);
    const [msgPopup, setMsgPopup] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [rollHub, setRollHub] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [iconPopup, setIconPopup] = useState({ visible: false, iconUrl: null, message: null });

    // Sorting state
    const [sortCriteria, setSortCriteria] = useState('name'); // Default sorting by 'name'
    const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order is ascending

    const LevelsGet = async () => {
        setAddHub(null);
        setLoader(true);
        let hubs = await ConfigrationDevice("level");
        setHubList(hubs);
        setLoader(false);
    };

    useEffect(() => {
        LevelsGet();
    }, []);

    const addEdit = (data = null) => {
        if (data) {
            setAddHub(data);
            setRollHub(data);
        } else {
            setAddHub(true);
            setRollHub(null);
        }
    };

    const deleteAccess = async (id, key) => {
        let result = await delHubs(id);
        setHubList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    };

    // Sorting function
    const sortHubList = (list) => {
        return list.sort((a, b) => {
            let aValue = (a.config[sortCriteria] || '').toString().toLowerCase();
            let bValue = (b.config[sortCriteria] || '').toString().toLowerCase();

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });
    };

    // Filtered and sorted hub list
    const sortedAndFilteredHubList = sortHubList(
        hubList.filter(level =>
            level.config?.type.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const imgRenderFunc = async (src, _id, type) => {
        let image = null;
        if (src) {
            if (src.endsWith(".svg")) {
                image = await getResource(src);
                image = { icon: image, type: 'svg' };
            } else if (src.endsWith(".js")) {
                let imagecheck = await getResource(src);
                let imageFunction = eval("(" + imagecheck + ")");
                if (typeof imageFunction === 'function') {
                    image = imageFunction({ _id, type });
                }
                if (typeof image === 'object' && image !== null && 'image' in image) {
                    image = image.image;
                }
                image = { icon: image, type: 'js' };
            } else {
                image = { icon: getImgUrl + src, type: 'img' };
            }
        }
        return image;
    };

    const imgReturnFunc = (data) => {
        if (data) {
            let img = data;
            if (img.type === "svg") {
                return "data:image/svg+xml;base64," + btoa(img.icon);
            } else if (img.type === "js") {
                if (typeof img.icon === "function") {
                    const svgString = img.icon({ value: 0 }); // Generate SVG string
                    return "data:image/svg+xml;base64," + btoa(svgString); // Convert SVG string to base64
                } else {
                    console.error("img.icon is not a function for type 'js'");
                    return null;
                }
            } else {
                return img.icon;
            }
        }
        return null;
    };

    const handleViewIcon = async (iconSrc, _id, type) => {
        if (!iconSrc) {
            setIconPopup({ visible: true, iconUrl: null, message: "Icon source is not available" });
            return;
        }

        try {
            const iconData = await imgRenderFunc(iconSrc, _id, type);
            const iconUrl = imgReturnFunc(iconData);
            setIconPopup({ visible: true, iconUrl });
        } catch (error) {
            console.error("Error handling icon:", error);
            setIconPopup({ visible: true, iconUrl: null, message: "Error displaying icon" });
        }
    };

    const handleCloseIconPopup = () => {
        setIconPopup({ visible: false, iconUrl: null, message: null });
    };

    // Toggle sorting order
    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className='mt-2 px-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Levels</Heading>
                        </div>
                        <div>
                            <Input
                                type="text"
                                label="Search Locations"
                                placeholder="Search by type..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button onClick={() => addEdit()}>Add Level</Button>
                            </ButtonContainer>
                        </div>
                        
                    </div>
                </CardHeader>
            </Card>

            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col-3 col-sm-3 col-lg-3'><Heading>S.No</Heading></div>
                    <div className='col-4 col-sm-6 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => {
                        const newOrder = sortCriteria === 'type' ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
                        setSortCriteria('type');
                        setSortOrder(newOrder);
                    }}>
                        <Heading className="d-flex gap-2">Type
                            <div className='ml-2'>
                                {sortCriteria === 'type' ? (
                                    sortOrder === 'asc' ? (
                                        <i className="fas fa-sort-down"></i>
                                    ) : (
                                        <i className="fas fa-sort-up"></i>
                                    )
                                ) : (
                                    <i style={{ color: 'lightgrey' }} className="fas fa-sort"></i>
                                )}
                            </div>
                        </Heading>
                    </div>
                    <div className='col-4 col-sm-6 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => {
                        const newOrder = sortCriteria === 'name' ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
                        setSortCriteria('name');
                        setSortOrder(newOrder);
                    }}>
                        <Heading className="d-flex gap-2">Name
                            <div className='ml-2'>
                                {sortCriteria === 'name' ? (
                                    sortOrder === 'asc' ? (
                                        <i className="fas fa-sort-down"></i>
                                    ) : (
                                        <i className="fas fa-sort-up"></i>
                                    )
                                ) : (
                                    <i style={{ color: 'lightgrey' }} className="fas fa-sort"></i>
                                )}
                            </div>
                        </Heading>
                    </div>
                    <div className='col-4 col-sm-6 col-lg-1'><Heading>Icon</Heading></div>
                    <div className='col-5 col-sm-3 col-lg-18 d-flex justify-content-end' style={{ width: "100px" }}><Heading>Action</Heading></div>
                </CardHeader>
            </Card>

            {sortedAndFilteredHubList.length > 0 ? (
                sortedAndFilteredHubList.map((level, key) =>
                    <Card className="overflow-hidden mt-1" key={level._id}>
                        <CardHeader className='row m-0 align-items-center'>
                            <div className='col-3 col-sm-3 col-lg-3'>{key + 1}</div>
                            <div className='col-2 col-sm- col-lg-3'>{level?.config?.type}</div>
                            <div className='col-2 col-sm- col-lg-2'>{level?.config?.name}</div>
                            <div className='col-lg-1'>
                                <ButtonContainer className="mr-2 p-2 btn-light" flat="light">
                                    <Button onClick={() => handleViewIcon(level?.config?.icon, level._id, level?.config?.type)}>VIEW</Button>
                                </ButtonContainer>
                            </div>
                            <div className='col-5 col-sm-3 col-lg-15 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button onClick={() => addEdit(level)}>
                                        <i className="lt lt-edit"></i>
                                    </Button>
                                    <Button>
                                        <i className="lt lt-trash" onClick={() => setConfirmPopup({ data: level._id, key })}></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
            ) : null}

            {addHub && (
                <Mypopup title={addHub._id ? "Update Locations" : "Create Locations"} onAddUser={() => setAddHub(null)} onClose={() => setAddHub(null)}>
                    <AddConfigLevel data={rollHub} onResPopup={(e) => setMsgPopup(e)} onAddUser={() => { setAddHub(null); LevelsGet() }} />
                </Mypopup>
            )}
            {confirmPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmPopup(null)}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}
            {iconPopup.visible && (
                <Mypopup title={"Icon"} onClose={handleCloseIconPopup}>
                    <div className="d-flex justify-content-center p-3">
                        {iconPopup.message ? (
                            <p>{iconPopup.message}</p>
                        ) : (
                            typeof iconPopup.iconUrl === 'string' ? (
                                <img src={iconPopup.iconUrl} alt="Device Icon" style={{ height: '300px', width: "300px", objectFit: "contain" }} />
                            ) : (
                                <div className="alert alert-danger" role="alert">
                                    Error displaying icon. Please check the icon source.
                                </div>
                            )
                        )}
                    </div>
                </Mypopup>
            )}
        </div>
    );
};

export default Devices;
