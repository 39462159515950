import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className='p-2 mb-1' style={{ background: "#f8d7da", borderColor: "#f5c6cb" }}>
                    <p className='m-2' style={{ color: "#721c24" }}>Something went wrong.</p>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary
