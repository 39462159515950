import React from 'react'
import Otto from '../../assets/svgs/Ottomatically_logo.png'

function Header(props) {
  return (
    <div className='d-flex align-items-center py-3'>
        <img src={Otto} width={50}/>
        <h2 className={'text-primary-gradient font-weight-bold m-0 ml-2' + (props?.heading?' split-r pr-4 mr-4':'')}>Ottomatically 2.0</h2>
        {props?.heading?
            <h4 className="text-center m-0 my-0 text-primary-gradient">{props?.heading}</h4>
        :null}
    </div>
  )
}

export default Header