
import { io } from "socket.io-client";
import { SocketServerBaseAddress } from "../config/serverAddresses";
import Cookies from "js-cookie";
// import Cookies from "js-cookie";
let socket;


export async function publishDeviceData(data) {
  if (socket) {
    socket.emit("deviceCmd", data)
  }
}

export default function getdata() {
  if (socket) {
    socket.disconnect();
  }
  let token = Cookies.get("token");
  socket = io(SocketServerBaseAddress, {
    auth: { token },
    transports: ["websocket"],
  });

  // socket.on("connect", () => {
  //   ////console.log("connected");
  // });
  return socket;
}

