    import React, { useEffect, useState } from 'react';
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import Input from '../../components/input/input';
import { Configurationshubs, getResource, hubUpdate, postImage } from '../../services/utilities/events';

import { SpecialCharacters } from '../../services/config/regex';
import { getImgUrl } from '../../services/config/serverAddresses';
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../card/card';
import { useLocation, useNavigate } from 'react-router-dom';
import Mypopup from '../popup/mypopup';
import { ResponseMessageupdate } from '../../services/config/generic';
import CardLoader from '../loader/cardLoader';
import Select from '../select/select';

const AddDevicePopup = (props) => {
    const location = useLocation();

    const cancel = useNavigate();
    const [logoLoader, setLogoLoader] = useState()
    const [confirmPopup, setConfirmPopup] = useState(null)
    const [imgFile, setImgFile] = useState(null)
    const [name, setName] = useState(true)
    const [type, setType] = useState(true)
    const [tab, setTab] = useState("parameter")
    const [file, setFile] = useState();
    const [activePrereq, setActivePrereq] = useState(0);
    const [activeAction, setActiveAction] = useState(0);
    const [activeParameter, setActiveParameter] = useState(0);
    const [lengthPrereq, setLengthPrereq] = useState(1);
    const [lengthAction, setLengthAction] = useState(1);
    const [lengthParameter, setLengthParameter] = useState(1);
    const [activeAlert, setActiveAlert] = useState(0)
    const [loader,setLoader] = useState(false)
    const[actionParamList,setActionParamList]=useState([])


    const addDeviceName = () => {
        let data1 = { ...branch };
        data1.config.name.push("");
        setBranch(data1);

    }
    const deleteDeviceName = (index) => {
        let data1 = { ...branch };
        data1.config.name.splice(index, 1);
        setBranch(data1);
    }


    const addUnit = () => {
        let data1 = { ...branch };
        data1.config.parameter[activeParameter].unit.push("");
        setBranch(data1);
    }
    const deleteUnit = (index) => {
        let data1 = { ...branch };
        data1.config.parameter[activeParameter].unit.splice(index, 1);
        setBranch(data1);
    }

    const addAlias = () => {
        let data1 = { ...branch }
        data1.config.parameter[activeParameter].alias.push({
            key: "", value: "", icon: ""
        })
        setBranch(data1)
    }

    const deleteAlias = (index) => {
        let data1 = { ...branch }
        data1.config.parameter[activeParameter].alias.splice(index, 1)
        setBranch(data1)

    };
    const addPrereq = () => {
        let data1 = { ...branch };
        data1.settings.prereq[activePrereq].conditions.push("");
        setBranch(data1);
    }
    const deletePrereq = (index) => {
        let data1 = { ...branch };
        data1.settings.prereq[activePrereq].conditions.splice(index, 1);
        setBranch(data1);
        // You may also want to update the state of prerequisites here
    }
    const addValue = () => {
        let data1 = { ...branch };
        data1.settings.prereq[activePrereq].values.push("");
        setBranch(data1);
    }
    const deleteValue = (index) => {
        let data1 = { ...branch };
        data1.settings.prereq[activePrereq].values.splice(index, 1);
        setBranch(data1);
    }
    const addAction = () => {
        let data1 = { ...branch };
        data1.settings.action[activeAction].values.push("");
        setBranch(data1);
    }
    const deleteAction = (index) => {
        let data1 = { ...branch };
        data1.settings.action[activeAction].values.splice(index, 1);
        setBranch(data1);
    }
    const addAlertMsgEmail = (index, type) => {
        let newLengthPrereq = (branch.settings.prereq.length + 1);
        let newLengthAction = (branch.settings.action.length + 1);
        let newLengthParameter = (branch.config.parameter.length + 1);

        let newBranch = { ...branch };

        if (type === "prereq") {
            newBranch.settings.prereq.push({
                parameter: '' ,
                conditions: [],
                values: []
            });
            setLengthPrereq(newLengthPrereq);
        }
        if (type === "action") {
            newBranch.settings.action.push({
                parameter: "" ,
                values: [],
                hubId: ""
            });
            setLengthAction(newLengthAction);
        }
        if (type === "parameter") {
            newBranch.config.parameter.push({
                // parameter: 'Parameter' + newLengthParameter,
                type: 'type' + newLengthParameter,
                alias: [],
                unit: []
            });
            setLengthParameter(newLengthParameter);
        }
        setBranch(newBranch);
    };

    const deleteMSGEMAIL = (key, type) => {
        let newBranch = { ...branch };

        if (type === "prereq") {
            newBranch.settings.prereq.splice(key, 1);
            if (activePrereq > 0) {
                ChangeStates(newBranch.settings.prereq[activePrereq - 1], activePrereq - 1, tab);
            } else {
                ChangeStates(newBranch.settings.prereq[activePrereq], activePrereq, tab);
            }
        }
        if (type === "action") {
            newBranch.settings.action.splice(key, 1);
            if (activeAction > 0) {
                ChangeStates(newBranch.settings.action[activeAction - 1], activeAction - 1, tab);
            } else {
                ChangeStates(newBranch.settings.action[activeAction], activeAction, tab);
            }
        }
        if (type === "parameter") {
            newBranch.config.parameter.splice(key, 1);
            if (activeParameter > 0) {
                ChangeStates(newBranch.config.parameter[activeParameter - 1], activeParameter - 1, tab);
            } else {
                ChangeStates(newBranch.config.parameter[activeParameter], activeParameter, tab);
            }
        }
        setBranch(newBranch);
    };

    const ChangeStates = (data, key, type) => {
        let newBranch = { ...branch };
        if (type === "prereq") {
            setActivePrereq(key);
        } else if (type === "action") {
            setActiveAction(key);
        } else if (type === "parameter") {
            setActiveParameter(key);
        }
        setBranch(newBranch);
    };

    const [branch, setBranch] = useState(
        location?.state || {
            type: "device",
            config: {
                type: "",
                name: [""],
                icon: "",
                parameter: [{
                    type: "Type1",
                    alias: [],
                    unit: []
                }],
                isControllable: false
            },
            settings: {
                prereq: [],
                action: [],
            }
        }
    )

    useEffect(() => {
        setLoader(true)
        const asyncFunction = async () => {
            console.log(location)
            if (location?.state?._id) {
                let icon = await imgRenderFunc(location.state.config?.icon);
                setImgFile(icon);
        
                // Process all parameter icons
                let parameterPromises = location.state.config.parameter.map(async (param, key) => {
                    if (param.alias.length) {
                        let aliasPromises = param.alias.map(async (alias, key2) => {
                            if (alias?.icon) {
                                let icon = await imgRenderFunc(alias.icon);
                                location.state.config.parameter[key].alias[key2]["tempIcon"] = icon;
                            }
                        });
                        await Promise.all(aliasPromises); // Wait for all alias icons to be processed
                    }
                });
        
                await Promise.all(parameterPromises); // Wait for all parameters to be processed
        
                setBranch({
                    _id: location.state._id,
                    type: location.state.type,
                    config: location.state.config,
                    settings: location.state.settings
                });
            }
        };
            setLoader(false)
        asyncFunction()
    }, []);

useEffect(() => {
  if(tab==="action"||tab==="prereq") {
          const list= branch.config.parameter.map(data=>{
            return({value: data.type, label:data.type})
          })
          setActionParamList(list)
  }
}, [tab])

    const onChangeHandler = (e, type, index = null, subIndex = null) => {
        let data = { ...branch };
        if (e.target.name === "isControllable") {
            data.config.isControllable = e.target.checked
        }
        const name = e.name;
        const value = e.target.value;

        if (type === "config") {

            if (name === "configtype") {
                data.config.type = value;
            } else if (name === "configname") {
                data.config.name[index] = value;
            } else if (name === "icon") {
                data.config.icon = value;
            } else if (name === "parametertype") {
                data.config.parameter[index].type = value;
            } else if (name === "parameteraliaskey") {
                data.config.parameter[activeParameter].alias[index].key = value;
            } else if (name === "parameteraliasvalue") {
                data.config.parameter[activeParameter].alias[index].value = value;
            } else if (name === "configicon") {
                data.config.parameter[activeParameter].alias[index].icon = value;
            } else if (name === "parameterunit") {
                data.config.parameter[activeParameter].unit[index] = value;
            }
        } else if (type === "prereq") {
            if (name === "prereqparam") {
                data.settings.prereq[activePrereq].parameter = value;
            }
            else if (name === "ParamConditions") {
                data.settings.prereq[activePrereq].conditions[index] = value;
            } else if (name === "Paramvalues") {
                data.settings.prereq[activePrereq].values[index] = value;
            }
        } else if (type === "action") {
            if (name === "actionparam") {
                data.settings.action[activeAction].parameter = value;
            } else if (name === "actionvalues") {
                data.settings.action[activeAction].values[index] = value;
            }
        }
        setBranch(data);
    };



    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }

  const imgRenderFunc = async (src) => {
    let image = null;
    console.log(src);
    if (src) {
        if (src.endsWith(".svg")) {
            image = await getResource(src);
            image = { icon: image, type: 'svg' };
        } else if (src.endsWith(".js")) {
            const imagecheck = await getResource(src);
            let imageFunction = eval("(" + imagecheck + ")");
            if (typeof imageFunction === 'function') {
                image = imageFunction({ _id: location.state._id, type: location.state.type });
            }
            if (typeof image === 'object' && image !== null && 'image' in image) {
                image = image.image;
            }
            image = { icon: image, type: 'js' };
        } else {
            image = { icon: getImgUrl + src, type: 'img' };
        }
    }
    return image;
};   
const checkName = () => {
    const special = SpecialCharacters
    if (!branch?.config.name[0] || special.test(branch?.config?.name[0])) {
        setName(false)
        return false
    }
    else {
        setName(true)
        return (true)
    }
}

    const checkType = () => {
        if (!branch.config.type) {
            setType(false)
            return false
        }
        else {
            setType(true)
            return (true)
        }
    }

    const imgReturnFunc = (data, value) => {
        if (data) {
            let img = data;
            if (img) {
                if (img.type === "svg") {
                    return "data:image/svg+xml;base64," + btoa(img.icon);
                } else if (img.type === "js") {
                    if (typeof img.icon === "function") {
                        const svgString = img.icon({ value: 0 }); // Generate SVG string
                        return "data:image/svg+xml;base64," + btoa(svgString); // Convert SVG string to base64
                    } else {
                        console.error("img.icon is not a function for type 'js'");
                        return null;
                    }
                } else {
                    return img.icon;
                }
            }
        }
        return null;
    };  
    const checkImageProperties = async (file, type = null, index = null) => {
        setLogoLoader(true)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = async () => {
                    const width = image.width;
                    const height = image.height;
                    const size = file.size;
                    const fileType = file.type;
                    if (size > 1024 * 1024) {
                        setLogoLoader(false)
                        reject("File size too large. Please select a smaller image.");
                    } else if (!fileType.startsWith("image/")) {
                        setLogoLoader(false)
                        reject("Invalid file type. Please select an image file.");
                    } else {
                        let res = await postImage(file)
                        if (type) {
                            onChangeHandler({ name: "configicon", target: { value: "/files/image/" + res.filename } }, "config", index)
                        } else {
                            onChangeHandler({ name: "icon", target: { value: "/files/image/" + res.filename } }, "config")
                        }
                        setLogoLoader(false)
                        resolve();
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    }

    const createHub = () => {

        if (checkType()) {
            if (checkName()) {
                
                let branch1 = { ...branch }
                branch1.config.parameter.map((param, key) => {
                    if (param.alias.length) {
                        param.alias.map(ali => {
                            delete ali.tempIcon
                        })
                    }
                })
                if (location?.state?._id && location?.state.type) {
                    return hubUpdate(branch1._id, branch1).then(async res => {
                        let result = res
                        if (result) {
                            props.onChange(result, "update")
                        }
                    })
                } else {


                    return Configurationshubs(branch1).then(async res => {
                        let result = res
                        if (result.statusCode) {
                            props.onResPopup(["Failure!", result.message, "danger"])
                            props.onAddUser()
                        }
                        else {
                            props.onResPopup(["Success!", "User created successfully.", "success"])
                            props.onAddUser()
                        }
                    })
                }
            }
        }
        else {
            setName(true)

        }
    }
    const toggleIsControllable = () => {
        let data1 = { ...branch };
        data1.config.isControllable = !data1.config.isControllable;
        setBranch(data1);
    };
    const imgSave = (key, data) => {

        let data1 = { ...branch }
        data1.config.parameter[activeParameter].alias[key].tempIcon = data
        setBranch(data1)
    }

    const userDetail = (data) => {
        cancel({
            pathname: '/Configuration/Devices'
        })
    }
    return (
        <div className='p-2'>
            <Card  className="mb-3 position-sticky" style={{ top: 50, zIndex: '2' }}>
                <CardBody className="row m-0 justify-content-center align-items-center py-3 px-1">
                    <Heading className="col-sm col-12 ">
                        Device Configuration
                    </Heading>

                    <div className='d-flex' style={{ gap: 10 }}>
                        <ButtonContainer>
                            <Button onClick={() => userDetail()}>Cancel</Button>
                        </ButtonContainer>

                        <ButtonContainer>
                            <Button onClick={() => {
                                setConfirmPopup(createHub());

                            }}>{location.state?._id ? "Update":"Add"}</Button>
                        </ButtonContainer>
                    </div>
                </CardBody>
            </Card>
            {loader ? <CardLoader/> :
            <Card className='px-5 pb-2'>
                <div className='d-flex justify-content-center'>

                </div>
                <div className='row mt-2'>
                    {console.log('typeof',typeof imgFile)}
                    <div className='col-1 px-2'>
                        <label className='overviewHeading d-flex align-items-center justify-content-center px-2 w-100 h-100' style={{ cursor: 'pointer', borderRadius: '10px' }} title="Upload Logo">
                            {imgFile || branch?.config?.icon ?
                                <img style={{ objectFit: 'contain', width: '100px', Height: '100px' }} src={imgFile && typeof imgFile !== 'object' ? imgFile : imgReturnFunc(imgFile)} alt='Icon' />
                                : <><i className='fa fa-upload'></i>Icon</>}
                            <input type='file' accept="image/*,.js" hidden onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    checkImageProperties(file)
                                        .then(() => {
                                            setImgFile(URL.createObjectURL(file))
                                        })
                                        .catch((error) => {
                                            alert(error);
                                        });
                                }
                            }
                            } />
                        </label>
                    </div>
                    <div className='col-11 px-2'>


                        <div className=' row p-0   mt-3'>

                            <div className='col-10 m-0 mt-2'>
                                {location?.state?._id ? 
                                <Input dropDownHeight="100px"  name="configtype" label="Type*" defaultValue={branch?.config?.type} disabled message={{ msg: "Please enter your Device Type", status: !type }} onChange={(e) => onChangeHandler(e, "config")} />
                                :
                                <Input dropDownHeight="100px"  name="configtype" label="Type*" defaultValue={branch?.config?.type}  message={{ msg: "Please enter your Device Type", status: !type }} onChange={(e) => onChangeHandler(e, "config")} />
                                }
                            </div>

                            <div className='col-2 p-0  mt-2 d-flex'>
                                <CardHeader className="d-flex">
                                    <label className='d-flex m-0' style={{ gap: 9 }}>
                                        <ButtonContainer flat="light" className="p-1 btn-light">
                                            <Button
                                                color={branch.config.isControllable ? "text-primary" : ''}
                                                onClick={toggleIsControllable}
                                                className="h3 m-0 align-self-center"
                                            >
                                                <i className={"fa fa-toggle-" + (branch.config.isControllable ? "on" : "off")}></i>
                                            </Button>
                                        </ButtonContainer>
                                        <Heading size={1}> Controllable </Heading>
                                    </label>
                                </CardHeader>
                            </div>

                            {branch.config.name.length > 0 ? branch.config.name.map((name, index) => (
                                <div className='col-3 mt-3' key={index}>
                                    <div className='d-flex'>
                                        <Input dropDownHeight="100px" name="configname" defaultValue={branch?.config?.name?.[index]} label="Name*" onClick message={{ msg: "Please enter your Device name"}} onChange={(e) => onChangeHandler(e, "config", index)} />
                                        {index > 0 && (
                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteDeviceName(index)}> <i className='fas fa-trash fa-sm'></i></div>
                                        )}
                                    </div>
                                </div>)) : null}

                            <div className='mt-3 col-10'>
                                <button className='className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3' onClick={addDeviceName}>+</button>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='p-3' style={{ display: "block" }}>
                    <div className='mt-3'>


                        <div className='px-4'>
                            <InnerCard>
                                <CardHeader className='p-0'>
                                    <div className='row m-0 pb-4 mt-4'>
                                        <div className={"col d-flex"}>
                                            <div className={'tabButton d-flex ' + (tab === "parameter" ? "active" : "null")}>
                                                <div className='inner-btn' onClick={() => setTab("parameter")}>Parameter</div>
                                            </div>
                                            <div className={'tabButton d-flex ' + (tab === "prereq" ? "active" : "null")}>
                                                <div className='inner-btn' onClick={() => setTab("prereq")}>And Condition</div>
                                            </div>
                                            {branch.config?.isControllable ?
                                                <div className={'tabButton d-flex ' + (tab === "action" ? "active" : "null")}>
                                                    <div className='inner-btn' onClick={() => setTab("action")}>Action</div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>

                                    {/* Tab prereq */}
                                    {tab === "prereq" ? (
                                        <div>
                                            <CardHeader className='p-0'>
                                                <div className='row m-0 pb-4'>
                                                    <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                        {branch.settings.prereq.map((prereqData, key) => (
                                                            <div className={'tabButton d-flex ' + (key === activePrereq ? "active" : "null")} style={{ minWidth: (key === activePrereq) ? "130px" : "100px" }} key={key}>
                                                                <div className='inner-btn text-truncate' onClick={() => ChangeStates(branch.settings.prereq[key], key, tab, "onButtonClick")}>{prereqData.parameter}</div>
                                                                {branch.settings.prereq.length > 0 ? (
                                                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className='justify-content-end px-3'>
                                                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                    </div>
                                                </div>
                                            </CardHeader>

                                            {branch.settings.prereq.length > 0 ? (
                                                <div className='row m-0 mt-4 pb-4'>
                                                    
                                                    <div className='col-6 mt-3'>
                                                        {console.log('actionParamList',actionParamList)}
                                                        {actionParamList.length?
                                                        <Select dropDownHeight="100px" name="prereqparam" label="Parameter*" defaultValue={{value: branch?.settings?.prereq[activePrereq]?.parameter}} onChange={(e) => onChangeHandler(e, "action")} options={actionParamList}/>
                                                        :null}
                                                    </div>
                                                    <div className='col-8 mt-3'>
                                                        <InnerCard>
                                                            <div className='d-flex justify-content-center'>
                                                                <Heading size={2}>Add Condition</Heading>
                                                            </div>
                                                            {branch.settings.prereq[activePrereq].conditions.length > 0 ? branch.settings.prereq[activePrereq].conditions.map((condition, index) => (
                                                                <div className='col-12 mt-3 d-flex align-items-end ' key={index}>
                                                                    <div className='col px-2 mt-2'>
                                                                        <Input dropDownHeight="100px" name="ParamConditions" label="Conditions*" defaultValue={branch?.settings?.prereq[activePrereq]?.conditions[index]} onChange={(e) => onChangeHandler(e, "prereq", index)} />
                                                                    </div>
                                                                    <div>

                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deletePrereq(index)}><i className='fas fa-trash fa-sm'></i></div>

                                                                    </div>  </div>)) : null} <div className='col-12 d-flex justify-content-end'>
                                                                <button className='mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3' onClick={addPrereq}>+</button>
                                                            </div>
                                                        </InnerCard>
                                                    </div>

                                                    <div className='col-4 mt-3 '>
                                                        <InnerCard>
                                                            <div className='d-flex justify-content-center'>
                                                                <Heading size={2}>Add Value</Heading>
                                                            </div>
                                                            {branch.settings.prereq[activePrereq].values.length > 0 ? branch.settings.prereq[activePrereq].values.map((values, index) => (
                                                                <div className=' col-12 mt-3 d-flex align-items-end ' key={index}>
                                                                    <div className='col-10'>
                                                                        <Input dropDownHeight="100px" name="Paramvalues" label="Value*" defaultValue={branch?.settings?.prereq[activePrereq]?.values[index]} onChange={(e) => onChangeHandler(e, "prereq", index)} />
                                                                    </div>
                                                                    <div>

                                                                        <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteValue(index)}><i className='fas fa-trash fa-sm'></i></div>

                                                                    </div>

                                                                </div>)) : null}

                                                            <div className='col-12 d-flex justify-content-end'>
                                                                <button className='mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3' onClick={addValue}>+</button>
                                                            </div>
                                                        </InnerCard>
                                                    </div>
                                                </div>

                                            ) : null}
                                        </div>
                                    ) : null}
                                </CardBody>
                                <CardBody>
                                    {/* Tab Action */}
                                    {tab === "action" ? (
                                        <div>
                                            <CardHeader className='p-0'>
                                                <div className='row m-0 pb-4'>
                                                    <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                        {branch.settings.action.map((data, key) => (
                                                            <div className={'tabButton d-flex ' + (key === activeAction ? "active" : "null")} style={{ minWidth: (key === activeAction) ? "130px" : "100px" }} key={key}>
                                                                <div className='inner-btn text-truncate' onClick={() => ChangeStates(branch.settings.action[key], key, tab, "onButtonClick")}>{data.parameter}</div>
                                                                {branch.settings.action.length > 0 && <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div>}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='justify-content-end px-3'>
                                                        <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                    </div>
                                                </div>
                                            </CardHeader>

                                            {branch.settings.action.length > 0 ? (
                                                <div className=' mt-4 pb-4'>
                                                    <div className='col-6 mt-3'>
                                                        {console.log('actionParamList',actionParamList)}
                                                        {actionParamList.length?
                                                        <Select dropDownHeight="100px" name="actionparam" label="Parameter*" defaultValue={{value: branch?.settings?.action[activeAction]?.parameter}} onChange={(e) => onChangeHandler(e, "action")} options={actionParamList}/>
                                                        :null}
                                                    </div>
                                                    <div className=' ml-1 row'>

                                                        {branch.settings.action[activeAction].values.length > 0 ? branch.settings.action[activeAction].values.map((values, index) => (
                                                            <div className=' mt-3 ml-3 d-flex'>
                                                                <Input dropDownHeight="100px" name="actionvalues" label="Value*" defaultValue={branch?.settings?.action[activeAction]?.values[index]} onChange={(e) => onChangeHandler(e, "action", index)} />
                                                                {index > 0 && (
                                                                    <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteAction(index)}> <i className='fas fa-trash fa-sm'></i></div>
                                                                )}
                                                            </div>

                                                        )) : null}
                                                        <div className='col-12'>
                                                            <button className='mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3' onClick={addAction}>+</button>
                                                        </div>
                                                    </div>            </div>
                                            ) : null}
                                        </div>
                                    ) : null}

                                </CardBody>
                                {tab === "parameter" ? (
                                    <div>
                                        <CardHeader className='p-0'>
                                            <div className='row m-0 pb-4'>
                                                <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                    {branch.config.parameter.map((data, key) => (
                                                        <div className={'tabButton d-flex ' + (key === activeParameter ? "active" : "null")} style={{ minWidth: (key === activeParameter) ? "130px" : "100px" }} key={key}>
                                                            <div className='inner-btn text-truncate' onClick={() => ChangeStates(branch.config.parameter[key], key, tab, "onButtonClick")}>{data.type}</div>
                                                            {branch.config.parameter.length > 0 ? <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteMSGEMAIL(key, tab)}><i className='fas fa-trash fa-sm'></i></div> : null}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='justify-content-end px-3'>
                                                    <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={() => addAlertMsgEmail(activeAlert, tab)}>+</button>
                                                </div>
                                            </div>
                                        </CardHeader>

                                        {branch.config.parameter.length > 0 ?
                                            <div className='row m-0 mt-4 pb-4'>
                                                <div className='col-12 mt-3'>
                                                    <Input dropDownHeight="100px" name="parametertype" label="Type*" defaultValue={branch?.config?.parameter[activeParameter]?.type} onChange={(e) => onChangeHandler(e, "config", activeParameter)} />

                                                </div>
                                                <div className='col-8 mt-3'>
                                                    <InnerCard>
                                                        <div className='d-flex justify-content-center'>
                                                            <Heading size={2}>Add Alias</Heading>
                                                        </div>
                                                        {branch.config.parameter[activeParameter].alias.length > 0
                                                            ? branch.config.parameter[activeParameter].alias.map((alias, index) => (
                                                                <div className='col-12 mt-3 d-flex' key={index}>
                                                                     
                                                                    <div className='col-2 p-0 '>

                                                                        <label className='overviewHeading d-flex align-items-center justify-content-center px-2 w-100 h-100' style={{ cursor: 'pointer', borderRadius: '10px' }} title="Upload Logo">

                                                                            {branch?.config?.parameter[activeParameter].alias[index].tempIcon || branch?.config?.parameter[activeParameter].alias[index].icon ?
                                                                                <img style={{ objectFit: 'contain', width: '100px', height: '100px' }} src={typeof branch?.config?.parameter[activeParameter].alias[index].tempIcon !== 'object' ? branch?.config?.parameter[activeParameter].alias[index].tempIcon : imgReturnFunc(branch?.config?.parameter[activeParameter].alias[index].tempIcon)} alt='Icon' />
                                                                                : <><i className='fa fa-upload'></i>Icon</>}
                                                                            <input type='file' accept="image/*" hidden onChange={(e) => {
                                                                                const file = e.target.files[0];
                                                                                if (file) {
                                                                                    checkImageProperties(file, "aliasIcon", index)
                                                                                        .then(() => {
                                                                                            imgSave(index, URL.createObjectURL(file))
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            alert(error);
                                                                                        });
                                                                                }
                                                                            }
                                                                            } />
                                                                        </label>

                                                                    </div>
                                                                    <div className='col-9 p-0'>

                                                                        <div className='col  mt-2'>
                                                                            <Input name="parameteraliaskey" label="Key*" defaultValue={branch.config.parameter[activeParameter].alias[index]?.key} onChange={(e) => onChangeHandler(e, "config", index)} />
                                                                        </div>
                                                                        <div className='col mt-3'>
                                                                            <Input name="parameteraliasvalue" label="value*" defaultValue={branch.config.parameter[activeParameter].alias[index]?.value} onChange={(e) => onChangeHandler(e, "config", index)} />

                                                                        </div>
                                                                    </div>

                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div>
                                                                            {/* {index > 0 && ( */}
                                                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteAlias(index)}><i className='fas fa-trash fa-sm'></i></div>
                                                                            {/* )} */}
                                                                        </div>
                                                                    </div>
                                                                </div>)) : null}

                                                        <div className='col-12 d-flex justify-content-end'>
                                                            <button className="mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3" onClick={addAlias}>+</button> {/* Button to add more alias input fields */}
                                                        </div>
                                                    </InnerCard>
                                                </div>

                                                <div className='col-4 mt-3 '>
                                                    <InnerCard>
                                                        <div className='d-flex justify-content-center'>
                                                            <Heading size={2}>Add Unit</Heading>
                                                        </div>
                                                        {branch.config.parameter[activeParameter].unit.length > 0 && branch.config.parameter[activeParameter].unit.map((unit, index) => (
                                                            <div className=' col-12 mt-3 d-flex align-items-end ' key={index}>
                                                                <div className='col-10'>

                                                                    <Input dropDownHeight="100px" name="parameterunit" label="unit*" defaultValue={branch?.config?.parameter[activeParameter]?.unit[index]} onChange={(e) => onChangeHandler(e, "config", index)} />
                                                                </div>
                                                                <div>

                                                                    <div className=' close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteUnit(index)}><i className='fas fa-trash fa-sm'></i></div>
                                                                </div>
                                                            </div>))}
                                                        <div className='col-12 d-flex justify-content-end'>
                                                            <button className='mt-1 btn btn-dark rounded-circle-px dropshadow primary-gradient px-3' onClick={addUnit}>+</button>
                                                        </div>
                                                    </InnerCard>
                                                </div>  </div>

                                            : null}
                                    </div>
                                ) : null}


                            </InnerCard>
                        </div>
                    </div>



                </div>

            </Card>}
            {confirmPopup ? <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                <p className="p-3">{ResponseMessageupdate.confirmMessageUpdate}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => { setConfirmPopup(false); cancel.push(userDetail()); }}>ok</button>
                </div>
            </Mypopup> : null}
        </div>

    )
}
export default AddDevicePopup;
