import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const notifyReducer = createSlice({
    name: 'Notify',
    initialState,
    reducers: {
        ADD_NOTIFICATION: (state, action) => {
            state.notifyEvents = action.payload
        }
    }
})
export const {ADD_NOTIFICATION} = notifyReducer.actions

export default notifyReducer.reducer
