import { useState } from "react";
import { useEffect } from "react";
import { Button, ButtonContainer } from "../arraybutton/arraybutton";
import Card, { CardBody, CardHeader, Heading } from "../card/card";
import "./mypopup.scss"
export default function Mypopup(props) {

    const [close, setClose] = useState(true)

    useEffect(() => {
        if (props?.noClose) {
            setClose(false)
        }
    }, [])
    return (
        <div className="myPopup row m-0 justify-content-center">
            <div className="myPopupBack">

            </div>
            <div className="myPopupInner col-lg-6 col-md-8 col-12">
                <Card >
                    <CardHeader className="row align-self-center">
                        <div className="col">
                            <Heading size={2} color={"type" in props ? props.type ? props.type : null : null}>{"title" in props ? props.title : "please add title attr."}</Heading>
                        </div>
                        {"headerContent" in props ? props.headerContent : null}
                        {!close ? null :
                            <div className="d-flex align-self-center ml-1">
                                <ButtonContainer flat="light" className="mr-2">
                                    <Button onClick={(e) => "onClose" in props ? props.onClose(false) : console.error("please add 'onClose' attr.")} color="danger">
                                        <i className="fa fa-times align-self-center"></i>
                                    </Button>
                                </ButtonContainer>
                            </div>}

                    </CardHeader>
                    <CardBody loader={"loader" in props ? props.loader : null} loaderHeight={"loaderHeight" in props ? props.loaderHeight : null} className={"className" in props ? props.className : null} style={props?.style||null}>
                        {props.children}
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}
