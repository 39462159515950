import axios from "axios";
import { dataHandler } from "../../functions/functions";
// import axiosEvent from './axiosEvent'
import {
  getRoles, createUser, getUser, User, VerifyUser, Getuseradmin, GetRootLevels, hierarchy2SubUrl, hierarchy2Url, hierarchyUrl, hierarchySubUrl, createRoles, GetOrganizations, DelOrganization, DelOrganization1, DelOrganization2, DelOrganization3, getConfigrations, DelOrganization4, DeviceConfig, Config,
  Access,
  Accesscon,
  roles,
  rolescon,
  Delroles,
  level,
  CPLevel,
  getHubUrl,
  getHubSubUrl,
  GetHub,
  Getlevels,
  GetallHub,
  CreateHubs,
  updateHubs,
  levelHirarchy,
  Imageupload,
  getImgUrl,
  getLastEventsUrl,
  getLastSubEventsUrl,
  getDeviceByDeviceIdURL,
  getByLevelId,
  updateDevice,
  deviceCreateBulk,
  updateHubmangment,
  deletehub,
  CreateHubmangment,
  userharddel,
} from "../config/serverAddresses";
import { deleteRequest, getRequest, postRequest, putRequest } from "./axiosRequests";
import cookies from "./cookies";
import { acKnowledge } from "../config/serverAddresses";


export let getAllEvents = async (levelId) => {
  try {
    let response = await getRequest(getLastEventsUrl + levelId + getLastSubEventsUrl)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let CreateChildUser = async (body) => {
  try {
    let response = await postRequest(createUser, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getHubsByLevelId = async (levelId) => {
  try {
    let response = await getRequest(getHubUrl + levelId + getHubSubUrl)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let EditChildUser = async (body, id) => {
  try {
    let response = await putRequest(User + id, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let DeleteChildUser = async (id) => {
  try {
    let response = await deleteRequest(User + id)
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }
}

export let GetChildUser = async (levelId) => {
  try {
    let response = await getRequest(getUser);
    let data = await dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let DeleteAdminRoles = async (id) => {
  try {
    let response = await deleteRequest(getRoles + "/" + id)
    let data = dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data
  }
}

export let getVerifyUserToken = async (token) => {
  try {
    let response = await getRequest(VerifyUser + token);
    let data = await dataHandler(response.data)
    return data
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

//Level APis
export const getBranch = async (id) => {
  try {
    let response = await getRequest(level + id)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export const addLevelOrganizationUpdate = async (id = null, body) => {
  try {
    let response = null
    if (id) {
      // response = await putRequest(CPLevel + "s/" + id, body)
      response = await putRequest(CPLevel + "s/" + id, body)
    } else {
      response = await postRequest(CPLevel + "s", body)
    }
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export const deleteLevel = async (id) => {
  try {
    let response = await deleteRequest(level + "tree/" + id)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
// Level Apis

export let getAdminRoles = async (id) => {
  try {
    let response = await getRequest(getRoles(id))
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}


export let getAdmins = async () => {
  try {
    let response = await getRequest(Getuseradmin)
    let data = dataHandler(response.data)
    // if (data.status === true) {
    return data
    // }
    // else {
    // return response.data
    // }
  } catch (err) {
    return err.response.data
  }
}

export let GetRoots = async () => {
  try {
    let response = await getRequest(GetRootLevels);
    let data = await dataHandler(response.data)
    // if (data.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let EditUser = async (body, id) => {
  try {
    let response = await putRequest(User + id, body)
    let data = dataHandler(response.data)
    // let data = await response.data
    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let getHierarchy = async (levelId) => {
  try {
    let response = await getRequest(hierarchy2Url + levelId + hierarchy2SubUrl);
    let data = await dataHandler(response.data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let putBranches = async (id, body) => {
  try {
    let response = await putRequest(GetOrganizations(id), body);
    let data = await dataHandler(response.data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let delBranch = async (levelId, userId) => {
  try {
    let response = await deleteRequest(DelOrganization3 + levelId + DelOrganization2 + userId);
    let data = await dataHandler(response.data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let AddLevel = async (userId, body) => {
  try {
    let response = await putRequest(DelOrganization1 + userId, body);
    let data = await dataHandler(response.data)
    //console.log(data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let delBranches = async (levelId, userId) => {
  try {
    let response = await deleteRequest(DelOrganization4 + levelId + DelOrganization2 + userId);
    let data = await dataHandler(response.data)
    // if (response.status === true) {
    return data;
    // } else {
    //   return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getConfigration = async () => {
  try {
    let response = await getRequest(getConfigrations);
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let ConfigrationDevice = async (type1) => {
  try {
    let response = await getRequest(Config + "?type=" + type1);
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let putAccessconfiguration = async (id, body) => {
  try {
    //console.log(id)
    let response = await putRequest(Access(id), body);
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let postAccessconfiguration = async (body) => {
  try {
    let response = await postRequest(Accesscon, body);
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let delAccessconfig = async (id) => {
  try {
    //console.log(Access(id))
    let response = await deleteRequest(Access(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let EditAdminRoles = async (id, body) => {
  try {
    let response = await putRequest(rolescon(id), body);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let configAccess = async (id) => {
  try {
    let response = await getRequest(Access(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let CreateAdminRoles = async (body) => {
  try {
    let response = await postRequest(roles, body);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let delRoleconfig = async (id) => {
  try {
    let response = await deleteRequest(rolescon(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let GetAdminRoles = async () => {
  try {
    let response = await getRequest(roles);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let Harddelroles = async (id) => {
  try {
    let response = await deleteRequest(rolescon(id) + Delroles);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let Harddelusers= async (id) => {
  try {
    let response = await deleteRequest(userharddel(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let GetHubs = async () => {
  try {
    let response = await getRequest(GetallHub);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let Getlevel = async () => {
  try {
    let response = await getRequest(Getlevels);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubCreated = async (body) => {
  try {
    let response = await postRequest(CreateHubs, body);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubUpdate = async (id, body) => {
  try {
    let response = await putRequest(updateHubs(id), body);
    console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let levelId1 = async (id) => {
  try {
    let response = await getRequest(levelHirarchy(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let delHubs = async (id) => {
  try {
    let response = await deleteRequest(updateHubs(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let postImage = async (fileData) => {
  try {
    const formData = new FormData();
    formData.append("file", fileData)
    let token = cookies.getCookie('token');
    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-Type': 'multipart/form-data'
      },

    }
    let response = await axios.post(Imageupload, formData, config);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let Configurationshubs = async (body) => {
  try {
    let response = await postRequest(Config, body);
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
let oldImage = []
export let getResource = async (imgSrc) => {
  try {
    const oldFindImg = oldImage.find(imgData => imgData.url === imgSrc)
    if (oldImage.length && oldFindImg) {
      return oldFindImg.data
    } else {
      let response = await getRequest(getImgUrl + imgSrc);
      // let data = dataHandler(response.data)
      oldImage.push({ url: imgSrc, data: response.data })
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}

export let GetStateByDeviceId = async (id, limit, startDate = null, endDate = null) => {
  try {
    let response = {}
    if (startDate) {
      response = await getRequest(getDeviceByDeviceIdURL + id + "/logs?limit=" + limit + "&startDate=" + startDate + "&endDate=" + endDate);
    } else {
      response = await getRequest(getDeviceByDeviceIdURL + id + "/logs?limit=" + limit);
    }
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let GetLogsByDeviceId = async (id, limit, type, startDate = null, endDate = null) => {
  try {
    let response = {}
    if (startDate) {
      response = await getRequest(getDeviceByDeviceIdURL + id + '/events?type=' + type + '_Connection&limit=' + limit + "&startDate=" + startDate + "&endDate=" + endDate);
    } else {
      response = await getRequest(getDeviceByDeviceIdURL + id + '/events?type=' + type + '_Connection&limit=' + limit);

    }
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getDevicesByLevelId = async (id) => {
  try {
    let response = await getRequest(getHubUrl + id + getByLevelId);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deviceDelete = async (id) => {
  try {
    let response = await deleteRequest(updateDevice + id);
    let data = await response.data
    if (data.status === true) {
      return data;
    } else {
      return response.data
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deviceCreatedBulk = async (body) => {
  try {
    let response = await postRequest(deviceCreateBulk, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deviceUpdate = async (body, id) => {
  try {
    let response = await putRequest(updateDevice + id, body);
    let data = dataHandler(response.data)

    // if (data.status === true) {
    return data;
    // } else {
    // return response.data
    // }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let deleteHub = async (id) => {
  try {
    let response = await deleteRequest(deletehub(id));
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let updateHubMang = async (id, body) => {
  try {
    let response = await putRequest(updateHubmangment(id), body);
    console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let hubCreatedMangment = async (body) => {
  try {
    let response = await postRequest(CreateHubmangment, body);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getHubByLevelId = async (id) => {
  try {
    let response = await getRequest(getHubUrl + id + getHubSubUrl);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}
export let getlevels = async () => {
  try {
    let response = await getRequest(level);
    //console.log(response)
    // data = dataHandler(response.data)
    // let data = response
    let data = dataHandler(response.data)
    return data;
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
}