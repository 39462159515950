import React, { useState, useEffect } from 'react'
import { Heading } from '../card/card'
import Input from '../input/input'
import Select from '../select/select'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { assign, set } from 'lodash'
import { connect } from 'react-redux'
import { EmailRevoke, RoleList } from '../../services/config/generic'
import { CapitalLetters, EmailRegex, Numbers, SmallLetters, SpecialCharacters2, WhiteSpace } from '../../services/config/regex'
import { CreateChildUser, EditChildUser } from '../../services/utilities/events'
import CardLoader from '../loader/cardLoader'
import { flattenArray } from '../../functions/functions'
import MyDropDown, { MyDropDownBody } from '../dropdown/dropdown'

const AddUserPopup = (props) => {
    const [userLoader, setUserLoader] = useState(false)
    const [email, setEmail] = useState(true)
    const [specialCase, setSpecialCase] = useState(false)
    const [eightChar, setEightChar] = useState(false)
    const [whiteSpace, setWhiteSpace] = useState(true)
    const [hover, setHover] = useState(false)
    const [focus, setFocus] = useState(false)
    const [password, setPassword] = useState("")
    const [pass, setPass] = useState("")
    const [passCnfrm, setPassCnfrm] = useState(true)
    const [passValidation, setPassValidation] = useState(true)
    const [cnfrmPassValidation, setCnfrmPassValidation] = useState("")
    const [visiblePass, setVisiblePass] = useState(false)
    const [visiblePassCnfrm, setVisiblePassCnfrm] = useState(false)
    const [popup, setPopup] = useState(false)
    const [popup1, setPopup1] = useState(false)
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        designation: "",
        role: "admin"
    })

    const [formValid, setFormValid] = useState({
        firstName: true,
        lastName: true,
        designation: true,
        companyName: true,
        email: true,
        password: true,
        confirmPassword: true,
    })

    const [passwordCases, setPasswordCases] = useState({
        whiteSpace: false,
        eightChar: false,
        specialCase: false,
    })

    useEffect(() => {
        if (props?.data._id) {
            setUserData({
                firstName: props?.data.firstName,
                lastName: props?.data.lastName,
                email: props?.data.email,
                password: props?.data.password,
                designation: props?.data.designation,
                companyName: props?.data.companyName,
                role: "admin"

            })
        }
    }, [])
    const onChangeHandler = (e) => {
        const name = e.name, value = e.target.value;
        let data1 = { ...userData }

        if (e.target.name === "password") {
            data1[e.target.name] = value;
            setPassword(value);
            cnfrmPassvalidate(value, pass);
            Passwordvalidate(value);
        }
        else {

            data1[name] = value;

        }
        setUserData(data1);
    }
    const passCnfrmHandler = (e) => {
        setPass(e.target.value)
        setPassCnfrm(true)
        setCnfrmPassValidation(e.target.value)
        cnfrmPassvalidate(userData.password, e.target.value)
    }

    const cnfrmPassvalidate = (password, passcnfrm) => {
        if (password === passcnfrm) {
            setPassCnfrm(true)
            return true
        } else {
            setPassCnfrm(false)
            return false
        }
    }
    const Passwordvalidate = (pass) => {
        var whiteSpace1 = WhiteSpace
        let passwordCases1 = { ...passwordCases }
        if (whiteSpace1.test(pass)) {
            passwordCases1.whiteSpace = false;
        } else {
            passwordCases1.whiteSpace = true;
        }
        if (pass.length >= 8) {
            passwordCases1.eightChar = true;
        } else {
            passwordCases1.eightChar = false;
        }
        var passwordformat = [
            CapitalLetters,
            SmallLetters,
            Numbers,
            SpecialCharacters2]

        let count = 0
        for (let index = 0; index < passwordformat.length; index++) {
            const element = passwordformat[index];
            if (element.test(pass))
                count++
        }
        if (count >= 3) {
            passwordCases1.specialCase = true;
        }
        else {
            passwordCases1.specialCase = false;
        }
        setPasswordCases(passwordCases1)
        if (passwordCases1.specialCase && passwordCases1.eightChar && passwordCases1.whiteSpace) {
            return true
        }
        else {
            return false
        }
    }

    // const onChangeHandler = (e, key = null) => {
    //     let data1 = userData
    //     let name = e.name
    //     let alreadyAssign1 = false
    //     if (name === "levelId" || name === "roleId") {
    //         // data1["levels"][key][name] = e.target.value
    //         let update = false
    //         let excludeChecking = key
    //         if (data1["levels"][key][name]) {
    //             for (let i = 0; i < data1.levels; i++) {
    //                 if (i !== excludeChecking && data1.levels[i] === excludeChecking + 1) {
    //                     update = true // Element found in the array except for the excluded index
    //                 }
    //             }
    //         }
    //         data1.levels.map((level, key1) => {
    //             if (level.levelId.match(e.target.value) || e.target.value.match(level.levelId)) {
    //                 if (key1 !== key && !update) {
    //                     alreadyAssign1 = true
    //                 }
    //             }
    //         })
    //         if (alreadyAssign1) {
    //             setAlredyAssign({ valid: false, key: key })
    //         } else {
    //             setAlredyAssign({ valid: true, key: key })
    //         }
    //         data1["levels"][key][name] = e.target.value
    //     } else {
    //         data1[name] = e.target.value
    //     }
    //     setUserData(data1)
    // }
    // useEffect(async () => {
    //     // setLoader(true)
    //     let data = await getAdminRoles()
    //     let data1 = []
    //     data.map((data, key1) => {
    //         data1.push({ label: data.name, value: data._id })
    //     })
    //     // setLoader(false)
    //     setRoleArray(data1)
    // }, [])
    const NotSupoortedEmail = [...new Set(EmailRevoke)];

    const isFound = (email) => {
        let result = NotSupoortedEmail.some(element => element === email.split('@')[1].split('.')[0])
        return result
    }

    const checkEmail = () => {
        if (userData.email) {
            var mailformat = EmailRegex;
            if (userData.email.match(mailformat) && !isFound(userData.email)) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }

    // useEffect(() => {
    //     let levelIds1 = functionFilterLevelID()
    //     let filteredIds = []
    //     levelIds1.map(ids => {
    //         if (ids.includes(data => !data.match(ids))) {
    //         }
    //     })
    //     levelIds1 = [...filteredIds]
    // }, [userData])

    // const addLevel = (data) => {
    //     let levelAssign1 = { ...data }
    //     levelAssign1.levels.push({ levelId: "", roleId: "" })
    //     // setLevelAssign(levelAssign1)
    //     setUserData(levelAssign1)
    // }
    // const deleteLevel = (key, data) => {
    //     setLevel({ valid: true, key: 0 })
    //     setRole({ valid: true, key: 0 })
    //     let levelAssign1 = { ...data }
    //     levelAssign1.levels.splice(key, 1)
    //     setUserData(levelAssign1)
    //     // setLevelAssign(levelAssign1)

    // }


    const dropDown = (state = true, message, type) => {
        return (
            <MyDropDown openToggle={(e) => setHover(e)}>

                {state ? !type ?
                    <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px 0 calc(1rem + 10px) -55px" }}></span>
                    : <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -100px" }}></span>
                    : null}
                <MyDropDownBody open={hover ? hover : focus ? focus : false} right>
                    <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                        <small>{message}</small>
                        {type === "password" ?
                            <div style={{
                                padding: 0,
                                margin: 0,
                                listStyleType: "none",
                            }}>
                                {
                                    passwordCases.eightChar ?
                                        <li><i className="far fa-check-circle mr-1 text-success"></i><small>Be a minimum of 8 characters</small></li>
                                        :
                                        <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Be a minimum of 8 characters</small></li>
                                }
                                {passwordCases.specialCase ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>

                                }
                                {passwordCases.whiteSpace ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Contain not be spaces</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Contain not be spaces</small></li>
                                }
                            </div>
                            : null}
                    </div>
                </MyDropDownBody>
            </MyDropDown>)
    }


    const addUser = () => {

        setUserLoader(true)

        let userData1 = { ...userData }

        if (props?.data._id) {
            return EditChildUser(userData1, props?.data._id).then(async res => {
                let result = res
                if (result) {
                    if (result.statusCode) {
                        props.onResPopup(["Failure!", result.message, "danger"])
                        setUserLoader(false)
                        props.onAddUser()
                    }
                    else {
                        props.onResPopup(["Success!", "User updated successfully.", "success"])
                        setUserLoader(false)
                        props.onAddUser()
                    }
                }

            })
        }
        else {

        }
        return CreateChildUser(userData1).then(async res => {
            let result = res
            if (result) {
                if (result.statusCode) {
                    props.onResPopup(["Failure!", result.message, "danger"])
                    setUserLoader(false)
                    props.onAddUser()
                }
                else {
                    props.onResPopup(["Success!", "User created successfully.", "success"])
                    setUserLoader(false)
                    props.onAddUser()
                }
            }

        })
    }

    const newUser = () => {
        const formValid1 = { ...formValid }
        if (userData?.firstName) {
            formValid1.firstName = true;
            if (userData?.lastName) {
                formValid1.lastName = true;
                if (userData?.designation) {
                    formValid1.designation = true;
                    if (userData?.companyName) {
                        formValid1.companyName = true;
                        if (checkEmail()) {
                            formValid1.email = true;
                            if (Passwordvalidate(password)) {
                                formValid1.password = true;
                                if (Passwordvalidate(pass)) {
                                    formValid1.confirmPassword = true;
                                    setUserLoader(true)
                                    let userData1 = { ...userData }
                                    return CreateChildUser(userData1).then(async res => {
                                        let result = res
                                        if (result) {
                                            if (result.statusCode) {
                                                props.onResPopup(["Failure!", result.message, "danger"])
                                                setUserLoader(false)
                                                props.onAddUser()
                                            }
                                            else {
                                                props.onResPopup(["Success!", "User created successfully.", "success"])
                                                setUserLoader(false)
                                                props.onAddUser()
                                            }
                                        }

                                    })
                                } else {
                                    formValid1.confirmPassword = true;
                                }
                            } else {
                                formValid1.password = false;
                                formValid1.confirmPassword = true;
                            }
                        }
                        else {
                            formValid1.email = false;
                            formValid1.password = true;
                            formValid1.confirmPassword = true;
                        }
                    }
                    else {
                        formValid1.companyName = false;
                        formValid1.email = true;
                        formValid1.password = true;
                        formValid1.confirmPassword = true;
                    }
                }
                else {
                    formValid1.designation = false;
                    formValid1.companyName = true;
                    formValid1.email = true;
                    formValid1.password = true;
                    formValid1.confirmPassword = true;
                }
            }
            else {
                formValid1.lastName = false;
                formValid1.designation = true;
                formValid1.companyName = true;
                formValid1.email = true;
                formValid1.password = true;
                formValid1.confirmPassword = true;
            }
        } else {
            formValid1.firstName = false;
            formValid1.lastName = true;
            formValid1.designation = true;
            formValid1.companyName = true;
            formValid1.email = true;
            formValid1.password = true;
            formValid1.confirmPassword = true;
        }
        setFormValid(formValid1)
    }





    return (
        <div className='px-3 pb-3'>
            <div className="fade-in-right-onload">
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>User Information</Heading>
                </div>

                <div className='row '>
                    <div className='col-md-6 mt-3'>
                        <Input label="First Name" name="firstName" defaultValue={userData?.firstName} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your First Name", status: !formValid.firstName }} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <Input label="Last Name" name="lastName" defaultValue={userData?.lastName} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your Last Name", status: !formValid.lastName }} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <Input label="Designation" name="designation" defaultValue={userData?.designation} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your Designation", status: !formValid.designation }} />
                    </div>

                    <div className='col-md-6 mt-3'>
                        <Input label="Company Name" name="companyName" defaultValue={userData?.companyName} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Your Comapany", status: !formValid.companyName }} />
                    </div>

                    {props?.data._id ? null :

                        <div className='col-md-12 mt-3'>
                            <Input type="email" label="Email" name="email" defaultValue={userData?.email} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please Enter Valid Email", status: !formValid.email }} />
                        </div>
                    }
                    {props?.data._id ? null :
                        <div className='d-flex col-md-6 my-md-3 my-3 col-12'>
                            <input
                                type={visiblePass ? "text" : "password"}
                                className="form-control rounded-4 py-4 px-4 mb-3"
                                label="Password" name="password" defaultValue={userData?.password} onChange={(e) => onChangeHandler(e)}
                                style={{ borderColor: passValidation && formValid.password ? "#1B75BC" : "#ff0000" }}
                                onFocus={() => { setFocus(true); Passwordvalidate(password); }}
                                onBlur={() => setFocus(false)}
                                id="pass"
                                onClick={(e) => onChangeHandler(e)}
                            />
                            {focus ?
                                dropDown(false, ``, "password")
                                : null}
                            <button className={visiblePass ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePass(!visiblePass)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>


                    }
                    {props?.data._id ? null :
                        <div className='d-flex col-md-6 my-md-3 my-3 col-12'>
                            <input className="form-control rounded-4 py-4 px-4 mb-3"
                                style={{ borderColor: passCnfrm ? "#1B75BC" : "#FF0000" }}
                                name="cnfrmpassword" placeholder="New Confirm Password*"
                                type={visiblePassCnfrm ? "text" : "password"}
                                onChange={(e) => { passCnfrmHandler(e) }} />
                            <button className={visiblePassCnfrm ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setVisiblePassCnfrm(!visiblePassCnfrm)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>}
                    {!passCnfrm ? <p style={{ marginRight: "120px", color: "red" }}>Please make sure your password match</p> : null}

                </div>

                <ButtonContainer className="mt-3">
                    <Button onClick={() => props?.data?._id ? addUser() : newUser()}>
                        {props?.data?._id ? "Save " : "Add User"}
                    </Button>
                </ButtonContainer>

            </div>
        </div >
    )
}
const mapStateToProps = (state) => ({
    userInfo: state.userInfo

})
export default connect(mapStateToProps, null)(AddUserPopup)