import React, { useState, useEffect } from 'react'
import { Heading } from '../card/card'
import Select from '../select/select'
import { ButtonContainer, Button } from '../arraybutton/arraybutton'
import { ConfigrationDevice, CreateAdminRoles, EditAdminRoles, GetRoots, configAccess, configroles } from '../../services/utilities/events'
import { type } from '@testing-library/user-event/dist/type'
import { roles, rolescon } from '../../services/config/serverAddresses'
import Input from '../input/input'
import { useLocation } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'



const AddrolePopup = (props) => {
    //console.log(props)
    const [userLoader, setUserLoader] = useState(false)
    const [RoleList, setRoleList] = useState()
    const [addRole, setAddRole] = useState([])
    const [checkRoleName, setCheckRoleName] = useState(true)
    const [checkRoleAccess, setCheckRoleAccess] = useState(true)
    const [rolesLoader, setRolesLoader] = useState(false)
    const [popup, setPopup] = useState(null)
    const [hierarchy, setHierarchy] = useState(null)
    const [rootLevel, setRootLevel] = useState([])
    const configuration = useSelector(state => state.configuration)
    let Array = configuration?.config?.access
    //console.log(configuration)
    const [rules, setRules] = useState({
        name: "",
        access: [],
        createdFor: "",
    })
    useEffect(() => {
        if (props?.data?._id) {

            const access = props.data.access.map(acc => acc._id)
            setRules({
                name: props.data.name,
                access: access,
                _id: props.data._id,
                createdFor: props.data.createdFor
            })

        }
    }, [])
    const rootLevelGet = async () => {
        let root = await GetRoots()
        let organization = []
        root.length && root.map(org => {
            organization.push({ label: org, value: org })
        })

        setRootLevel(organization)
    }
    useEffect(() => {

        rootLevelGet()
    }, [])
    // const onChangeHandler = (e) => {
    //     let roles1 = { ...rules }
    //     if ("name" in e) {
    //         roles1[e.name] = e.target.value
    //         setCheckRoleName(true)
    //     } else {
    //         if (e.target.checked) {
    //             roles1.access.push(e.target.name)
    //         } else {
    //             roles1.access.map((acc, key) => {
    //                 if (e.target.name === acc) {
    //                     roles1.access.splice(key, 1)
    //                 }
    //             })
    //         }
    //         setCheckRoleAccess(true)
    //     }
    //     setRules(roles1)
    // }
    const onChangeHandler = (e) => {
        let roles1 = { ...rules }
        //console.log(roles1)
        if ("name" in e) {
            //console.log(roles1)
            roles1[e.name] = e.target.value
            setCheckRoleName(true)
        } else {
            if (e.target.checked) {
                roles1.access.push(e.target.name)
            } else {
                roles1.access.map((acc, key) => {
                    if (e.target.name === acc) {
                        roles1.access.splice(key, 1)
                    }
                })
            }
            setCheckRoleAccess(true)
        }
        setRules(roles1)
    }





    const checkRole = () => {
        if (!rules.name) {
            setCheckRoleName(false)
            return false
        } else {
            setCheckRoleName(true)
            return true
        }
    }
    const checkAccess = () => {
        if (!rules.access.length) {
            setCheckRoleAccess(false)
            return false
        } else {
            setCheckRoleAccess(true)
            return true
        }
    }
    const AddRules = async () => {
        if (checkRole()) {
            if (checkAccess()) {
                setRolesLoader(true)
                let rules1 = { ...rules }
                if (rules1?._id) {
                    return EditAdminRoles(props?.data?._id, rules1).then(async res => {

                        let result = res
                        if (result) {
                            if (!result.statusCode) {
                                props.onResPopup(["Success!", "Role Updated successfully.", "success"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                            else {
                                props.onResPopup(["Failure!", "Role already exist.", "danger"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                        }
                    })
                } else {
                    return CreateAdminRoles(rules1).then(async res => {

                        let result = res
                        if (result) {
                            if (!result.statusCode) {
                                props.onResPopup(["Success!", "Role created successfully.", "success"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                            else {
                                props.onResPopup(["Failure!", "Role already exist.", "danger"])
                                setRolesLoader(false)
                                props.onAddRole()
                            }
                        }
                    })
                }
            }
        } else {
            setCheckRoleAccess(true)
        }
    }
    // const handleChangeInput = async (e) => {
    //     let level1 = { ...rules }
    //     ////console.log(level1)
    //     let name = e.name
    //         level1[name] = e.target.value
    //     setRules(level1)
    // }
    return (
        <div className='px-3 pb-3'>
            <div className="">
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Role Information</Heading>
                </div>
                <div className="col-md-6 d-flex justify-items-center">
                    <div className='col-md-12 mt-3' >
                        <Input label="AddRole" name="name" defaultValue={rules?.name} onChange={(e) => onChangeHandler(e)} message={{ msg: "Please enter a title of this role", status: !checkRoleName }} />
                    </div>
                    <div className='col-md-12 mt-3' >
                        <Select style="background-color:pink" label="Organization" dropdownStyle={{ height: '200px' }} onClick options={rootLevel} name="createdFor" defaultValue={{ value: rules?.createdFor }} onChange={(e) => onChangeHandler(e)} ></Select>
                    </div>
                </div>
                <div className='row mt-3 px-3'>
                    {Array.map((role) =>

                        <div className='col-6 ' key={role._id}>

                            <label>
                                <input className='mr-1' type="checkbox" name={role._id} checked={rules.access.includes(role._id)} onChange={(e) => { onChangeHandler(e) }} />
                                {/* {rules.access.length ?
                                rules.access.map(access =>
                                    access === role.label ?
                                        <input className='mr-1' type="checkbox" checked={access} name={role.value} onChange={(e) => { onChangeHandler(e) }} />
                                )
                                :
                                <input className='mr-1' type="checkbox" name={role.value} onChange={(e) => { onChangeHandler(e) }} />
                            } */}
                                {role.label}
                            </label>

                        </div>
                    )}
                </div>
                {!checkRoleAccess ? <p style={{ color: "red" }}> Please assign at least one module to this role </p> : null}


                <ButtonContainer className="mt-3">
                    <Button onClick={() => AddRules("data")}>{props?.data?._id ? "Update" : "Add Role"}</Button>
                </ButtonContainer>
            </div></div>
    )
}
// const mapStateToProps = (state) => ({
//     configration: state.configration,
// });
export default (AddrolePopup)