import { useState } from "react";
import { postAccessconfiguration, putAccessconfiguration } from "../../services/utilities/events";
import { Button, ButtonContainer } from "../arraybutton/arraybutton";
import { Heading } from "../card/card";
import Input from "../input/input";


const AddAccessPopup = (props) => {
    const [userLoader, setUserLoader] = useState(false);
  

    const [accessData, setAccessData] = useState(props.data._id ? { label: props.data.label, value: props.data.value }
        : {
            label: "",
            value: "",
        });
       
    const addAccess = () => {
        //console.log("Access Data:", accessData);
        setUserLoader(true);
        let AcessData1 = { ...accessData }
        // Modify this part according to your API call
        if (props?.data._id) {
            return putAccessconfiguration(props?.data._id, AcessData1).then(async res => {
                let result = res
                if (result) {
                    if (result.statusCode) {
                        props.onResPopup(["Failure!", result.message, "danger"])
                        setUserLoader(false)
                        props.onAddAccess()
                    }
                    else {
                        props.onResPopup(["Success!", "User updated successfully.", "success"])
                        setUserLoader(false)
                        props.onAddAccess()
                    }
                }

            })
        }
        return postAccessconfiguration(AcessData1).then(async res => {
            let result = res
            if (result) {
                if (result.statusCode) {
                    props.onResPopup(["Failure!", result.message, "danger"])
                    setUserLoader(false)
                    props.onAddAccess()
                }
                else {
                    props.onResPopup(["Success!", "access created successfully.", "success"])
                    setUserLoader(false)
                    props.onAddAccess()
                }
            }
        })
    }

    const onChangeHandler = (e) => {
        const { name } = e;
        const { value } = e.target;
        //console.log(name,value)
        setAccessData(prevState => ({
            ...prevState,
            [name]: value,

        }));
    };

    return (
        <div className='px-3 pb-3'>
            <div className="fade-in-right-onload">
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Access Information</Heading>
                </div>
                <div className='row '>
                    <div className='col-md-6 mt-3'>
                        <Input label="Access Name" name="label" defaultValue={accessData.label} onChange={onChangeHandler} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <Input label="Value" name="value" defaultValue={accessData.value} onChange={onChangeHandler} />
                    </div>
                </div>
                <ButtonContainer className="mt-3">
                    <Button onClick={addAccess}>
                        {props?.data?._id ? "Update" : "Add Access"}
                    </Button>
                </ButtonContainer>
            </div>
        </div>
    );
};

export default AddAccessPopup;
