import React, { useState, useEffect } from 'react'
import { Heading } from '../card/card'
import { Button, ButtonContainer } from '../arraybutton/arraybutton'
import { CapitalLetters, Numbers, SmallLetters, SpecialCharacters2, WhiteSpace } from '../../services/config/regex'
import MyDropDown, { MyDropDownBody } from '../dropdown/dropdown'
import { setPassword } from '../../services/utilities/users'
import { ResponseMessageLocalupdate } from '../../services/config/generic'
import Mypopup from '../popup/mypopup'

const ChangePasswordPopup = (props) => {
    const [hover, setHover] = useState(false)
    const [focus, setFocus] = useState(false)
    const [state, setState] = useState("")
    const [confirmpopuppassword, setConfirmPopupPassword] = useState(false)
    const [passwordForm, setPasswordForm] = useState({
        password1: {
            valid: true,
            value: null,
            visible: false
        },
        password2: {
            valid: true,
            value: null,
            visible: false
        }
    })

    const cnfrmPopupCheck = () => {
        if (Passwordvalidate(passwordForm.password1.value))
            if (cnfrmPassvalidate(passwordForm.password1.value, passwordForm.password2.value)) {
                setConfirmPopupPassword(true)


            }
    }
    const updatePassword = () => {
        setPassword({ password: passwordForm.password1.value, _id: props?.id })
            .then(response => {
                // Handle success if needed
                //console.log('Password updated successfully:', response);
                let state1 = { ...state };
                state1.loginLoader = true;
                setState(state1);
                setConfirmPopupPassword(null)
                props.onClose()

            }).catch(error => {
                // Handle error
                console.error('Error updating password:', error);
            });

    }
    const [passwordCases, setPasswordCases] = useState({
        whiteSpace: false,
        eightChar: false,
        specialCase: false,
    })


    const onChangeHandler = (e) => {
        const name = e.target.name, value = e.target.value;
        let data1 = { ...passwordForm }
        //console.log(name,value)
        data1[name].value = value;
        if (name === "password1") {
            // setPassword(value);
            data1[name].valid = Passwordvalidate(value);
            data1['password2'].valid = cnfrmPassvalidate(passwordForm.password1.value, value);
        }
        else {
            data1[name].valid = cnfrmPassvalidate(passwordForm.password1.value, value);
        }
        setPasswordForm(data1);
    }

    const cnfrmPassvalidate = (password, passcnfrm) => {
        if (password === passcnfrm) {
            // setPasswordForm(data=>({...data,password2:{...data.password2,valid:true}}))
            return true
        } else {
            // setPasswordForm(data=>({...data,password2:{...data.password2,valid:false}}))
            return false
        }
    }
    const Passwordvalidate = (pass) => {
        //console.log(pass)
        var whiteSpace1 = WhiteSpace
        let passwordCases1 = { ...passwordCases }
        if (whiteSpace1.test(pass)) {
            passwordCases1.whiteSpace = false;
        } else {
            passwordCases1.whiteSpace = true;
        }
        if (pass.length >= 8) {
            passwordCases1.eightChar = true;
        } else {
            passwordCases1.eightChar = false;
        }
        var passwordformat = [
            CapitalLetters,
            SmallLetters,
            Numbers,
            SpecialCharacters2]

        let count = 0
        for (let index = 0; index < passwordformat.length; index++) {
            const element = passwordformat[index];
            if (element.test(pass))
                count++
        }
        if (count >= 3) {
            passwordCases1.specialCase = true;
        }
        else {
            passwordCases1.specialCase = false;
        }
        setPasswordCases(passwordCases1)
        if (passwordCases1.specialCase && passwordCases1.eightChar && passwordCases1.whiteSpace) {
            // setPasswordForm(data=>({...data,password1:{...data.password1,valid:true}}))
            return true
        }
        else {
            // setPasswordForm(data=>({...data,password1:{...data.password1,valid:false}}))
            return false
        }
    }



    const dropDown = (state = true, message, type) => {
        return (
            <MyDropDown openToggle={(e) => setHover(e)}>

                {state ? !type ?
                    <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px 0 calc(1rem + 10px) -55px" }}></span>
                    : <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -100px" }}></span>
                    : null}
                <MyDropDownBody open={hover ? hover : focus ? focus : false} right>
                    <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
                        <small>{message}</small>
                        {type === "password" ?
                            <div style={{
                                padding: 0,
                                margin: 0,
                                listStyleType: "none",
                            }}>
                                {passwordCases.eightChar ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Be a minimum of 8 characters</small></li>
                                    :
                                    <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Be a minimum of 8 characters</small></li>
                                }
                                {passwordCases.specialCase ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>

                                }
                                {passwordCases.whiteSpace ?
                                    <li><i className="far fa-check-circle mr-1 text-success"></i><small>Contain not be spaces</small></li>
                                    : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Contain not be spaces</small></li>
                                }
                            </div>
                            : null}
                    </div>
                </MyDropDownBody>
            </MyDropDown>)




    }

    return (
        <div className='px-3 pb-3'>
            <div className="fade-in-right-onload">
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Update Password</Heading>
                </div>

                <div className='d-flex '>
                    <input
                        type={passwordForm.password1.visible ? "text" : "password"}
                        className="form-control rounded-4 py-4 px-4 mb-3"
                        label="Password" name="password1" placeholder="New Password" defaultValue={passwordForm.password1.value} onChange={(e) => onChangeHandler(e)}
                        style={{ borderColor: passwordForm.password1.valid && passwordForm.password1.value ? "#FF0000" : "#1B75BC" }}
                        onFocus={() => { setFocus(true); Passwordvalidate(); }}
                        onBlur={() => setFocus(false)}
                        id="password"
                        onClick={(e) => onChangeHandler(e)}
                    />
                    {focus ?
                        dropDown(false, ``, "password")
                        : null}
                    <button className={passwordForm.password1.visible ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setPasswordForm(data => ({ ...data, password1: { ...data.password1, visible: !data.password1.visible } }))} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                </div>




                <div className='d-flex '>
                    <input className="form-control rounded-4 py-4 px-4 mb-3"
                        style={{ borderColor: passwordForm.password2.valid ? "#1B75BC" : "#FF0000" }}
                        name="password2" placeholder="New Confirm Password*"
                        type={passwordForm.password2.visible ? "text" : "password"}
                        onChange={(e) => { onChangeHandler(e) }} />
                    <button className={passwordForm.password2.visible ? 'btn btn-light fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setPasswordForm(data => ({ ...data, password2: { ...data.password2, visible: !data.password2.visible } }))} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                </div>
                {!passwordForm.password2.valid ? <p style={{ marginRight: "120px", color: "red" }}>Please make sure your password match</p> : null}

            </div>

            <ButtonContainer className="mt-3">
                <Button onClick={() => cnfrmPopupCheck()}>
                    Update Password
                </Button>
            </ButtonContainer>

            {confirmpopuppassword ? <Mypopup title={"Confirm!"} onClose={() => setConfirmPopupPassword(null)}>
                <p className="p-3">{ResponseMessageLocalupdate.confirmMessageUpdate}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => updatePassword()}>Yes</button>
                    <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => setConfirmPopupPassword(null)}>No</button>
                </div>
            </Mypopup> : null}

        </div>

    )
}
export default ChangePasswordPopup