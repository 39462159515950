import logo from './logo.svg';
import './App.css';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from "./containers/users/users";
import "./_App.scss"
import DefaultLayout from './containers/default-layout/defaultLayout';
function App() {
    return(
        <HashRouter>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route
                    path="*"
                    // exact={true}
                    name="Dashboard"
                    element={<DefaultLayout/>}/>
            </Routes>

        </HashRouter>

    );
  
   }

export default App;
