import moment from "moment";
// import XLSX from 'xlsx'
import zlib from "zlib-browserify";
import CryptoJS from 'crypto-js';
import { getHierarchy } from "../services/utilities/events";
// import { saveAs } from 'file-saver';
// import _ from "lodash";

export function flattenArray(nestedArray) {
    if (!nestedArray) return [];
    let result = [];
    function flattenHelper(arr) {
        for (let item of arr) {
            if (Array.isArray(item.levels)) {
                flattenHelper(item.levels);
                result.push(item) // Recursively call the helper function for nested arrays
            } else {
                result.push(item); // Append non-array elements to the result array
            }
        }
    }
    flattenHelper(nestedArray);
    return result;
}
export const fetchData = async (level) => {
    try {
        let response = await getHierarchy(level);
        // setHierarchy(response);
        const array = flattenArray(response.levels);
        let branch = []
        if (array.length) {
            branch = array.map(bnch => {
                let levelName = array.find(arr => arr._id === bnch.levelId)?.locationName || ''
                if (levelName) {
                    levelName += ' > '
                }
                return ({ label: bnch._id, value: bnch._id })
            })
        }
        branch.push({ label: level, value: level })
        return branch
    } catch (error) {
    }
};


export function getUnit(deviceType) {
    if (
        deviceType === "TVOC" ||
        deviceType === "LPG" ||
        deviceType === "CH4" ||
        deviceType === "Humidity" ||
        deviceType === "Oxygen"
    ) {
        return "%";
    } else if (deviceType === "Temperature") {
        return "°C";
    } else if (
        deviceType === "CO" ||
        deviceType === "O3" ||
        deviceType === "SO2" ||
        deviceType === "NO2"
    ) {
        return "ppm";
    } else if (deviceType === "Pressure") {
        return "mbar";
    } else if (deviceType === "Hydpressure") {
        return "bar";
    } else if (deviceType === "Dust") {
        return "µg/m3";
    } else if (deviceType === "Altitude") {
        return "Meters";
    } else if (deviceType === "AQI") {
        return "INDEX";
    }
}

export function getDate(str) {
    let date = str.split("T")[0];
    let splitDate = date.split("-");
    return splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
}

export let getSortedDate = (dates = []) => {
    if (dates.length > 1) {
        var i, j;
        for (j = 0; j < dates.length - 1; j++) {
            for (i = 0; i < dates.length - 1; i++) {
                if (moment(dates[i], "YYYY-MM-DD[T]HH:mm:ss").isBefore(dates[i + 1])) {
                    dates.splice(i, 2, dates[i + 1], dates[i]);
                }
            }
        }
    }
    return dates[0];
};

export let combineObjectsintoOne = (obj = {}) => {
    let keys = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];
    let value = "name";
    let finalObj = {};
    if (obj.length > 0) {
        obj.map((ob, ind) => {
            finalObj[keys[ind]] = ob[value];
        });
    }
    return finalObj;
};
export let reasonsIdsIntoName = (stopReasons, events) => {
    events.classes.forEach((e) => {
        for (let index = 0; index < stopReasons.length; index++) {
            const reason = stopReasons[index];
            if (e.classId === reason.classId) {
                e.classId = reason.className;
                break;
            }
        }
        e.categories.forEach((e) => {
            for (let index = 0; index < stopReasons.length; index++) {
                const reason = stopReasons[index];
                if (e.categoryId === reason.categoryId) {
                    e.classId = reason.className;
                    e.categoryId = reason.categoryName;
                    break;
                }
            }
            e.subCategories.forEach((e) => {
                for (let index = 0; index < stopReasons.length; index++) {
                    const reason = stopReasons[index];
                    if (e.subCategoryId === reason._id) {
                        e.classId = reason.className;
                        e.subCategoryId = reason.name;
                        e.categoryId = reason.categoryName;
                        break;
                    }
                }
            });
        });
    });
    return events;
};

export function getWordCount(str) {
    return str.length;
}

export const timeInterval = () => {
    let time = 60
    let Timer = setInterval(() => {
        time = time - 1
        if (time === 0) {
            clearInterval(Timer)
        }
        return time
    }, 1000)
}
export function getTime(str) {
    let time = str.split("T")[1];
    let timeFiltered = time.split(".")[0];
    let splitTime = timeFiltered.split(":");
    return formatAMPM(splitTime[0], splitTime[1], splitTime[2]);
}
function formatAMPM(hours1, minutes1, secs) {
    var hours = hours1;
    var minutes = minutes1;
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes === 0) {
        minutes = "00";
    }
    // else {
    //     minutes = minutes;
    // }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + ":" + minutes + ":" + secs + " " + ampm;
    return strTime;
}

function formatAMPMHours(hours1, minutes2, secs) {
    var hours = hours1;
    var minutes = minutes2;
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes === 0) {
        minutes = "00";
    }
    // else {
    //     minutes = minutes;
    // }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + " " + ampm;
    return strTime;
}

export function convertToInt(date, start = null, end = null) {
    let dt = date;

    dt = dt.replace("T", " ");
    if (dt.length === 16) {
        dt += ":00.000";
    } else if (dt.length === 19) {
        dt += ".000";
    } else if (dt.length === 10) {
        dt += " 00:00:00.000";
    }

    return dt;
}

export function convertToDate(str, type) {
    let date = str.split("T")[0];
    let splitDate = date.split("-");
    let time = str.split("T")[1];
    let timeFiltered = time.split(".")[0];
    let splitTime = timeFiltered.split(":");

    let day = splitDate[2];
    let month = splitDate[1];
    let year = splitDate[0];
    let hours = splitTime[0];
    let mins = splitTime[1];
    let secs = splitTime[2];
    if (type === "day") {
        return hours;
    } else if (type === "month") {
        return day;
    } else if (type === "year") {
        return month;
    } else if (type === "live") {
        return formatAMPM(hours, mins, secs);
    } else if (type === "hours") {
        return formatAMPMHours(hours, mins, secs);
    } else if (type === "recent") {
        return hours;
    } else if (type === "date") {
        return day + "-" + month + "-" + year;
    } else if (type === "object") {
        return [year, month, day, hours, mins, secs];
    }

    return formatAMPM(hours, mins, secs) + " " + day + "-" + month + "-" + year;
    //return new Date(year, month, day, hours, mins, secs);
}

export function getState(device, status, sn, graphType) {
    if (device.type === "AC") {
        if (sn === 1) {
            if (status === 0) {
                return "Status : OFF";
            } else {
                return "Status : ON";
            }
        } else if (sn === 2) {
            if (status === 0) {
                return "Swing : OFF";
            } else {
                return "Swing : ON";
            }
        } else {
            return ("Temperature : " + status + " °C");
        }
    } else if (
        device.type === "Fan" ||
        device.type === "Light" ||
        device.type === "Monitor"
    ) {
        if (status === 0) {
            return "Status : OFF";
        } else {
            return "Status : ON";
        }
    } else if (device.type === "Door" || device.type === "Lock") {
        if (status === 0) {
            return "Status : CLOSE";
        } else {
            return "Status : OPEN";
        }
    } else if (device.type === "Leakage") {
        if (status === 0) {
            return "Status : NO LEAKAGE";
        } else {
            return "Status : LEAKAGE";
        }
    } else if (device.type === "Curtain") {
        if (status === 0) {
            return "Status : CLOSE";
        } else if (status === 1) {
            return "Status : MID";
        } else {
            return "Status : OPEN";
        }
    } else if (
        device.type === "Sound" ||
        device.type === "Fire" ||
        device.type === "Smoke"
    ) {
        if (status === 0) {
            return ("Status : NO " + device.type.toUpperCase());
        } else {
            return ("Status : " + device.type.toUpperCase());
        }
    } else if (device.type === "Humidity") {
        return (device.name + ": " + status + " %");
    } else if (device.type === "Temperature") {
        if (device.metadata)
            if (device.metadata.unit)
                return (device.name + ": " + status + " " + device.metadata.unit);
            else return (device.name + ": " + status + " °C");
    } else if (device.type === "Count") {
        if (device.metadata)
            if (device.metadata.unit)
                return (device.name + ": " + status + " " + device.metadata.unit);
            else return (device.name + ": " + status);
    } else if (device.type === "Motion") {
        if (status === 0) {
            return ("Status : NO MOVEMENT");
        } else {
            return ("Status : MOVEMENT");
        }
    } else if (device.type === "FuelTank") {
        return ("Fuel Level : " + status + " %");
    } else if (device.type === "Battery") {
        return ("Battery Level : " + status + " %");
    } else if (device.type === "SourceIdentifier") {
        if (status === 0) {
            return ("Status : OFF");
        } else {
            return ("Status : ON");
        }
    } else if (device.type === "WaterTank") {
        if (device.metadata) {
            if (device.metadata.monitor) {
                if (device.metadata.monitor === "Oil") {
                    return ("Oil Level : " + status + " %");
                } else {
                    let unit = device.metadata.unit ? device.metadata.unit : "%";
                    return ("Water Level : " + status + unit);
                }
            } else {
                let unit = device.metadata.unit ? device.metadata.unit : "%";
                return ("Water Level : " + status + unit);
            }
        } else {
            let unit = device.metadata.unit ? device.metadata.unit : "%";
            return ("Water Level : " + status + unit);
        }
    } else if (device.type === "Moisture") {
        return ("Moisture : " + status + " %");
    } else if (device.type === "Pressure") {
        if (device.metadata)
            if (device.metadata.unit)
                return ("Pressure : " + status + " " + device.metadata.unit);
            else return ("Pressure : " + status + " mbar");
    } else if (device.type === "Hydpressure") {
        return ("Hydraulic Pressure : " + status + " bar");
    } else if (device.type === "FlowMeter") {
        return ("Flow Meter : " + status + " L/min");
    } else if (device.type === "Noise") {
        return ("Noise : " + status + " dB");
    } else if (device.type === "Gust" || device.type === "WindSpeed") {
        return (device.type + " : " + status + "m/s");
    } else if (device.type === "WindDirection") {
        return (device.type + " : " + status + "°");
    } else if (device.type === "Rain") {
        return (device.type + " : " + status + " mm");
    } else if (device.type === "Illuminance") {
        return (device.type + " : " + status + " LUX");
    } else if (device.type === "UV") {
        return (device.type + " : " + status + " UW/cm2");
    } else if (device.type === "SolarRadiation") {
        return (device.type + " : " + status);
    } else if (device.type === "DewPoint") {
        return ("DewPoint : " + status + " °C");
    } else if (device.type === "PhIndicator") {
        return ("Ph : " + status);
    } else if (device.type === "Vibration") {
        if (status === 0) {
            return ("Status : NO VIBRATION");
        } else {
            return ("Status : VIBRATION");
        }
    } else if (device.type === "Position") {
        if (status === 0) {
            return ("Status : NORMAL");
        } else {
            return ("Status : TILT");
        }
    } else if (
        device.type === "Dust" ||
        device.type === "CO" ||
        device.type === "CO2" ||
        device.type === "CH4" ||
        device.type === "LPG" ||
        device.type === "TVOC" ||
        device.type === "AQI" ||
        device.type === "Altitude" ||
        device.type === "Oxygen"
    ) {
        if (device.type === "Dust") {
            return (device.type + " : " + status + " µg/m");
        }
        if (
            device.type === "CO" ||
            device.type === "CO2" ||
            device.type === "CH4" ||
            device.type === "O3" ||
            device.type === "SO2" ||
            device.type === "NO2"
        ) {
            return (status + " ppm");
        } else if (device.type === "AQI") {
            return (device.type + " : " + status);
        } else if (device.type === "Altitude") {
            return (device.type + " : " + status + " Meters");
        } else {
            return (device.type + " : " + status + " %");
        }
    } else if (
        device.type === "PDU" ||
        device.type === "UPS" ||
        device.type === "Meter"
    ) {
        if (graphType === "Current") {
            return (status + " Ampere");
        } else if (graphType === "Voltage") {
            return (status + " Volts");
        } else if (graphType === "Power") {
            return (status + " KWatts");
        } else if (graphType === "Energy") {
            return (status + " Kw/Hr");
        } else if (graphType === "Load") {
            return (status + " %");
        }
    }
}

export function getDevice(hvalue, devices) {
    let temp = null;
    devices.map((dvalue) => {
        if (hvalue._id === dvalue.levelId) {
            temp = dvalue;
        }
    });
    return temp;
}

export function getDevices(hvalue, devices) {
    let temp = [];
    devices.map((dvalue) => {
        if (hvalue._id === dvalue.levelId) {
            temp.push(dvalue);
        }
    });
    return temp;
}

export function createDate() {
    let temp = moment().utc().add(5, "hours");
    return temp;
}

function toFahrenheit(celsius) {
    return (9 * celsius) / 5 + 32;
}
function toCelsius(fehrenheit) {
    return (5 * (fehrenheit - 32)) / 9;
}
function getType(input) {
    return {}.toString.call(input).slice(8, -1);
}
export function heatIndex(input) {
    if (arguments.length === 0) {
        throw new Error("Invalid Argument. Need at least one.");
    }
    if (getType(input) !== "Object") {
        throw new TypeError("Invalid Argument. Expecting 'Object'");
    }
    if (
        getType(input.temperature) !== "Number" ||
        getType(input.humidity) !== "Number"
    ) {
        throw new TypeError(
            "Invalid Argument. temperature and humidity must be 'Number'"
        );
    }
    var t = toFahrenheit(input.temperature) || 0,
        h = input.humidity || 0;
    if (input.fahrenheit) {
        t = input.temperature;
    }
    // Steadman's result
    var heatIndex = 0.5 * (t + 61 + (t - 68) * 1.2 + h * 0.094);
    // regression equation of Rothfusz is appropriate
    if (t >= 80) {
        var heatIndexBase =
            -42.379 +
            2.04901523 * t +
            10.14333127 * h +
            -0.22475541 * t * h +
            -0.00683783 * t * t +
            -0.05481717 * h * h +
            0.00122874 * t * t * h +
            0.00085282 * t * h * h +
            -0.00000199 * t * t * h * h;
        // adjustment
        if (h < 13 && t <= 112) {
            heatIndex =
                heatIndexBase -
                ((13 - h) / 4) * Math.sqrt((17 - Math.abs(t - 95)) / 17);
        } else if (h > 85 && t <= 87) {
            heatIndex = heatIndexBase + ((h - 85) / 10) * ((87 - t) / 5);
        } else {
            heatIndex = heatIndexBase;
        }
    }
    return input.fahrenheit ? heatIndex : toCelsius(heatIndex);
}

export function todayDate(type) {
    if (type === "start") {
        var currentdate = new Date();
        currentdate.setDate(currentdate.getDate());
        var currentYear = currentdate.getFullYear();
        var currentMonth = currentdate.getMonth() + 1;
        if (currentMonth <= 9) {
            currentMonth = "0" + currentMonth;
        }
        var currentDate = currentdate.getDate();
        if (currentDate <= 9) {
            currentDate = "0" + currentDate;
        }
        var datetime =
            "" + currentYear + "-" + currentMonth + "-" + currentDate + "T00:00:00"
        // + ["Z"];

        return datetime;
    } else if (type === "end") {
        var currentdate = new Date();
        var currentYear = currentdate.getFullYear();
        var currentMonth = currentdate.getMonth() + 1;
        var currentHours = currentdate.getHours();
        var currentMinutes = currentdate.getMinutes();
        var currentSeconds = currentdate.getMinutes();

        if (currentMonth <= 9) {
            currentMonth = "0" + currentMonth;
        }
        var currentDate = currentdate.getDate();
        if (currentDate <= 9) {
            currentDate = "0" + currentDate;
        }
        if (currentHours <= 9) {
            currentHours = "0" + currentHours;
        }
        if (currentMinutes <= 9) {
            currentMinutes = "0" + currentMinutes;
        }
        if (currentSeconds <= 9) {
            currentSeconds = "0" + currentSeconds;
        }


        var datetime =
            "" +
            currentYear +
            "-" +
            currentMonth +
            "-" +
            currentDate +
            "T" +
            currentHours +
            ":" +
            currentMinutes
        // +
        // ":" +
        // currentSeconds + ["Z"];

        return datetime;
    } else if (type === "shift") {
        var currentdate = new Date();
        currentdate.setDate(currentdate.getDate());
        var currentYear = currentdate.getFullYear();
        var currentMonth = currentdate.getMonth() + 1;
        var currentDate = currentdate.getDate();
        var currentHours = currentdate.getHours();

        if (currentMonth <= 9) {
            currentMonth = "0" + currentMonth;
        }
        if (currentDate <= 9) {
            currentDate = "0" + currentDate;
        }
        if (currentHours >= 8 && currentHours < 20) {
            currentHours = "08";
        } else {
            currentHours = "20";
        }
        // var datetime =
        //   "" + currentYear + "-" + currentMonth + "-" + currentDate + "T00:00:00";
        var datetime =
            "" +
            currentYear +
            "-" +
            currentMonth +
            "-" +
            currentDate +
            "T" +
            currentHours +
            ":00:00"
        // +
        // ["Z"];

        return datetime;
    }
}
export function utcDate(date, reverse = null) {
    let localUTC = new Date().getTimezoneOffset()
    if (!reverse) {
        let utcDate = moment(date).add(localUTC, "minute").format('YYYY-MM-DDTHH:mm:ss.SSS')
        return utcDate
    }
    else {
        let utcDate = moment(date).subtract(localUTC, "minute").format('YYYY-MM-DDTHH:mm:ss.SSS')
        return utcDate
    }
}
// export const formatEvent = async (event) => {
//     let map = {
//         c: "current",
//         r: "reason",
//         e: "energy",
//         ph: "phase",
//         p: "power",
//         pf: "pf",
//         v: "voltage",
//         fr: "frequency",
//         rp: "reactivePower",
//         ap: "activePower",
//         ae: "activeEnergy",
//         aed: "activeEnergyDemand",
//         aes: "activeEnergySupply",
//         red: "reactiveEnergyDemand",
//         res: "reactiveEnergySupply",
//         tae: "totalActiveEnergy",
//         taed: "totalActiveEnergyDemand",
//         taes: "totalActiveEnergySupply",
//         tre: "totalReactiveEnergy",
//         tred: "totalReactiveEnergyDemand",
//         tres: "totalReactiveEnergySupply",
//         pcv12: "phaseConductorVoltage12",
//         pcv23: "phaseConductorVoltage23",
//         pcv31: "phaseConductorVoltage31",
//     }
//     // Change keys
//     return _.mapKeys(event, (value, key) => map[key] ? map[key] : key);
// }
export const idGenerator = () => {
    let result = Math.floor(Math.random() * 9999);
    // result = new Date();
    // result = result.getTime();
    return result
}
// export const excelExport = (fileData, headerNames, fileName) => {
//     if (fileData.length) {
//         if (fileName) {
//             var data = fileData;
//             if (headerNames) {
//                 data = []
//                 let keys = Object.keys(headerNames)
//                 fileData.map(row => {
//                     let rowData = {}
//                     keys.map(key => {
//                         rowData[headerNames[key]] = row[key]
//                     })
//                     data.push(rowData)
//                 })
//             }
//             const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheethtml.sheet;charset=UTF-8'
//             const EXCEL_EXTENSION = '.xlsx';
//             const headerTitle = 'your header title here';
//             const sheet = XLSX.utils.json_to_sheet([{}], {
//                 header: [headerTitle],
//             });
//             const worksheet = XLSX.utils.sheet_add_json(sheet, data, { origin: 'A3' });
//             XLSX.utils.json_to_sheet(data)

//             const workbook = {
//                 Sheets: {
//                     'data': worksheet,
//                     "header": sheet
//                 },
//                 SheetNames: ['data']
//             };
//             const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

//             const finalData = new Blob([excelBuffer], { type: EXCEL_TYPE })
//             saveAs(finalData, fileName + " " + moment().format("DD-MMMM-yyyy") + EXCEL_EXTENSION)
//         } else {
//             console.error("please add filename in second param")
//         }
//     } else {
//         console.error("please add data in first param")
//     }
// }
// export const Sort = (data) => {
//     const hubs1 = data.sort((a, b) => {

//         if (a.levelId < b.levelId) {
//             return -1;
//         }
//         if (a.levelId > b.levelId) {
//             return 1;
//         }
//         return 0;
//     });
//     return hubs1
// }
export const SortByNumbers = () => {

}
export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export function dataHandler(data) {
    if (typeof data === 'string') {
        let temp = data.substring(0, 6);
        data = data.substring(6);
        const bytes = CryptoJS.AES.decrypt(data, temp);
        let aa = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(JSON.parse(zlib.inflateSync(Buffer.from(aa, 'base64')).toString()));
    } else {
        return data
    }
}

export const titleCase = (str) => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export function rgbToHsl(r, g, b) {
    // Normalize RGB values to be in the range [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Find the maximum and minimum values among R, G, and B
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // Calculate the lightness
    const lightness = (max + min) / 2;

    // Check if the color is grayscale (no saturation)
    if (max === min) {
        return { h: 0, s: 0, l: lightness };
    }

    // Calculate the saturation
    const delta = max - min;
    const saturation = lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    // Calculate the hue
    let hue;
    if (max === r) {
        hue = ((g - b) / delta + (g < b ? 6 : 0)) % 6;
    } else if (max === g) {
        hue = (b - r) / delta + 2;
    } else {
        hue = (r - g) / delta + 4;
    }

    hue *= 60; // Convert hue to degrees

    return { h: hue, s: saturation, l: lightness };
}
export function hexToHsl(H) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0)
        h = 0;
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    else if (cmax === g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}
export function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
export function hsvToHex(h, s, v) {
    // Ensure hue value is between 0 and 360
    h = (h % 360 + 360) % 360;
    // Ensure saturation and value are between 0 and 1
    s = Math.max(0, Math.min(1, s));
    v = Math.max(0, Math.min(1, v));
    // Calculate chroma and intermediate values
    const c = v * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = v - c;
    // Calculate RGB values
    let r, g, b;
    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }
    // Convert RGB to 8-bit values
    const r8bit = Math.round((r + m) * 255);
    const g8bit = Math.round((g + m) * 255);
    const b8bit = Math.round((b + m) * 255);
    // Convert to hexadecimal color code
    const hexR = r8bit.toString(16).padStart(2, '0');
    const hexG = g8bit.toString(16).padStart(2, '0');
    const hexB = b8bit.toString(16).padStart(2, '0');
    return `#${hexR}${hexG}${hexB}`;
}

export function getRandomNumber(min, max = null) {
    if (max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    } else {
        return Math.floor(Math.random() * min)
    }
}
export function getAQISetting(AQI) {
    let labelColor = "",
        red = 0,
        green = 150,
        blue = 0;
    let aqiValue = AQI,
        aqiMessage = "Good",
        aqiArrow = 0
    if (aqiValue === "N/A") {
        red = 150;
        green = 150;
        blue = 150;
        aqiMessage = "Not Available";
    } else if (aqiValue > 25 && aqiValue <= 50) {
        var per = ((aqiValue - 25) / 25) * 100;
        red = (230 * per) / 100;
        green = 150 + (80 * per) / 100;
        blue = 0;
        aqiMessage = "Good";
    } else if (aqiValue > 50 && aqiValue <= 100) {
        var per = ((aqiValue - 50) / 50) * 100;
        red = 230;
        green = 230 - (128 * per) / 100;
        blue = 0;
        aqiMessage = "Moderate";
    } else if (aqiValue > 100 && aqiValue <= 200) {
        red = 200;
        green = 102 - (102 * (aqiValue - 100)) / 100;
        blue = 0;
        aqiMessage =
            "Unhealthy_for sensitive groups";
        if (aqiValue > 150) {
            aqiMessage = "Unhealthy";
        }
    } else if (aqiValue > 200 && aqiValue <= 300) {
        red = 200 - (100 * (aqiValue - 200)) / 100;
        green = 0;
        blue = (100 * (aqiValue - 200)) / 100;
        aqiMessage = "Very Unhealthy";
    } else if (aqiValue > 300) {
        red = 100;
        green = 0;
        blue = 100;
        aqiMessage = "Hazardous";
    }
    labelColor = "rgb(" + red + "," + green + "," + blue + ")";
    if (aqiValue <= 300) {
        aqiArrow = (aqiValue / 300) * 100;
    } else {
        aqiArrow = (300 / 300) * 100;
    }
    // if (aqiValue !== "N/A") {
    //   aqiValue = aqiValue.toFixed(0);
    // }
    return { value: aqiValue, percentage: aqiArrow, message: aqiMessage, color: labelColor }
};
export function getDevicesList(alldevices, hierarchy, type) {
    let branches = false
    let devices = []
    ////console.log(alldevices)
    alldevices.map(device => {
        if (hierarchy.length) {
            hierarchy.map(levels => {
                // ////console.log(device, levels)
                if (device.levelId.includes(levels._id)) {
                    if (levels.branches.length > 0) {
                        branches = true
                        levels.branches.map(branch => {
                            if (device.levelId.match(branch.levelId)) {
                                if (branch.roleId.access.some(a => a.value === "1008" || a.value === "admin")) {
                                    if (!devices.includes(device.levelId)) {
                                        devices.push(device)
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
        if (!branches) {
            devices.push(device)
        }
    })
    return devices
}
export function getLevelIds(organization, hierarchy, type) {
    let levelIds1 = []
    let branches = false
    if (organization) {
        organization.allhubs.map((level) => {
            if (hierarchy.length) {
                hierarchy.map(levels => {
                    if (level.levelId.includes(levels._id)) {
                        if (levels.branches.length > 0) {
                            branches = true
                            levels.branches.map(branch => {
                                if (level.levelId.match(branch.levelId)) {
                                    if (!levelIds1.includes(level.levelId)) {
                                        levelIds1.push(level.levelId)
                                    }
                                }
                            })
                        }
                    }
                })
            }
            if (!branches) {
                if (!levelIds1.includes(level.levelId)) {
                    levelIds1.push(level.levelId)
                }
            }
        })
        levelIds1 = [...new Set(levelIds1.map(id => {
            let label = ""
            id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
            return ({ label, value: id })
        }))]
    }
    return levelIds1
}
export function getLevelIdsAll(organization, hierarchy, type) {
    let levelIds1 = []
    let branches = false
    if (organization) {
        let flattenedArray1 = flattenArray(organization?.levels);
        flattenedArray1.map((level) => {
            if (hierarchy.length) {
                hierarchy.map(levels => {
                    if (level.levelId.match(levels._id)) {
                        if (levels.branches.length > 0) {
                            branches = true
                            levels.branches.map(branch => {
                                if (level._id.match(branch.levelId)) {
                                    levelIds1.push(level._id)
                                }
                            })
                        }
                    }
                })
            }
            if (!branches) {
                levelIds1.push(level._id)
            }
        }
        )
        levelIds1 = [...new Set(levelIds1.map(id => {
            let label = ""
            id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
            return ({ label, value: id })
        }))]
    }
    // levelIds1.unshift({ label: 'None', value: null })
    // setLevelIds(levelIds1)
    // let levelIds1 = []
    // let branches = false
    // if (organization) {
    //  flattenArray
    //     organization.levels.map((level) => {
    //         if (hierarchy.length) {
    //             hierarchy.map(levels => {
    //                 if (level.levelId.match(levels.levelId)) {
    //                     if (levels.branches.length > 0) {
    //                         branches = true
    //                         levels.branches.map(branch => {
    //                             if (level.levelId.match(branch.levelId)) {
    //                                 if (!levelIds1.includes(level.levelId)) {
    //                                     levelIds1.push(level.levelId)
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 }
    //             })
    //         }
    //         if (!branches) {
    //             if (!levelIds1.includes(level.levelId)) {
    //                 levelIds1.push(level.levelId)
    //             }
    //         }
    //     })
    //     levelIds1 = [...new Set(levelIds1.map(id => {
    //         let label = ""
    //         id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
    //         return ({ label, value: id })
    //     }))]
    // }
    return levelIds1
}
export function getLevelsAll(organization, hierarchy, type) {
    let levelIds1 = []
    let branches = false
    if (organization) {
        let flattenedArray1 = flattenArray(organization?.levels);
        flattenedArray1.map((level) => {
            if (hierarchy.length) {
                hierarchy.map(levels => {
                    if (level.levelId.match(levels._id)) {
                        if (levels.branches.length > 0) {
                            branches = true
                            levels.branches.map(branch => {
                                if ((level._id.match(branch.levelId) && level._id.split("_").length > branch.levelId.split("_").length) || level._id === branch.levelId) {
                                    levelIds1.push(level)
                                }
                            })
                        }
                    }
                })
            }
            if (!branches) {
                levelIds1.push(level)
            }
        }
        )
        return levelIds1
        // levelIds1 = [...new Set(levelIds1.map(id => {
        //     let label = ""
        //     id.split("_").map((lbl, key) => label += key ? lbl + (key < id.split("_").length - 1 ? " > " : "") : "")
        //     return ({ label, value: id })
        // }))]
    }
}
export function getHubsList(allhubs, hierarchy, access = true, type) {
    let branches = false
    let hubs = []
    allhubs.map(hub => {
        if (hierarchy.length) {
            hierarchy.map(levels => {
                if (hub.levelId.includes(levels._id)) {
                    if (levels.branches.length > 0) {
                        branches = true
                        levels.branches.map(branch => {
                            if ((hub.levelId.match(branch.levelId) && hub.levelId.split("_").length > branch.levelId.split("_").length) || hub.levelId === branch.levelId) {
                                if (access) {
                                    if (branch.roleId.access.some(a => a.value === "1008" || a.value === "admin")) {
                                        if (!hubs.includes(hub.levelId)) {
                                            hubs.push(hub)
                                        }
                                    }
                                } else {
                                    if (!hubs.includes(hub.levelId)) {
                                        hubs.push(hub)
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
        if (!branches) {
            hubs.push(hub)
        }
    })
    return hubs
}
export function get_All_LevelIds_match_with_deviceType(organization, hierarchy, type, levelIds) {
    let branches = false
    let devices = []
    let levels1 = []
    const flattenedArray1 = flattenArray(organization?.levels);
    ////console.log("flattenArray1", flattenedArray1)
    organization.alldevices.map(device => {
        if (hierarchy.length) {
            hierarchy.map(levels => {
                if (device.levelId.match(levels._id)) {
                    if (levels.branches.length > 0) {
                        branches = true
                        levels.branches.map(branch => {
                            if (device.levelId.match(branch.levelId)) {
                                if (branch.roleId.access.some(a => a.value === "1008" || a.value === "admin")) {
                                    if (!devices.includes(device.levelId)) {
                                        if (device.type === type) {
                                            flattenedArray1.map(data => {
                                                if (device.levelId === data._id) {
                                                    levels1.push(data)
                                                }
                                            })
                                        }
                                        if (levelIds) {
                                            levelIds.map(levelid => {
                                                if (levelid === device.levelId) {
                                                    devices.push(device)
                                                }
                                            })
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
        if (!branches) {
            if (type) {
                // ////console.log(flattenedArray1.length)
                if (device.type === type) {
                    flattenedArray1.map(branch => {
                        if (device.levelId === branch._id) {
                            // if (!devices.includes(device.levelId)) {
                            levels1.push(branch)
                            // organization.levels.map(level => {
                            //     if (level._id === device.levelId) {
                            // levels1.push(level)
                            //     }
                            // })
                            // }
                        }
                    })
                }
            }
            // if (levelIds) {
            //     levelIds.map(levelid => {
            //         if (levelid === device.levelId) {
            //             devices.push(device)
            //         }
            //     })
            // }
        }
    })
    if (levelIds) {
        const uniqueList = [...new Set(devices)];
        return uniqueList
    } else {
        const uniqueList = [...new Set(levels1)];
        return uniqueList
    }

}
export function get_All_HubIds_selected_LevelIds(organization, hierarchy, type, levelIds) {
    let hubIds = []
    let result = []
    organization.alldevices.map(device => {
        if (levelIds.length) {
            levelIds.map(levelId => {
                if (device.levelId === levelId) {
                    if (device.type === type) {
                        organization.allhubs.map(hub => {
                            if (hub._id === device.hubId) {
                                hubIds.push({ value: hub._id, name: hub.name })
                            }
                        })
                    }
                }
            })
        }
    })
    result = duplicateremover(hubIds)
    return result
}
export function get_All_Devices_Selected_HubIds(organization, type, hubIds) {
    let deviceIds = []
    organization.alldevices.map(device => {
        if (hubIds.length) {
            hubIds.map(id => {
                if (device.type === type) {
                    if (device.hubId === id) {
                        deviceIds.push(device)
                    }
                }
            })
        }
    })
    return deviceIds
}

export function get_Devices_Selected_HubIds_WithoutMatchType(organization, hubIds) {
    let deviceIds = []
    let result = []
    organization.alldevices.map(device => {
        if (hubIds.length) {
            hubIds.map(id => {
                // if (device.type === type) {
                if (device.hubId === id) {
                    deviceIds.push({ label: device.type, value: device.type })
                }
                // }
            })
        }
    })
    result = duplicateremover(deviceIds)
    return result
}

export function get_All_Parameters_Selected_Devices(allDevice, hubIds) {
    let parameters = []
    let lengthOfDevices = []
    let result = []
    allDevice.map(device => {
        hubIds.map(hubIds => {
            if (device._id === hubIds) {
                lengthOfDevices.push(hubIds)
                device.parameter.map(param => {
                    parameters = [...parameters]
                    parameters.push({ value: param.value, type: param.name ? param.name : param.types })
                })
                // parameters.push(device)
            }
        })
    })

    // for (let i = 0; i < parameters.length; i++) {
    //     const element = parameters[i];
    //     frequency[element] = (frequency[element] || 0) + 1;

    //     if (frequency[element] >= lengthOfDevices.length && !result.includes(element)) {
    //         result.push(element)
    //     }
    // }
    result = duplicateremover(parameters)
    return result
    // let duplicateParameters = parameters => parameters.filter((item, index) => parameters.indexOf(item) !== index)
}
export function Create_Condition_List(deviceType, paramType, device) {
    let conditionList = []
    device.map(deviceConfig => {
        if (deviceType === deviceConfig.type) {
            deviceConfig.settings.prereq.map(condition => {
                if (paramType === condition.parameter) {
                    conditionList = condition.conditions
                }
            })
        }
    })
    return conditionList
}
const duplicateremover = (arr) => {
    let newArray = []
    let uniqueObject = {}
    for (let i in arr) {

        // Extract the title
        let objTitle = arr[i]['value'];

        // Use the title as the index
        uniqueObject[objTitle] = arr[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

    // Display the unique objects
    return newArray;
}
export function getAverageRGB(imgEl) {

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        /* security error, img on diff domain */alert('x');
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;

}


 export const Sort = (type,data,objKey = null) => {
    const sorted = [...data].sort((a, b) => {
        let nameA = '';
        let nameB = '';
        if (type.includes('date')) {
            nameA = new Date(a.created);
            nameB = new Date(b.created);
            if (type.includes('asc')) {
                return nameA - nameB
            } else if (type.includes('des')) {
                return nameB - nameA

            }
        } else if (type.includes('name') || type.includes('email') || type.includes('text')) {
            if (type.includes('name')) {
                nameA = `${a.firstName || ''} ${a.lastName || ''}`.toUpperCase();
                nameB = `${b.firstName || ''} ${b.lastName || ''}`.toUpperCase();
            } 
            else if(type.includes('text')) {
                nameA = (a?.[objKey] || '').toUpperCase();
                nameB = (b?.[objKey] || '').toUpperCase();
            }else {
                nameA = (a.email || '').toUpperCase();
                nameB = (b.email || '').toUpperCase();
            }
            if (type.includes('asc')) {
                return nameA.localeCompare(nameB);
            } else if (type.includes('des')) {
                return nameB.localeCompare(nameA);

            }
        } else if (type.includes('action')) {
            if (type.includes('asc')) {
                return a.registered - b.registered;
            } else if (type.includes('des')) {
                return b.registered - a.registered;

            }
        }
    });
    return (sorted)
}
 
export const SortItems = (type, data, objKey = null) => {
    
    return [...data].sort((a, b) => {
        if (type.includes('date')) {
            const dateA = new Date(a.created);
            const dateB = new Date(b.created);
            return type.includes('asc') ? dateA - dateB : dateB - dateA;
        }

        if (type.includes('name') || type.includes('text')) {
            // Ensure nameA and nameB are strings
            const nameA = (type.includes('name') ? `${a.firstName || ''} ${a.lastName || ''}` : (a?.[objKey] || '')).toString().toUpperCase();
            const nameB = (type.includes('name') ? `${b.firstName || ''} ${b.lastName || ''}` : (b?.[objKey] || '')).toString().toUpperCase();
            return type.includes('asc') ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        }

        if (type.includes('action')) {
            return type.includes('asc') ? a.registered - b.registered : b.registered - a.registered;
        }

        if (type.includes('status')) {
            const statusA = 'event' in a ? a.event?.value : 0; // Default to 0 if event.value is undefined
            const statusB = 'event' in b ?b.event?.value : 0; // Default to 0 if event.value is undefined
            return type.includes('asc') ? statusA - statusB : statusB - statusA;
        }

        return 0; // Default case
    });
}


// export const Sortlevel = (type,data, objKey) => {
//     const sorted = [...data].sort((a, b) => {
//         let nameA = '';
//         let nameB = '';
//         if (type.includes('date')) {
//             nameA = new Date(a.created);
//             nameB = new Date(b.created);
//             if (type.includes('asc')) {
//                 return nameA - nameB
//             } else if (type.includes('des')) {
//                 return nameB - nameA

//             }
//         } else if (type.includes('name') || type.includes('email')) {
//             if (type.includes('name')) {
//                 nameA = `${a.name || ''}`.toUpperCase();
//                 nameB = `${b.name || ''}`.toUpperCase();
//             } else if(type.includes('text')) {
//                 nameA = (a?.[objKey] || '').toUpperCase();
//                 nameB = (b?.[objKey] || '').toUpperCase();
//             } else {
//                 nameA = (a.email || '').toUpperCase();
//                 nameB = (b.email || '').toUpperCase();
//             }
//             if (type.includes('asc')) {
//                 return nameA.localeCompare(nameB);
//             } else if (type.includes('des')) {
//                 return nameB.localeCompare(nameA);

//             }
//         } else if (type.includes('action')) {
//             if (type.includes('asc')) {
//                 return a.registered - b.registered;
//             } else if (type.includes('des')) {
//                 return b.registered - a.registered;

//             }
//         }
//     });
//     console.log(sorted)
//     return (sorted)
// }