import React, { useEffect, useState } from 'react'
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import './addHubForm.scss'
import Hubs from '../../containers/configurtionManagment/Hubs'
// import { getImgUrl } from '../../services/config/serverAddresses';
// import { Heading } from '../card/card';
// import Mypopup from '../popup/mypopup';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/input/input';
import { Configurationshubs, getResource, hubUpdate, postImage } from '../../services/utilities/events';

import { SpecialCharacters } from '../../services/config/regex';
import { getImgUrl } from '../../services/config/serverAddresses';
import { Heading } from '../card/card';
import { ResponseMessageupdate, ResponseMessageupdatelevel, ResponseMessageupdatess } from '../../services/config/generic';
import Mypopup from '../popup/mypopup';

const AddHubForm = (props) => {
 console.log(props)
    const [rootLevel, setRootLevel] = useState([])
    const [logoLoader, setLogoLoader] = useState()
    const [imgFile, setImgFile] = useState(null)
    const [imgURL, setImgURL] = useState(null)
    const [metadataError, setMetadataError] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(null)

    const [name, setName] = useState(true)
    const [type, setType] = useState(true)

    const [branch, setBranch] = useState(
        {
            type: "hub",
            config: {
                type: "",
                name: [""],
                icon: "",
            },
            metadata: {},
        },
    )
    useEffect(() => {
        const asyncFunction = async () => {
            if (props?.data?._id) {
                console.log(props)
                let icon = await imgRenderFunc(props?.data?.config?.icon)
                props.data.config["showicon"] = icon
                props.data.config["icon"] = icon
                setBranch({
                    _id: props.data._id,
                    type: props.data.type,
                    config: props.data.config,
           
                    metadata: props.data.metadata,
                })
                // setImgFile(props?.data?.config?.showicon);
                // setImgFile(props?.data?.config?.icon);
            }
        }
        asyncFunction()
    }, []);
 

    const onChangeHandler = (e, index) => {
        let data = { ...branch }

        let name = e.name
        if (name === "configtype") {
            data["config"].type = e.target.value
            setType(true)
        }
        else if (name === "configname") {
            data["config"].name[0] = e.target.value
            setName(true)
        }
        else if (name === "configicon") {
            data["config"].icon = e.target.value
            setImgURL(true)
        }
        else if (name === "configicon") {
            data["config"].icon = e.target.value
            setImgURL(true)
        }
        else if (name === "configicon") {
            data["config"].icon = e.target.value
            setImgURL(true)
        }
        else {
            data[name] = e.target.value
        }
        setBranch(data)
    }
    const [file, setFile] = useState();
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const imgRenderFunc = async (src) => {
        let image = null
        if (src) {
            if (src.endsWith(".svg")) {
                image = await getResource(src)
                image = { icon: image, type: 'svg' }
            }
            else if (src.endsWith(".js")) {
                const imagecheck = await getResource(src)
                image = eval("(" + imagecheck + ")")
                //   image = image()
                if (imagecheck.includes('return {image, update: updateImage};')) {
                    image = image({ _id: props.device._id, type: props.device.type })
                }
                image = { icon: image, type: 'js' }
            }else{
                image = { icon: getImgUrl + src, type: 'img' }
            }

            // await Promise.all(imgReturnFunc(image))
        }
        return image;
    }
    const checkName = () => {
        const special = SpecialCharacters
        if (!branch?.config.name[0] || special.test(branch?.config?.name[0])) {
            setName(false)
            return false
        }
        else {
            setName(true)
            return (true)
        }
    }
    const checkType = () => {
        if (!branch.config.type) {
            setType(false)
            return false
        }
        else {
            setType(true)
            return (true)
        }
    }
    const handleChangeMetadata = (e) => {
        let branch1 = { ...branch }
        const { value } = e.target;
        try {
            const parsedValue = JSON.parse(value); // Attempt to parse the value as JSON
            setMetadataError(null); // Clear any previous error message
            branch1["metadata"] = parsedValue
            setBranch(branch1);
        } catch (error) {
            setMetadataError("Invalid JSON format"); // Set error message if parsing fails
        }
    }
    const imgReturnFunc = (data, value) => {
        // var length = data?.length
        if (data) {
            let img = data
            if (img) {
                if (img.type === "svg") {
                    return "data:image/svg+xml;base64," + btoa(img.icon)
                }
                else if (img.type === "js") {
                    return "data:image/svg+xml;base64," + btoa(img.icon({ value: 0 }))
                }
                else {
                    return img.icon
                }
            }
        } else {
            return null
        }
    }
    const checkImageProperties = async (file) => {
        setLogoLoader(true)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = async () => {
                    const width = image.width;
                    const height = image.height;
                    const size = file.size;
                    const fileType = file.type;
                    // check if the image meets your criteria for size, width, height, and type
                    if (size > 1024 * 1024) { // max size 1MB
                        setLogoLoader(false)
                        reject("File size too large. Please select a smaller image.");
                        // } else if (width !== 200 || height !== 100) { // required dimensions 300x100
                        //   reject("Invalid image dimensions. Please select an image with dimensions of 300x100 pixels.");
                    } else if (!fileType.startsWith("image/")) { // check if the file type is an image
                        setLogoLoader(false)
                        reject("Invalid file type. Please select an image file.");
                    } else {
                        let res = await postImage(file)
                        onChangeHandler({ name: "configicon", target: { value: "/files/image/" + res.filename } })
                        setLogoLoader(false)
                        resolve();
                    }
                };
            };
            reader.readAsDataURL(file);
        });
    }

    // Hub Create Function 
    const createHub = () => {
        if (checkType()) {
            if (checkName()) {
                if (!metadataError) {
                    delete branch?.config?.showicon
                    if (props?.data?._id && props?.data.type) {
                        return hubUpdate(branch._id, branch).then(async res => {
                            let result = res
                            if (result) {
                                props.onChange(result, "update")
                            }
                        })
                    } else {
                        return Configurationshubs(branch).then(async res => {
                            let result = res
                            if (result.statusCode) {
                                props.onResPopup(["Failure!", result.message, "danger"])
                                
                            }
                            else {
                                props.onResPopup(["Success!", "User created successfully.", "success"])
                            }
                        })
                    }
                }
            } else {
                setName(true)
                
            }
        }
    
    
    
    };
  

    return (
        <div className='px-4 pb-2'>
             <div className='d-flex justify-content-center'>
                    <Heading size={2}>Hub Information</Heading>
                </div>
                <div className='row mt-2'>
                <div className='col-2 p-0 '>
                        <label className='overviewHeading d-flex align-items-center justify-content-center px-2 w-100 h-100' style={{ cursor: 'pointer', borderRadius: '10px' }} title="Upload Logo">

                            {imgFile || branch?.config?.icon ?
                                <img style={{ objectFit: 'contain', width: '100px', height: '100px' }} src={imgFile ? imgFile : imgReturnFunc(branch?.config?.icon)} alt='Icon' />
                                : <><i className='fa fa-upload'></i>Icon</>}
                            <input type='file' accept="image/*" hidden onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    checkImageProperties(file)
                                        .then(() => {
                                            setImgFile(URL.createObjectURL(file))
                                        })
                                        .catch((error) => {
                                            alert(error);
                                        });
                                }
                            }
                            } />
                        </label>
                    </div>
                <div className='col-10 px-2'>

                    <div className='col-12 p-0 mt-2'>
                        <Input dropDownHeight="100px" name="configtype" label="Type*" defaultValue={branch?.config?.type} message={{ msg: "Please enter your Device Type", status: !type }} onChange={(e) => onChangeHandler(e)} />
                    </div>

                    <div className='col-12 p-0 mt-3'>
                        <Input dropDownHeight="100px" name="configname" defaultValue={branch?.config?.name} label="Name*" message={{ msg: "Please enter your Device name", status: !name }} onChange={(e) => onChangeHandler(e)} />

                    </div>
                </div>
            </div>
            <div className='row mt-3'>
    <div className='col-12'>
        <Input
            // className='form-control'
            type="textarea"
            defaultValue={JSON.stringify(branch?.metadata)} // Convert metadata object to JSON string
            onChange={(e) => handleChangeMetadata(e)}
            label='Enter JSON'
            message={{
                msg: metadataError, status: metadataError
            }}
        />
    </div>
</div>
            <ButtonContainer className="mt-5">
            <Button onClick={() => setConfirmPopup(createHub())}>{props?.data?._id ? "Update" : "Add Location"}</Button>
            </ButtonContainer>
            {confirmPopup ? <Mypopup title={"Confirm!"}>

                <p className="p-3">{ props.data?._id ?"hub update successfully":ResponseMessageupdatess.confirmMessageUpdate}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => { setConfirmPopup(false ); props.onAddUser();}}>ok</button>
                    
                </div >
            </Mypopup> : null}
        </div>
    )
}

export default (AddHubForm)
