

import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const userInfo = createSlice({
    name: 'user',
    initialState,
    reducers: {
        Verification: (state, action) => {
            state.varification = action.payload
        }
    }
})

export const {Verification} = userInfo.actions

export default userInfo.reducer

// export const userInfo2= (state = null, action) => {

//     switch (action.type) {
//         case "Verification":
//             return { ...state, varification: action.payload };
//         default:
//             return state
//     }
// }
// export const userToken = (state = null, action) => {

//     switch (action.type) {
//         case "Token":
//             return { ...state, token: action.payload };
//         default:
//             return state
//     }
// }
// export const themeMode = (state = null, action) => {

//     switch (action.type) {
//         case "themeMode":
//             return { ...state, isLight: action.payload };
//         default:
//             return state
//     }
// }