import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lastEvent: null,
    lastEventConnection: null
};

const lastEvents = createSlice({
    name: 'events',
    initialState,
    reducers: {
        Get_Events(state, action) {
            // if (action.payload.type === "last") {
            state.lastEvent = {
                ...action.payload, devices: action.payload.devices.map(device => {
                    let hubId = device._id.split("_");
                    hubId = hubId[0] + "_" + hubId[1]
                    device.events = device.events.find(eve => hubId === eve.deviceId)
                    return device
                })
            }
            // })
            // state.lastEvent.map()
            // } else if (action.payload.type === "live") {
            //     if (state.lastEvent?.devices) {
            //         state.lastEvent.devices = state.lastEvent.devices.map(device => {
            //             let hubId = device._id.split("_");
            //             hubId = hubId[0] + "_" + hubId[1];
            //             if (device._id === action.payload.data.deviceId) {
            //                 device.events[0] = action.payload.data;
            //             } else if (hubId === action.payload.data.deviceId) {
            //                 device.events[1] = action.payload.data;
            //             }
            //             return device;
            //         });
            //     }
            // }
        },
        UPDATE_EVENTS(state, action) {
            if (state.lastEvent?.devices) {
                state.lastEvent.devices = state.lastEvent.devices.map(device => {
                    let hubId = device._id.split("_");
                    hubId = hubId[0] + "_" + hubId[1];
                    if (hubId === action.payload.deviceId) {
                        device.events = action.payload;
                    }
                    return device;
                });
            }
        },
        Live_Events(state, action) {
            if ("type" in action.payload) {
                state.lastEventConnection = action.payload
            } else {
                state.lastEventConnection = null
            }
        }
    },
});

// Export the action creator
export const { Get_Events } = lastEvents.actions;
export const { UPDATE_EVENTS } = lastEvents.actions
export const { Live_Events } = lastEvents.actions

// Export the reducer
export default lastEvents.reducer;
