import "./arraybutton.scss"
export const ButtonContainer = (props) => {
    let classes = "";
    let innerClasses = " primary";
    if ("flat" in props) {
        classes += "flat-container";
        if (props.flat) {
            if (props.flat === "light") {
                classes += " light";
            }
        }
    } else {
        classes += "array-button";
    }
    if ("className" in props) {
        classes += " " + props.className
    }
    if ("color" in props) {
        innerClasses = " " + props.color
    }
    if ("flat" in props) {
        return (
            <div className={classes}>
                {props.children}
            </div>
        )
    } else {
        return (
            <div className={classes}>
                <div className={"buttonFlex " + innerClasses}>
                    {props.children}
                </div>
            </div>
        )
    }
}
export const Button = (props) => {
    let classes = "togglebtn";
    if ("color" in props) {
        classes += " " + props.color;
    }
    if ("className" in props) {
        classes += " " + props.className
    }
    if ("active" in props) {
        classes += props.active ? " selectbtn" : "";
    }
    return (
        <div onClick={() => props?.onClick(props.value)} className={classes}>
            {props.children}
        </div>
    )
}