import React, { useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card';
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton';
import { useSelector } from 'react-redux';
import './userDetail.scss';
import { getRandomNumber, hslToHex } from '../../functions/functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { GetChildUser } from '../../services/utilities/events';

const UserDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const adminList = useSelector(state => state.fetchUserList);

    const [users, setUsers] = useState([]);
    const [logs, setLogs] = useState([]);
    const [me, setMe] = useState(location.state || {});

    // Fetch and filter users based on location state or URL parameters
    const getUsers = () => {
        console.log('Fetching users...');
        const filterdata = [...adminList.UserLists];
        console.log('AdminList.UserLists:', filterdata);
        let filterdata1 = [...filterdata];

        if (location.state) {
            console.log('Location state:', location.state);
            setMe(location.state);
            filterdata1 = filterdata.filter(user => user.createdBy === location.state.email);
        } else {
            const id = new URLSearchParams(location.search).get('id');
            console.log('ID from URL:', id);
            if (id) {
                const user = filterdata.find(userMatch => userMatch._id.toString() === id);
                console.log('User found by ID:', user);
                if (user) {
                    setMe(user);
                    filterdata1 = filterdata.filter(user => user.createdBy === user.email);
                    console.log(filterdata1)
                } else {
                    console.error('No user found with ID:', id);
                }
            } else {
                console.error('No ID found in URL');
            }
        }

        console.log('Filtered users:', filterdata1);
        setUsers(filterdata1);
    };

    useEffect(() => {
        console.log('useEffect triggered');
        getUsers();
        childuser();
    }, [location.state, location.search]);

    // Fetch and filter child users based on the 'createdBy' field
    const childuser = async () => {
        try {
            console.log('Fetching child users...');
            let root = await GetChildUser();
            console.log('Fetched child users:', root);
    
            // Filter users based on 'createdBy' field
            let filteredUsers = root.filter(user => user.createdBy===me.email);
            console.log('Filtered child users by createdBy:', filteredUsers);
     
            // Map the filtered users for display
            const organization = filteredUsers.map(user => ({
                label: user.firstName || 'No Name',
                value: user
            }));
            console.log('Mapped organization:', organization);
    
            setUsers(organization);
        } catch (error) {
            console.error('Error fetching child users:', error);
        }
    };
    

    const userDetail = (data) => {
        console.log('User detail clicked:', data);
        navigate(`/userDetail/?id=${data._id}`, { state: data });
    };

    return (
        <div className='m-3'>
            <Card>
                <CardHeader>
                    {me && (
                        <div className='d-flex'>
                            <InnerCard className="col-1 p-0" style={{ backgroundColor: hslToHex(getRandomNumber(0, 360), 50, 50) }}>
                                <div className='box'>
                                    <h1 className='heading'>
                                        {me.firstName?.charAt(0).toUpperCase() + me.lastName?.charAt(0).toUpperCase()}
                                    </h1>
                                </div>
                            </InnerCard>
                            <div className='col p-2 ml-2'>
                                <Heading>{me.firstName + " " + me.lastName}</Heading>
                                <p><b>{me.designation}</b></p>
                                <b>Organization: {me.companyName}</b>
                                {me.levels?.map(level => {
                                    console.log('Rendering level:', level);
                                    return (
                                        <span key={level.levelId} className='bg-light rounded-circle-px p-2 px-3'>{level.levelId}</span>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </CardHeader>
            </Card>
            <div className='d-flex justify-content-center'>
                <div className='col-md-6 p-2'>
                    <Card className="mt-2">
                        <CardHeader>
                            <Heading size={2}>Logs</Heading>
                        </CardHeader>
                        <CardBody className="p-3">
                            {logs.length ? (
                                logs.map((log, index) => {
                                    console.log('Rendering log:', log);
                                    return (
                                        <InnerCard key={index} className="p-3 mb-1">
                                            <Heading size={3}>{log.message}</Heading>
                                        </InnerCard>
                                    );
                                })
                            ) : (
                                <p>Logs not found</p>
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div className='col-md-6 p-2'>
    <Card className="mt-2">
        <CardHeader>
            <Heading size={2}>Employees</Heading>
        </CardHeader>
        <CardBody className="p-3">
            {users.length ? (
                users.map((user) => {
                    console.log('Rendering user:', user);
                    return (
                        <InnerCard key={user.value._id} className="d-flex p-3 mb-1 align-items-center">
                            <div className='col'>
                                <Heading size={3}>{user.label}</Heading>
                            </div>
                            <div className='col'>
                                <Heading size={3}><b>{user.value.designation}</b></Heading>
                            </div>
                            {user.value.levels?.map(level => {
                                console.log('Rendering user level:', level);
                                return (
                                    <span key={level.levelId} className='bg-light rounded-circle-px p-2 px-3'>{level.companyName}</span>
                                );
                            })}
                            <ButtonContainer color="success" className="mr-1 justify-content-end">
                                <Button buttonResult={() => userDetail(user.value)}>Detail</Button>
                            </ButtonContainer>
                        </InnerCard>
                    );
                })
            ) : (
                <p>Users not available</p>
            )}
        </CardBody>
    </Card>
</div>

            </div>
        </div>
    );
};

export default UserDetail;
