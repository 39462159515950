import Dashboard from './containers/dashboard/dashboard';
import UserManagement from './containers/userManagement/UserManagement';
import UserDetail from './containers/userDetail/userDetail';
import Devices from './containers/configurtionManagment/Devices';
import Level from './containers/configurtionManagment/Level';
import Roles from './containers/configurtionManagment/Roles';
import Hubs from './containers/configurtionManagment/Hubs';
import Access from './containers/configurtionManagment/Access';
import LevelManagement from './containers/levelManagement/levelManagement';
import MQTT from './containers/MQTT/Mqtt';
import DeviceManager from './containers/deviceManegement/DeviceManager';
import AddDevicePopup from './components/devicePopup/DevicePopup';
import HubMangment from './containers/HubMangment/Hubmangment';
const routes = [
     { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
     { path: '/MQTT', exact: true, name: 'Mqtt', component: MQTT },
     { path: "/userManagement", name: "user-management", component: UserManagement },
     { path: "/levelManagement", name: "user-management", component: LevelManagement },
     { path: "/deviceManagement", name: "user-management", component: DeviceManager },
     { path: "/userDetail", name: "user-detail", component: UserDetail },
     { path: "/Configuration/Devices", name: "devices", component: Devices },
     { path: "/Configuration/Level", name: "levels", component: Level },
     { path: "/Configuration/Roles", name: "Roles", component: Roles },
     { path: "/Configuration/Hubs", name: "Hubs", component: Hubs },
     { path: "/Configuration/Access", name: "Hubs", component: Access },
     { path: "/Configuration/Devices/AddDevicePopup", name: "Add Device", component: AddDevicePopup },
     { path: "/hubManagement", name: "HubMangment", component: HubMangment },

]

export default routes
