import React, { useState, useEffect } from "react";
import "./dropdown.scss";
let hover = "fade-out ";
export default function MyDropDown(props) {
  return (
    <div
      id={"id" in props ? props.id : null}
      onMouseLeave={() => props.openToggle(false)}
      onMouseEnter={() => props.openToggle(true)}
      className={
        "className" in props
          ? "dropdown-container " + props.className
          : "dropdown-container"
      }
    >
      {props.children}
    </div>
  );
}
export function MyDropDownBody(props) {
  let style = null;
  if ("style" in props) {
    style = props.style
  }
  return (
    <div
      style={style}
      className={
        "dropdown-body" +
        ("left" in props ? " left" : "") +
        ("right" in props ? " right" : "") +
        (props.open ? " fade-in" : " fade-out") +
        ("className" in props ? " "+props.className : "")
      }
    >
      {props.children}
    </div>
  );
}
