import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { useEffect, useState } from "react";
import { ConfigrationDevice, delAccessconfig } from "../../services/utilities/events";
import Mypopup from "../../components/popup/mypopup";
import AddAccessPopup from "../../components/accessPopup/Accesspopup";
import { ResponseMessageLocal } from "../../services/config/generic";
import Input from "../../components/input/input"; // Assuming you have an Input component
import { Sort } from "../../functions/functions";

const Access = (props) => {
    let [loader, setLoader] = useState(false);
    let [addAccess, setAddAccess] = useState(false);
    let [accessList, setAccessList] = useState([]);
    const [msgPopup, setMsgPopup] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('text-nam-asc'); // Default sort type

    const AccessGet = async () => {
        setLoader(true);
        let access = await ConfigrationDevice("access");
        setAccessList(access);
        setLoader(false);
    };

    useEffect(() => {
        AccessGet();
    }, []);

    const deleteAccess = async () => {
        let key = confirmPopup.key;
        let id = confirmPopup.data;
        await delAccessconfig(id);
        setAccessList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    };

    // Filtered access list based on search term
    const filteredAccessList = accessList.filter(access => 
        access.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortData = (type, objkey) => {
        setSortType(type);
        const sorted = Sort(type, filteredAccessList, objkey);
        setSortedUsers(sorted);
    };

    useEffect(() => {
        // Set sortedUsers when accessList or searchTerm changes
        const sorted = Sort(sortType, filteredAccessList, 'label'); // Adjust the key if needed
        setSortedUsers(sorted);
    }, [accessList, searchTerm, sortType]);

    return (
        <div className='mt-2 px-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Access</Heading>
                        </div>
                        <div>
                            <Input
                                type="text"
                                label="Search Access"
                                placeholder="Search by label..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => setAddAccess("data")}>Add Access</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                </CardHeader>
            </Card>

            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col'><Heading>S.No</Heading></div>
                    <div className='col' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'label')}>
                        <Heading className="d-flex gap-2">Name
                            <div className='ml-2'>
                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className='col-12 col-sm-4 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-valu-asc' : 'text-valu-' + (sortType.includes('asc') ? 'des' : 'asc')), 'value')}>
                        <Heading className="d-flex gap-2">Value
                            <div className='ml-2'>
                                {sortType.includes('valu') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>                   
                     <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}><Heading>Action</Heading></div>
                </CardHeader>
            </Card>
            
            {filteredAccessList.length > 0 ?
                sortedUsers.map((access, key) =>
                    <Card className="overflow-hidden mt-1" key={access._id}>
                        <CardHeader className='row m-0 align-items-center'>
                            <div className='col'>{key + 1}</div>
                            <div className='col'>{access.label}</div>
                            <div className='col-12 col-sm-4 col-lg-2'>{access.value}</div>
                            <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button onClick={() => setAddAccess(access)}>
                                        <i className="lt lt-edit"></i>
                                    </Button>
                                    <Button onClick={() => { setConfirmPopup({ data: access._id, key }) }}>
                                        <i className="lt lt-trash"></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : null
            }
            
            {addAccess && (
                <Mypopup title={addAccess._id ? "Edit Role" : "Add Role"} onAddAccess={() => setAddAccess(null)} onClose={() => setAddAccess(null)}>
                    <AddAccessPopup data={addAccess} onResPopup={(e) => setMsgPopup(e)} onAddAccess={() => { setAddAccess(null); AccessGet() }} />
                </Mypopup>
            )}
            
            {confirmPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmPopup(null)}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => { setConfirmPopup(null); AccessGet() }}>No</button>
                    </div>
                </Mypopup>
            )}
        </div>
    );
};

export default Access;
