import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import LT from "../../assets/svgs/Ottomatically_logo_horizontal.png";
import MyDropDown, { MyDropDownBody } from "../../components/dropdown/dropdown";
import Mypopup from "../../components/popup/mypopup";
import Signal from "../../components/signals/signal";
import { signOut } from "../../services/utilities/users";
import LightDarkButton from "../../components/lightDarkButton/lightDarkButton";
function DefaultHeader(props) {

  const [orgLogo, setOrgLogo] = useState(null)



  let history = useNavigate()
  document.onclick = function (e) {
    if (hover1) {
      setAlertPopup(!alertPopup)
    }
    else {
      setAlertPopup(false)
    }
    if (hover2) {
      setUserPopup(!userPopup)
    }
    else {
      setUserPopup(false)
    }
  };
  let [popup, setPopup] = useState(false)
  const showPopup = () => {
    setPopup(true)

  }


  const getCookie = (key) => {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodedCookieParts = decodedCookie.split(';');


    for (let i = 0; i < decodedCookieParts.length; i++) {
      let c = decodedCookieParts[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
  const [userPopup, setUserPopup] = useState(false)
  const [alertPopup, setAlertPopup] = useState(false)
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [hover3, setHover3] = useState(false)
  // const [searchInput, setSearchInput] = useState(false)
  const handleClick = () => {
    history("/change-password")
  }

  let { pathname } = useLocation();

  let levelId = pathname.split("/");
  levelId = levelId[levelId.length - 1];
  let orgId = levelId;
  if (orgId.match("_")) {
    let orgIdList = orgId.split("_");
    orgId = orgIdList[0];
  }

  // useEffect(() => {
  //   const renderLogo = async () =>{
  //     if (props.organization) {
  //       if (props?.organization?.metadata?.logo) {
  //         const logo = await getImage(props?.organization?.metadata?.logo)
  //         setOrgLogo(logo)
  //       }
       
  //     }
  //   }
  //   renderLogo()
  // }, [props.organization])
  return (
    <>
      <div className="topContainer">
        <div className="blurContainer"></div>
         <a href="#">
          <img src={orgLogo ? orgLogo : LT} alt="Linked-things Logo" />
        </a>
        <nav className={"right-btns"}>
          <div className="mySignll"><Signal connection={props.network} /></div>
          <MyDropDown className="position-unset" openToggle={(e) => setHover2(e)}>
            <button className="user-icon">
              <i className="lt lt-user"></i>
            </button>
            <MyDropDownBody className="userDropdownContainer" open={userPopup} right>
              <button className="userdropdown w-100 d-flex"><i className="mr-2 lt lt-user"></i> {getCookie('user')}</button>
              <button className="userdropdown w-100" onClick={() => handleClick()}><i className="mr-2 lt lt-lock"></i>Change Password</button>
              <LightDarkButton/>
              <button className="userdropdown w-100" onClick={() => showPopup()}><i className="mr-2 lt lt-power"></i> Sign Out</button>
            </MyDropDownBody>
          </MyDropDown>
        </nav>

        </div>
      {
        popup ? <Mypopup title="Warning!" onClose={(e) => setPopup(false)}>
          <p className="p-3"> Are You Sure Sign Out?</p>
          <div className="d-flex justify-content-center p-3">
            <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => signOut()}>Yes</button>
            <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setPopup(false) }}>No</button>
          </div>
        </Mypopup>
          : null}
    </>
  );
}
export default DefaultHeader
