
// HTTP Requests Server Live
// export const ServerAddress = "http://192.168.10.170:5000";
// export const ServerAddress = "http://192.168.100.21:5000";
// export const ServerAddress = "http://localhost:5000";
export const ServerAddress = "https://gateway2.s5.ottomatically.com";
// export const ServerAddress = "http://10.101.98.5:5001";
// export const ServerAddress = "http://10.101.98.5:5000";
// export const ServerAddress = "http://192.168.100.157:5000";
// export const ServerAddress = "http://192.168.10.2:5000";S
// export const ServerAddress = "https://4cb3-5-32-61-171.ngrok-free.app"
// export const ServerAddress = process.env.REACT_APP_API_URL + ":5000";
// export const ServerBaseAddress = ServerAddress + "/api/v2";
export const ServerBaseAddress = ServerAddress + "/api/v1";



// Socket Requests Server
// export const SocketServerAddress = "http://192.168.10.170:5000";
export const SocketServerAddress = "https://events2.s5.ottomatically.com";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = process.env.REACT_APP_API_URL + ":5005";
// export const SocketServerAddress = "http://10.101.98.104:5005";
// export const SocketServerAddress = "http://192.168.10.170:5005";
// export const SocketServerPort = 5005;
export const SocketServerBaseAddress = SocketServerAddress


// Dashboard Configurations URLs
export const getConfigrations = ServerBaseAddress + "/dashboard/configurations";

// Users URLs
export const signUpUrl = ServerBaseAddress + "/open/users/signup";
export const signInUrl = ServerBaseAddress + "/open/users/signin";
export const signOutUrl = ServerBaseAddress + "/open/users/sign-out"
export const verifyEmailUrl = ServerBaseAddress + "/open/users/verify-email/";
export const getSignedInUserUrl = ServerBaseAddress + "/me";
export const userVerificationUrl = ServerBaseAddress + "/open/users/confirmation";
export const setPasswordUrl = ServerBaseAddress + "/open/users/set-password";
export const resendEmailUrl = ServerBaseAddress + "/open/users/resend-email";
export const refreshTokenUrl = ServerBaseAddress + "/users/refresh-token";
export const ChangePassword = ServerBaseAddress + "/users/updatepassword"


//Events  URL
export const hierarchyUrl = ServerBaseAddress + '/levels/'
export const hierarchySubUrl = '/hierarchy'

//Hierarchy 2
export const hierarchy2Url = ServerBaseAddress + "/levels/"
export const hierarchy2SubUrl = "/hierarchy2"


export const organizationCreate = ServerBaseAddress + '/levels/bulk'

export const organizationCreatedUrlSingle = ServerBaseAddress + '/levels'

export const getHubsTypeUrl = ServerBaseAddress + '/hubs'

export const HubCreatedUrl = ServerBaseAddress + '/hubs'

export const getHubUrl = ServerBaseAddress + '/levels/'
export const getHubSubUrl = '/hubs'
export const getByLevelId = "/devices"

export const deviceCreateBulk = ServerBaseAddress + "/devices/bulk"

export const getDeviceUrl = ServerBaseAddress + "/hubs/"
export const getDeviceSubUrl = "/devices"

export const getLastEventsUrl = ServerBaseAddress + "/levels/"
export const getLastSubEventsUrl = "/events"

export const getDeviceByDeviceIdURL = ServerBaseAddress + "/devices/"

export const getLastEventsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getLastSubEventDeviceIdURL = '/events'


export const getMultiDeviceHistoryURL = ServerBaseAddress + "/devices/events/compare"
export const getMultiDeviceConsumptionURL = (id) => ServerBaseAddress + "/devices/" + id + "/energy/consumption"
// export const getMultiDeviceConsumptionURL = (id) => "https://analytics2.s5.ottomatically.com/api/v1/devices/" + id + "/energy/consumption"

export const getAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getSubAnylaticsByDeviceIdURL = '/events/temp/stats'

export const getStatusAnylaticsByDeviceIdURL = ServerBaseAddress + "/devices/"
export const getSubStatusAnylaticsByDeviceIdURL = "/duration"

export const getPostAlertURL = ServerBaseAddress + "/alerts"

export const getAlertUrl = ServerBaseAddress + "/devices/"
export const getSubAlertUrl = "/alerts"

export const getUpdateAlertUrl = ServerBaseAddress + "/alerts/"

export const getImgUrl = "https://organizations2.s5.ottomatically.com/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"
// export const getImgUrl = process.env.REACT_APP_API_URL + ":5001/api/v1"

export const ForgotPasswordEmail = ServerBaseAddress + "/open/users/request-password"
export const ForgetPassword = ServerBaseAddress + "/open/users/forget-password"

export const overviewById = ServerBaseAddress + "/overviews/level/"

export const overviewCRUD = ServerBaseAddress + "/overviews"

export const imageURL = getImgUrl + "/files"

export const getRoles = (id) => ServerBaseAddress + "/roles/" + id
export const createRoles = ServerBaseAddress + "/roles/"

export const createUser = ServerBaseAddress + "/admin/signup"
export const User = ServerBaseAddress + "/users/"
export const VerifyUser = ServerBaseAddress + "/open/users/verify-password/"
export const getUser = ServerBaseAddress + "/users"
export const functionsArray = ServerBaseAddress + "/functions"

export const acKnowledge = ServerBaseAddress + "/notifications"
// notification Id
export const hubInfoFiles = "https://ottomatically.com/inventory-server/images/ottomatically.com.zip"
// export const getImgUrl = "http://192.168.10.14:5001/api/v1"

export const updateDevice = ServerBaseAddress + "/devices/"

// export const summary = "http://192.168.10.3:5001/api/v1/levels/"
// export const summary = "http://192.168.100.157:5001/api/v1/levels/"
// export const summary = "http://10.101.98.2:5000/api/v2/levels/"
export const summary = ServerBaseAddress + "/levels/"
export const summary1 = "/summary"

export const level = ServerBaseAddress + "/levels/"
export const CPLevel = ServerBaseAddress + "/level"

export const create_getRules = ServerBaseAddress + "/rules"
export const get_rules_firstHalf = ServerBaseAddress + "/levels/"
export const get_rules_secondHalf = "/rules"

export const Getuseradmin = ServerBaseAddress + "/admin";
export const GetRootLevels = ServerBaseAddress + "/getRootLevels"
export const GetOrganizations = (id) => ServerBaseAddress + "/branches/update/users/" + id
export const DelOrganization1 = ServerBaseAddress + "/levels/update/users/"
export const DelOrganization2 = "/users/"
export const DelOrganization3 = ServerBaseAddress + "/levels/"
export const DelOrganization4 = ServerBaseAddress + "/branches/"
export const Config = ServerBaseAddress + "/db/config"
export const DashboardConfig = (dashboardId) => ServerBaseAddress + "db/config/" + dashboardId
export const Access = (id) => ServerBaseAddress + "/access/" + id
export const Accesscon = ServerBaseAddress + "/access"
export const roles = ServerBaseAddress + "/roles"
export const rolescon = (id) => ServerBaseAddress + "/roles/" + id
export const Delroles = "/harddelete"
export const GetallHub = ServerBaseAddress + "/allhubs"
export const Getlevels = ServerBaseAddress + "/levels"
export const CreateHubs = (id) => ServerBaseAddress + "/hubs" + id
export const updateHubs = (id) => ServerBaseAddress + "/db/config/" + id
export const levelHirarchy = (id) => ServerBaseAddress + "/levels/" + id + "/hierarchy"
export const Imageupload = ServerBaseAddress + "/files"
export const deletehub = (id) => ServerBaseAddress + "/hubs/" + id
export const updateHubmangment = (id) => ServerBaseAddress + "/hubs/" + id
export const CreateHubmangment = ServerBaseAddress + "/hubs"
export const userharddel = (id) => ServerBaseAddress + "/users/" + id +"/hard"

