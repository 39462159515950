import React, { useState, useEffect } from 'react'
// Import Resources
import {
  signIn,
  checkSignIn,
  resendCode,
  setPassword,
  requestForgotPasswordEmail,
  ForgotPassword
} from '../../services/utilities/users'
import "./users.scss"
import InlineLoader from "../../components/loader/inlineLoader"
import LtLogo from "./logo.svg";
import Logo from "../../assets/svgs/otto.svg";
import Mypopup from '../../components/popup/mypopup';
import { Link, useNavigate, useParams, useLocation, Navigate, useNavigation } from 'react-router-dom';
import { signUp } from '../../services/utilities/users';
import { getVerification } from '../../services/utilities/users';
import MyDropDown, { MyDropDownBody } from '../../components/dropdown/dropdown';
import { parseJwt } from '../../functions/functions';
import { EmailRevoke } from '../../services/config/generic';
import cookies from '../../services/utilities/cookies';
import Layout from '../publicLayout/layout';
import Header from '../publicLayout/header';
// import Otto from '../../assets/svgs/Ottomatically_logo.svg'
import LT from "../../assets/svgs/Ottomatically_logo_horizontal.png";


import {
  EmailRegex,
  WhiteSpace,
  CapitalLetters,
  SmallLetters,
  Numbers,
  SpecialCharacters2
} from '../../services/config/regex';
import CardLoader from '../../components/loader/cardLoader';
import { dataHandler } from '../../functions/functions';

// import videoBack from '../../assets/video/welcome.mp4'
import { getVerifyUserToken } from '../../services/utilities/events';
import FullLoader from '../../components/loader/fullPageLoader';
import UnAuthorized from '../unAuthorized/unAuthorized';



export default function Login(props) {
  const [emailId, setEmailId] = useState({})
  const { id } = useParams()
  const location = useLocation()
  const query = new URLSearchParams(location?.search)
  const userid = props?.id ? props.id : query?.get("token")
  const UnAuthorize = query?.get("unauthorize")


  const pageName = window.location.hash.split("/")[1]
  const [popup, setPopup] = useState(null)
  const [popup1, setPopup1] = useState(null)
  const [state, setState] = useState({
    loginData: {},
    modal: false,
    redirect: false,
    loginLoader: false,
    user: "",
    pass: "",
    renderSignup: false,
  })
  let history = useNavigate();
  //All States
  //SignIn States
  const [loginId, setLoginId] = useState(false)
  const [signinPassword, setSigninPassword] = useState(false)
  //SignUp States
  const [visiblePass, setVisiblePass] = useState(false)
  const [visiblePassCnfrm, setVisiblePassCnfrm] = useState(false)
  const [inputPassword, setInputPassword] = useState(false)
  const [TokenValidation, setTokenValidation] = useState(false)
  const [email_recovery_password_validation, setEmail_recovery_password_validation] = useState(false)
  const [email_recovery_password, setEmail_recovery_password] = useState("")
  const [emailValidation, setEmailValidation] = useState(false)
  const [nameValidation, setNameValidation] = useState(false)
  const [companynameValidation, setCompanynameValidation] = useState(false)
  const [lastNameValidation, setLastNameValidation] = useState(false)
  const [designationValidation, setDesignationValidation] = useState(false)
  const [pass, setPass] = useState("")
  const [passValidation, setPassValidation] = useState(true)
  const [passcnfrm, setPassCnfrm] = useState("")
  const [passValidate, setPassValidate] = useState(true)
  const [localStorageids, setLocalStorageIds] = useState(false)
  const [timer, setTimer] = useState(0)
  const [verifyCode, setVerifyCode] = useState("")
  const [signupbody, setSignupbody] = useState({})
  const [specialCase, setSpecialCase] = useState(false)
  const [eightChar, setEightChar] = useState(false)
  const [whiteSpace, setWhiteSpace] = useState(true)
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [verifictionToken, setVerificationToken] = useState("")
  const [loader, setLoader] = useState(false)
  const [authPopup, setAuthPopup] = useState(false)



  const NotSupoortedEmail = [...new Set(EmailRevoke)];

  const isFound = () => {
    let result = NotSupoortedEmail.some(element => element === signupbody.email.split('@')[1].split('.')[0])
    return result
  }
  useEffect(() => {
    if (UnAuthorize) {
      if (cookies.getCookie('token')) {
        window.location.reload()
      } else {
        setAuthPopup(true)
      }
    } else {
      if (cookies.getCookie('token')) {
        window.location.href = "#/"
      }
    }
  }, [])

  useEffect(() => {
    onLoadFunc()
  }, [])

  const onLoadFunc = async () => {
    setLoader(true)
    if (id) {
      let EmailId = parseJwt(id)
      setEmailId(EmailId)
    } else if (userid) {
      let response = await getVerifyUserToken(userid)
      if (response) {
        if (response.status) {
          let EmailId = parseJwt(userid)
          setVerificationToken(userid)
          setEmailId(EmailId)
        } else {
          props.history("/login")
        }
      }

    }
    setLoader(false)
  }

  // Check SignIn Cookies 
  useEffect(() => {
    let check = checkSignIn()
    //console.log(check)
    if (check) {
      state.redirect = true;
      history("/", { replace: true })
      setState(e => e = state);
    }
    if (localStorage.getItem("veriPass")) {
      setLocalStorageIds(true)
    }
  }, [])
  // <!----- Login Functions Start ->
  // Check Login ID Input
  const checkLoginId = () => {
    if (document.getElementById("_id").value) {
      setLoginId(false)
      return false
    }
    else {
      setLoginId(true)
      return true
    }
  }

  // Check Login Password Input
  const checkLoginpass = () => {
    if (document.getElementById("pass").value) {
      setSigninPassword(false)
      return false
    }
    else {
      setSigninPassword(true)
      return true
    }
  }

  // User LogIn Function 
  const login = async (username, password) => {
    if (!checkLoginId()) {
      if (!checkLoginpass()) {
        let state1 = { ...state }
        state1.loginLoader = true;
        setState(state1)
        if (username, password) {
          let response = await signIn({
            email: username,
            password: password
          });
          // if (response.status === true) {
          //   let state2 = state;
          //   state2.loginLoader = false;
          //   // setPopup(["Success!", response.message, "success"])
          //   // setState(state2)
          // }
          if (response.statusCode) {
            let state2 = state;
            state2.loginLoader = false;
            setPopup(["Failure!", response.message, "danger"])
            setState(state2)
          }
        }
      }
    } else {
      setSigninPassword(false)
    }
  }
  // <!----- Login Functions End ->

  const loginOnKeyPress = (e) => {
    if (e.key === "Enter") {
      login(document.getElementById("_id").value,
        document.getElementById("pass").value)
    }
  }
  const signUpOnKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitEvent()
    }
  }

  const passwordOnKeyPress = (e) => {
    if (e.key === "Enter") {
      password()
    }
  }
  // <!----- SignUp Page -->

  // All SignUp Input OnChangehandler 
  const onChangeHandler = (e) => {
    let data = signupbody
    let name = e.target.id
    data[name] = e.target.value
    delete signupbody["pass"]

    delete signupbody["_id"]

    signupbody.role = "admin";

    if (e.target.id === "firstName") {
      setNameValidation(false)
    }
    if (e.target.id === "lastName") {
      setLastNameValidation(false)
    }
    if (e.target.id === "companyName") {
      setCompanynameValidation(false)
    }
    if (e.target.id === "designation") {
      setDesignationValidation(false)
    }
    setSignupbody(data)
    if (e.target.id === "email") {
      setEmailValidation(false)
    }
  }

  //Check Email  Validate or not  
  const ValidateEmail = () => {
    if (signupbody.email) {
      var mailformat = EmailRegex;
      if (signupbody.email.match(mailformat) && !isFound()) {
        setEmailValidation(false)
        return false
      }
      else {
        setEmailValidation(true)
        return true
      }
    }
    else {
      setEmailValidation(true)
      return true
    }
  }
  //Check Name is null or not
  const checkNameValidation = () => {
    if (signupbody.firstName === "" || signupbody.firstName === null || signupbody.firstName === undefined) {
      setNameValidation(true)
      return true
    }
    else {
      setNameValidation(false)
      return false
    }
  }
  //Check Last Name is null or not
  const checkLastNameValidation = () => {

    if (signupbody.lastName === "" || signupbody.lastName === null || signupbody.lastName === undefined) {
      setLastNameValidation(true)
      return true
    }
    else {
      setLastNameValidation(false)
      return false
    }
  }
  //Check Company Email is null or not
  const checkCompanyNameValidation = () => {

    if (signupbody.companyName === "" || signupbody.companyName === null || signupbody.companyName === undefined) {
      setCompanynameValidation(true)
      return true
    }
    else {
      setCompanynameValidation(false)
      return false
    }
  }
  //CheckEmail is  null or not
  const checkDesignationValidation = () => {

    if (signupbody.designation === "" || signupbody.designation === null || signupbody.designation === undefined) {
      setDesignationValidation(true)
      return true
    }
    else {
      setDesignationValidation(false)
      return false
    }
  }



  const checkEmail_recovery_password = () => {
    if (email_recovery_password) {
      var mailformat = EmailRegex;
      if (email_recovery_password.match(mailformat) && !isFound()) {
        setEmail_recovery_password_validation(false)
        return false
      }
      else {
        setEmail_recovery_password_validation(true)
        return true
      }
    }
    else {
      setEmail_recovery_password_validation(true)
      return true
    }
  }

  const ForgotPasswordEmail = () => {
    if (!checkEmail_recovery_password()) {
      let state1 = { ...state }
      state1.loginLoader = true;
      setState(state1)
      let body = {
        email: email_recovery_password
      }
      return requestForgotPasswordEmail(body).then(res => {
        let result = res
        if (result) {
          let message = result.message
          if (result.status === "OK") {
            let localStorageid = result.user.id;
            localStorage.setItem("veriPass", localStorageid.toString())
            state1.loginLoader = false;
            setState(state1)
            setPopup1(["Success!", "The password reset email has been sent. Please check your inbox.", "success"])
          }
          else {
            setPopup(["Failure!", message, "danger"])
            state1.loginLoader = false;
            setState(state1)
          }
        }
      })
    }
  }




  //<!-----Email Verification Code Page -->

  // user Validation DropDown
  const dropDown = (state = true, message, type) => {
    return (
      <MyDropDown openToggle={(e) => setHover(e)}>

        {state ? !type ?
          <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px 0 calc(1rem + 10px) -55px" }}></span>
          : <span className='btn btn-light fas fa-info-circle rounded-circle-px text-danger' style={{ margin: "10px -36px calc(1rem + 10px) -194px" }}></span>
          : null}
        <MyDropDownBody open={hover ? hover : focus ? focus : false} right>
          <div className='py-3 text-left p-3 ' style={{ width: "300px" }}>
            <small>{message}</small>
            {type === "password" ?
              <div style={{
                padding: 0,
                margin: 0,
                listStyleType: "none",
              }}>
                {eightChar ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Be a minimum of 8 characters</small></li>
                  :
                  <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Be a minimum of 8 characters</small></li>
                }
                {specialCase ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>
                  : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Conatins atleast three of the following capital letters, small letters,numbers, symbols</small></li>

                }
                {whiteSpace ?
                  <li><i className="far fa-check-circle mr-1 text-success"></i><small>Contain not be spaces</small></li>
                  : <li><i className="fas fa-info-circle mr-1 text-danger"></i><small>Contain not be spaces</small></li>
                }
              </div>
              : null}
          </div>
        </MyDropDownBody>
      </MyDropDown>)
  }

  //<!---Create Password Page-->
  // Check Password and Confirm Password Same or not 
  const cnfrmPassvalidate = (passcnfrm) => {
    if (pass === passcnfrm) {
      setPassValidate(true)
      return true
    } else {
      setPassValidate(false)
      return false
    }
  }
  // password OnChange Function
  const passChangeHandler = (e) => {
    setPass(e.target.value)
    Passwordvalidate(e.target.value)
    setPassValidation(true)
    return true
  }
  // confirm Password OnChange Function
  const passCnfrmHandler = (e) => {
    setPassCnfrm(e.target.value)
    cnfrmPassvalidate(e.target.value)
    return true
  }
  // Check Password  pass all conditions
  const Passwordvalidate = (pass) => {
    var whiteSpace1 = WhiteSpace
    if (whiteSpace1.test(pass)) {
      setWhiteSpace(false)
    } else {
      setWhiteSpace(true)
    }
    if (pass.length >= 8) {
      setEightChar(true)
    } else {
      setEightChar(false)
    }
    var passwordformat = [
      CapitalLetters,
      SmallLetters,
      Numbers,
      SpecialCharacters2]

    let count = 0
    for (let index = 0; index < passwordformat.length; index++) {
      const element = passwordformat[index];
      if (element.test(pass))
        count++
    }
    if (count >= 3) {
      setSpecialCase(true)

    }
    else {
      setSpecialCase(false)
    }
    if (specialCase && eightChar && whiteSpace) {
      setPassValidation(true)
      return true
    }
    else {
      setPassValidation(false)
      return false
    }
  }


  // password Created Function whan password Return true
  const password = () => {
    if (Passwordvalidate(pass)) {
      if (cnfrmPassvalidate(passcnfrm)) {
        let state1 = { ...state }
        state1.loginLoader = true;
        setState(state1)
        let body = null
        if (userid) {
          body = {
            password: pass,
            _id: emailId.id,
            token: verifictionToken
          }
        } else {
          let localStorageid = localStorage.getItem("veriPass");
          body = {
            password: pass,
            _id: localStorageid
          }
        }
        return setPassword(body).then(res => {
          if (res.status) {
            state1.loginLoader = false;
            setState(state1)

            setPopup(["Success!", res.message, "success"])
            localStorage.removeItem("information");
            localStorage.removeItem("verify");
            history("/login")
            // window.location.reload()
          }
          else {
            state1.loginLoader = false;
            setState(state1)
            setPopup(["Failure!", res.message, "danger"])
          }
        })
      }
    }
  }

  //This Function return SignUp Page
  const newAccount = () => {
    localStorage.removeItem("information");
    localStorage.removeItem("verify");
    // window.location.pathname = "/#/signup"
    history("/signup")
    window.location.reload()
  }
  //This Function return SignIn Page 
  const AccountLogin = () => {
    localStorage.removeItem("information");
    localStorage.removeItem("verify");
    // window.location.pathname = "/#/signup"
    history("/login")
    window.location.reload()
  }
  return (
    <>
      {!loader ?
        <Layout className='userPage'>

          <div className='backGifAnimaton'>

            <div className="w-100 h-100"  >
              <div
                className="d-flex align-items-center justify-content-center p-3"
                style={{ minHeight: "100%", }}
              >

                <div className='userCardBlur'></div>
                <div className="dropshadow d-flex justify-content-center align-self-center rounded-4 p-3 pt-5  " style={{ maxWidth: 500, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255,255,255,0.2)' }}>

                  {/* Sign In Page */}
                  <div className="row d-flex justify-content-center align-self-center fade-in-right-onload">
                    <div className="col-12">
                      <center>
                        <a href="#">
                          <img src={LT} alt="Linked-things Logo" />
                        </a>
                        <div className="col-12">
                          <h4 className="text-center m-4 text-primary-gradient">
                            UNLOCKING THE POWER OF IOT
                          </h4>
                        </div>

                      </center>
                    </div>
                    <div className="col-12 px-5">

                      <center>
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"
                            placeholder="Email"
                            type="email"
                            style={{
                              borderColor: !loginId ? "#1B75BC" : "#FF0000",
                            }}
                            id="_id"
                            onChange={(e) => onChangeHandler(e)}
                            onKeyDown={(e) => loginOnKeyPress(e)}
                          />{loginId ?
                            dropDown(loginId, "Please enter your email")
                            : null}
                        </div>
                        <div className='d-flex'>
                          <input
                            className="form-control rounded-4 py-4 px-4 mb-3"
                            placeholder="Password"
                            type={inputPassword ? "text" : "password"}
                            style={{
                              borderColor: !signinPassword ? "#1B75BC" : "#FF0000",
                            }}
                            id="pass"
                            onChange={(e) => onChangeHandler(e)}
                            onKeyDown={(e) => loginOnKeyPress(e)}
                          />
                          {signinPassword ?
                            dropDown(signinPassword, "Please enter your password", "signinPassword")
                            : null}
                          <button className={inputPassword ? 'btn btn-light  fa fa-eye-slash rounded-circle-px' : 'btn btn-light fa fa-eye rounded-circle-px'} onClick={() => setInputPassword(!inputPassword)} style={{ margin: "10px 0 calc(1rem + 10px) -55px", backgroundColor: "transparent" }}></button>
                        </div>
                        {/* <div className='d-flex justify-content-end'>
                          <Link to={'/forgot-password'}>Forgot your password?</Link>
                        </div> */}

                        {state.loginLoader ?
                          <div style={{ height: "80px" }}>
                            <InlineLoader />
                          </div> :

                          <button
                            className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                            onClick={() =>
                              login(
                                document.getElementById("_id").value,
                                document.getElementById("pass").value
                              )
                            }
                          >
                            Sign in
                          </button>
                        }

                      </center>
                    </div>
                    <div className="col-12 d-flex justify-content-center ">
                      <div className="mt-3 mb-2 p-3 rounded">
                        <img src={LtLogo} height={75} alt={'Linked Things Logo'} />
                      </div>
                    </div>
                  </div>

                  {/* Recovery password send Email */}
                  {/* <div className="row justify-content-center align-self-center fade-in-right-onload" style={{ display: pageName === "forgot-password" && !id ? "flex" : "none" }}>
                    <div className="col-12">
                      <center>
                        <img
                          width="100px"
                          src={Logo}
                        />
                        <h4 className="text-center m-0 my-3">Forgot Password</h4>
                      </center>
                    </div>
                    <div className="col-12 px-5">
                      <div>
                        <center>
                          <div className='d-flex'>
                            <input
                              className="form-control rounded-4 py-4 px-4 mb-3"

                              placeholder="abc@xyz.com"
                              type="text"
                              style={{
                                borderColor: !email_recovery_password_validation ? " #1B75BC" : "#FF0000",
                              }}
                              id="resetEmail"
                              value={email_recovery_password}
                              onChange={(e) => { setEmail_recovery_password(e.target.value) }}
                            />
                            {email_recovery_password_validation ?
                              dropDown(email_recovery_password_validation, "Please Enter Valid Email")
                              : null}</div>

                          {state.loginLoader ?
                            <div style={{ height: "80px" }}>
                              <InlineLoader />
                            </div> :
                            <>
                              <button
                                className="col-8 btn-lg border-0 btn-dark my-3 primary-gradient-hv dropshadow rounded-5"
                                onClick={() => ForgotPasswordEmail()}
                              >
                                Send Email
                              </button>
                            </>}

                          <div className="col-12 mt-2"  >
                            <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => newAccount()}>
                              Sign Up with Another Account
                            </div>
                          </div>
                          <div className="col-12 mt-2"  >
                            <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={() => AccountLogin()}>
                              Already have an account?
                            </div>
                          </div>
                        </center></div>


                    </div>
                    <div className="col-12 d-flex justify-content-center ">
                      <div className="mt-3 mb-2 p-3 rounded">
                        <img src={LtLogo} height={75} />
                      </div>
                    </div>

                  </div> */}
                  {/* </div> */}
                </div >
              </div >

              {
                popup1 ? <Mypopup title={popup1[0]} type={popup1[2]} noClose={"noClose"} >
                  <div className="d-flex  justify-content-end p-3">
                    <Link className='mr-3' to={"/login"} onClick={() => { setPopup1(false) }}>
                      SignIn
                    </Link>
                    <Link to={"/signup"} onClick={() => { setPopup1(false) }} >
                      SignUp
                    </Link>
                  </div>
                  <p className="p-3 fa-lg"  > {popup1[1]}</p>
                </Mypopup >
                  : null}
              {
                popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                  setPopup(null);
                }
                }>
                  <p className="p-3" > {popup[1]}</p>
                  <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                      setPopup(null);
                    }}>Ok</button>
                  </div>
                </Mypopup >
                  : null
              }
              {authPopup ? <UnAuthorized onChange={() => setAuthPopup(false)} /> : null}
            </div>
          </div>
        </Layout > : <FullLoader />}
    </>
  )
}


