import React, { useEffect, useState } from 'react';
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton';
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card';
import AddLevelPopup from './addLevelPopup';
import Mypopup from '../../components/popup/mypopup';
import { GetRoots, deleteLevel, getHierarchy, getlevels } from '../../services/utilities/events';
import Select from '../../components/select/select';
import CardLoader from '../../components/loader/cardLoader';
import { Sort, Sortlevel, flattenArray } from '../../functions/functions';
import { ResponseMessageLocal } from '../../services/config/generic';
import moment from 'moment';
import Input from '../../components/input/input';

const LevelManagement = () => {
    const [addLevel, setAddLevel] = useState(null);
    const [rootLevel, setRootLevel] = useState([]);
    const [level, setLevel] = useState([]);
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [loader, setLoader] = useState(true);
    const [confirmPopup, setConfirmPopup] = useState(null);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [messagePopup, setMessagePopup] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('all');
    const [sortType, setSortType] = useState('text-asc')
    const [sortedUsers, setSortedUsers] = useState([])

    useEffect(() => {
        // console.log('Fetching root levels and levels...');
        rootLevelGet();
    }, []);

    useEffect(() => {
        // console.log('Filtering levels with search term:', searchTerm);
        filterLevels();
    }, [searchTerm]);

    const deleteBranch = async (id) => {
        // console.log('Deleting branch with id:', id);
        setDeleteLoader(true);
        await deleteLevel(id);
        setDeleteLoader(false);
        setConfirmPopup(null);
        reload();
    };

    const rootLevelGet = async () => {
        try {
            const root = await GetRoots();
            // console.log('Fetched root levels:', root);
            const organization = root.map(org => ({
                label: org,
                value: org
            }));
            setRootLevel(organization);

            // Fetch levels for the first organization or a default organization
            if (organization.length > 0) {
                const defaultOrg = organization[0].value;
                const allLevels = await getlevels(defaultOrg);
                // console.log('Fetched all levels:', allLevels);
                setLevel(allLevels);
            }
        } catch (error) {
            // console.error('Error fetching root levels or levels:', error);
        } finally {
            setLoader(false);
        }
    };

    const onChangeHandler = async (e) => {
        if (e.name === "organization") {
            // console.log('Fetching hierarchy for organization:', e.target.value);
            try {
                const branch = await getHierarchy(e.target.value);
                // console.log('Fetched branch levels:', branch.levels);
                const flattenedArray = flattenArray(branch.levels);
                setFilteredLevels(flattenedArray);
                const alllevel = await getlevels(e.target.value);
                // console.log(alllevel);
                setLevel(alllevel);
            } catch (error) {
                // console.error('Error fetching hierarchy:', error);
            }
        }
    };

    const reload = () => {
        // console.log('Reloading data...');
        setFilteredLevels([]);
        setSearchTerm('');
    };

    const handleSearchChange = (e) => {
        // console.log('Search term changed to:', e.target.value);
        setSearchTerm(e.target.value);
    };

    const filterLevels = () => {
        if (activeTab === 'filtered') {
            if (searchTerm) {
                // console.log('Filtering filtered levels with search term:', searchTerm);
                const filtered = filteredLevels.filter(lvl =>
                    lvl.locationName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    lvl.contactName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    lvl.contactNumber.includes(searchTerm)
                );
                setFilteredLevels(filtered);
            } else {
                // console.log('No search term provided. Showing all filtered levels.');
                setFilteredLevels(filteredLevels);
            }
        } else if (activeTab === 'all') {
            if (searchTerm) {
                // console.log('Filtering levels in "Organization" tab with search term:', searchTerm);
                const filtered = level.filter(lvl =>
                    lvl.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    lvl.companyUrl?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    lvl.addressOfHeadquater?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
                setLevel(filtered);
            } else {
                // console.log('No search term provided. Showing all levels.');
                rootLevelGet(); // Reload all levels if no search term
            }
        }
    };

    const handleTabChange = (tab) => {
        // console.log('Tab changed to:', tab);
        setActiveTab(tab);
        if (tab === 'all') {
            setSearchTerm('');
        }
    };
    const sortData = (type, objKey) => {
        setSortType(type);
        if (activeTab === 'all') {
            const sorted = Sort(type, level, objKey);
            setSortedUsers(sorted);
        } else if (activeTab === 'filtered') {
            const sorted = Sort(type, filteredLevels, objKey);
            setSortedUsers(sorted);
        }
    };
    useEffect(() => {
        // console.log('Sorting data with sort type:', sortType);
        sortData(sortType);
    }, [level, filteredLevels, activeTab]);  // Include activeTab in dependencies

    return (
        <div className='p-3'>
            {loader ? <CardLoader /> :
                <>
                    <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                        <CardHeader className="d-flex justify-content-between">
                            <Heading>
                                Location Management
                            </Heading>
                            <ButtonContainer color="primary-gradient text-white">
                                <Button onClick={() => setAddLevel(true)}>Add</Button>
                            </ButtonContainer>
                        </CardHeader>
                        <CardBody>
                            <div className="row m-0 justify-content-end">
                               
                                {activeTab === 'all' ?
                                    <div className='col-12 mb-2'>
                                        <Input
                                            label="Search Organizations" placeholder="Search..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </div>:<>
                                    
                                        <div className='col-12 mb-3'>
                                            <Select label="Select Organization" name="organization" onClick options={rootLevel} onChange={onChangeHandler} />
                                        </div>
                                        {filteredLevels.length > 0 && (
                                            <div className='col-12 mb-2'>
                                                <Input
                                                    label="Search Locations" placeholder="Search..."
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        )}
                                    </>
                            }


                                <div className='px-3 mb-2'>
                                    <ButtonContainer >
                                        <Button onClick={() => handleTabChange('all')} active={activeTab === 'all'}>Organization</Button>
                                        <Button onClick={() => handleTabChange('filtered')} active={activeTab === 'filtered'}>Branch Location</Button>
                                    </ButtonContainer>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    {activeTab === 'all' && sortedUsers.length > 0 && (
                        <>
                            <Card className="mb-3 position-sticky">
                                <CardHeader className="row m-0">
                                    <div className="col-1 px-0 col-sm-2 col-lg-1">
                                        <Heading >S.no</Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>
                                        <Heading className="d-flex gap-2">Name
                                            <div className='ml-2'>

                                                {sortType.includes('nam') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>
                                        </Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-company-asc' : 'text-company-' + (sortType.includes('asc') ? 'des' : 'asc')), 'companyUrl')}>
                                        <Heading className="d-flex gap-2">Company Url
                                            <div className='ml-2'>

                                                {sortType.includes('company') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>
                                        </Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-add-asc' : 'text-add-' + (sortType.includes('asc') ? 'des' : 'asc')), 'addressOfHeadquater')}>
                                        <Heading className="d-flex gap-2">Address
                                            <div className='ml-2'>

                                                {sortType.includes('add') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>
                                        </Heading>
                                    </div>
                                    <div className="col-1 col-sm-1 col-lg-2" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                        <Heading className="d-flex gap-2">Created
                                            <div className='ml-2'>
                                                {sortType.includes('date') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>

                                        </Heading>

                                    </div>
                                    <div className="col-2 col-sm-2 col-lg-2 justify-content-end">
                                        <Heading align="end">Action</Heading>
                                    </div>
                                </CardHeader>
                            </Card>
                            {sortedUsers.map((lvl, index) => (  // Use sortedUsers here
                                <Card key={index} className="overflow-hidden mt-1">
                                    <CardHeader className='row m-0 align-items-center'>
                                        <div className='col-sm-1 col-lg-1 text-truncate'>
                                            {index + 1}
                                        </div>
                                        <div className='col-3 col-sm-3 col-lg-2'>{lvl.name}</div>
                                        <div className='col-2 col-sm-3 col-lg-2'>{lvl.companyUrl}</div>
                                        <div className='col-3 col-sm-3 col-lg-3'>{lvl.addressOfHeadquater}</div>
                                        <div className='col-3 col-sm-3 col-lg-2'>{moment(lvl.created).format("ll LTS")}</div>
                                        <div className='col-2 col-sm-2 col-lg-2 d-flex justify-content-end'>
                                            <ButtonContainer className="p-2 btn-light" flat="light">
                                                <Button onClick={() => setAddLevel(lvl)}>
                                                    <i className="lt lt-edit"></i>
                                                </Button>
                                                <Button onClick={() => { setConfirmPopup({ _id: lvl._id }) }}>
                                                    <i className="lt lt-trash"></i>
                                                </Button>
                                            </ButtonContainer>
                                        </div>
                                    </CardHeader>
                                </Card>
                            ))}
                        </>
                    )}

                    {activeTab === 'filtered' && sortedUsers.length > 0 && (
                        <>
                            <Card className="mb-3 position-sticky">
                                <CardHeader className="row m-0">
                                    <div className="col-1 px-0 col-sm-2 col-lg-1">
                                        <Heading >S.no</Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-LC-asc' : 'text-LC-' + (sortType.includes('asc') ? 'des' : 'asc')), 'locationName')}>

                                        <Heading className="d-flex gap-2">Location Name
                                            <div className='ml-2'>

                                                {sortType.includes('LC') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }</div>
                                        </Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-CN-asc' : 'text-CN-' + (sortType.includes('asc') ? 'des' : 'asc')), 'contactName')}>
                                        <Heading className="d-flex gap-2">Contact Name
                                            <div className='ml-2'>

                                                {sortType.includes('CN') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>
                                        </Heading>
                                    </div>
                                    <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-CU-asc' : 'text-CU-' + (sortType.includes('asc') ? 'des' : 'asc')), 'contactNumber')}>
                                        <Heading className="d-flex gap-2">ContactNumber
                                            <div className='ml-2'>

                                                {sortType.includes('CU') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>
                                        </Heading>
                                    </div>
                                    <div className="col-1 col-sm-1 col-lg-2 " style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                        <Heading className="d-flex gap-2">Created
                                            <div className='ml-2'>
                                                {sortType.includes('date') ?
                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                    :
                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                }
                                            </div>

                                        </Heading>

                                    </div>
                                    <div className="col-2 col-sm-2 col-lg-2">
                                        <Heading align="end">Action</Heading>
                                    </div>
                                </CardHeader>
                            </Card>
                            {sortedUsers.map((lvl, index) => (
                                <Card key={index} className="overflow-hidden mt-1">
                                    <CardHeader className='row m-0 align-items-center'>
                                        <div className='col-sm-1 col-lg-1 text-truncate'>
                                            {index + 1}
                                        </div>
                                        <div className='col-3 col-sm-3 col-lg-2'>{lvl.locationName}</div>
                                        <div className='col-2 col-sm-3 col-lg-2'>{lvl.contactName}</div>
                                        <div className='col-3 col-sm-3 col-lg-3'>{lvl.contactNumber}</div>
                                        <div className='col-3 col-sm-3 col-lg-2'>{moment(lvl.created).format("ll LTS")}</div>
                                        <div className='col-2 col-sm-2 col-lg-2 d-flex justify-content-end'>
                                            <ButtonContainer className="p-2 btn-light" flat="light">
                                                <Button onClick={() => setAddLevel(lvl)}>
                                                    <i className="lt lt-edit"></i>
                                                </Button>
                                                <Button onClick={() => { setConfirmPopup({ _id: lvl._id }) }}>
                                                    <i className="lt lt-trash"></i>
                                                </Button>
                                            </ButtonContainer>
                                        </div>
                                    </CardHeader>
                                </Card>
                            ))}
                        </>
                    )}

                    {confirmPopup && (
                        <Mypopup title="Confirm!" onClose={() => setConfirmPopup(null)}>
                            <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                            {deleteLoader ? (
                                <CardLoader />
                            ) : (
                                <div className="d-flex justify-content-center p-3">
                                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteBranch(confirmPopup._id)}>Yes</button>
                                    <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmPopup(null)}>No</button>
                                </div>
                            )}
                        </Mypopup>
                    )}

                    {addLevel && (
                        <Mypopup data={addLevel} title={addLevel._id ? "Edit Location" : "Add Location"} onClose={() => setAddLevel(null)}>
                            <AddLevelPopup organizationName={rootLevel} data={addLevel} onAddUser={(e) => { setMessagePopup(e); e[2] === "success" && setAddLevel(null); }} />
                        </Mypopup>
                    )}

                    {messagePopup && (
                        <Mypopup title={messagePopup[0]} onClose={() => setMessagePopup(null)}>
                            <div className='text-center mt-3'>
                                <p>{messagePopup[1]}</p>
                                <br />
                                <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={() => { setMessagePopup(null); if (messagePopup[2] === "success") reload(); }}>Ok</button>
                            </div>
                        </Mypopup>
                    )}
                </>
            }
        </div>
    );
};

export default LevelManagement;
