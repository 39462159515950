import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const eventReducer = createSlice({
    name: 'event',
    initialState,
    reducers: {
        ADD_EVENT: (state, action) => {
            state.deviceEvents = action.payload
        }
    }
})
export const { ADD_EVENT } = eventReducer.actions

export default eventReducer.reducer
