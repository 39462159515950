import React, { useEffect, useState } from 'react'
import { BusinessCategory, organizationsType } from '../../services/config/generic'
import { Heading } from '../../components/card/card'
import Select from '../../components/select/select'
import Input from '../../components/input/input'
import { fetchData, flattenArray } from '../../functions/functions'
import { addLevelOrganizationUpdate, getHierarchy } from '../../services/utilities/events'
import CardLoader from '../../components/loader/cardLoader'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { SpecialCharacters, WhiteSpace } from '../../services/config/regex'

const AddLevelPopup = (props) => {
    console.log("Props received:", props);  // Log props for debugging
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    });

    const [hierarchyLoader, setHeirarchyLoader] = useState(true);
    const [level, setLevel] = useState({
        metadata: {
            coordinates: {
                lat: 0,
                lng: 0,
            }
        }
    });

    const [branchLevel, setBranchLevel] = useState([]);
    const [levelLoader, setLevelLoader] = useState(false);

    useEffect(() => {
        console.log("Inside useEffect, props.data:", props.data);  // Log initial data
        if (props.data?._id) {
            let data1 = { ...props.data };
            if (data1.level === 1) {
                data1["type"] = "organization";
            } else {
                data1["type"] = "level";
                data1["organization"] = data1.levelId.split("_")[0];
                callData(data1.organization);
            }
            setLevel(data1);
        }
    }, []);

    const onChangeHandler = (e) => {
        console.log("onChangeHandler triggered, event:", e);  // Log the event object
        const level1 = { ...level };
        let name = e.name;
        let value = e.target.value
        level1[name] = e.target.value;
        console.log("Updated level state:", level1);  // Log updated state
        if (name === "organization") {
            callData(level1.organization);
        }
        if (name === "metadataLat" || name === "metadataLng") {
            const coordinateKey = name === "metadataLat" ? "lat" : "lng";  // Determine which coordinate to update
            level1.metadata.coordinates[coordinateKey] = value;  // Update specific coordinate
        }
        setLevel(level1);
    };

    const addLevelOrganization = async () => {
        console.log("addLevelOrganization called");  // Log function call
        let state1 = { ...state };
        if (!("_id" in level)) {
            level["_id"] = level?.name ? level.name.replaceAll(WhiteSpace, "") : level.locationName.replaceAll(WhiteSpace, "");
            level._id = level._id.replaceAll(SpecialCharacters, "");
        }
        console.log("Prepared level data for update:", level);  // Log prepared data
        return await addLevelOrganizationUpdate(props.data._id ? props.data._id : null, level).then(async res => {
            console.log("API Response:", res);  // Log API response
            let result = res;
            let message = res.message;
            if (result) {
                props.onAddUser(["Success!", props.data._id ? "Successfully Updated." : "Successfully Created.", "success"]);
                state1.loginLoader = false;
            } else {
                props.onAddUser(["Failure!", message, "danger"]);
                state1.loginLoader = false;
            }
        });
    };

    const callData = async (level) => {
        console.log("callData triggered with level:", level);  // Log level parameter
        const branch = await fetchData(level);
        console.log("Fetched branch data:", branch);  // Log fetched data
        setBranchLevel(branch);
        setHeirarchyLoader(false);
    };

    return (
        <div className='px-3 pb-3'>
            <div>
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Location Information</Heading>
                </div>

                <div className='row '>
                    <div className='col-md-12 mt-3'>
                        <Select label="Type*" name="type" onClick options={organizationsType} defaultValue={{ value: level.type }} onChange={(e) => onChangeHandler(e)} />
                    </div>
                </div>
                {level.type === "level" ?
                    <div className='row '>
                        <div className='col-md-6 mt-3'>
                            <Input label="Name*" name="locationName" onClick defaultValue={level.locationName} onChange={(e) => onChangeHandler(e)} />
                        </div>
                        <div className='col-md-6 mt-3'>
                            <Select label="Organization*" name="organization" onClick options={props.organizationName} defaultValue={{ value: level.organization }} onChange={(e) => onChangeHandler(e)} />
                        </div>
                        {!hierarchyLoader &&
                            <>
                                <div className='col-md-12 mt-3'>
                                    <Select label="LevelId*" name="levelId" onClick options={branchLevel} defaultValue={{ value: level.levelId }} onChange={(e) => onChangeHandler(e)} />
                                </div>
                                <div className='col-md-6 col-12  mt-3'>
                                    <Input name="contactName" label="Contact person name*" defaultValue={level.contactName} onChange={(e) => onChangeHandler(e)} />
                                </div>
                                <div className='col-md-6 col-12  mt-3'>
                                    <Input name="contactNumber" label="Contact person number*" type="number" defaultValue={level.contactNumber} onChange={(e) => onChangeHandler(e)} />
                                </div>
                                <div className='col-md-6 col-12 mt-3'>
                                    <Input name="metadataLat" label="Latitude" defaultValue={level.metadata?.coordinates?.lat} onChange={onChangeHandler} />
                                </div>
                                <div className='col-md-6 col-12 mt-3'>
                                    <Input name="metadataLng" label="Longitude" defaultValue={level.metadata?.coordinates?.lng} onChange={onChangeHandler} />
                                </div>
                            </>
                        }
                    </div>

                    : null}
                {level.type === "organization" ?
                    <div className='row '>
                        <div className='col-md-6 mt-3'>
                            <Input label="Name*" name="name" onClick defaultValue={level.name} onChange={(e) => onChangeHandler(e)} />
                        </div>
                        <div className='col-md-6 mt-3 col-12'>
                            <Input name="companyUrl" label="Company URL*" defaultValue={level.companyUrl} onChange={(e) => onChangeHandler(e)} className="text-primary" />
                        </div>
                        <div className='col-md-6 my-3 col-12'>
                            <Select name="organizationBusiness" label="Organization Business*" defaultValue={{ value: level.organizationBusiness }} onClick options={BusinessCategory} onChange={(e) => onChangeHandler(e)} />
                        </div>
                        <div className='col-md-6 mt-3 col-12'>
                            <Input name="addressOfHeadquater" label="Address*" defaultValue={level.addressOfHeadquater} onChange={(e) => onChangeHandler(e)} />
                        </div>
                        <div className='col-md-6 col-12 mt-3'>
                            <Input name="metadataLat" label="Latitude" defaultValue={level?.metadata?.coordinates?.lat} onChange={onChangeHandler} />
                        </div>
                        <div className='col-md-6 col-12 mt-3'>
                            <Input name="metadataLng" label="Longitude" defaultValue={level?.metadata?.coordinates?.lng} onChange={onChangeHandler} />
                        </div>
                    </div> : null
                }
                {levelLoader ? <CardLoader /> :
                    <ButtonContainer className="mt-3">
                        <Button onClick={() => addLevelOrganization()}>
                            {props.data?._id ? "save" : "Add"}
                        </Button>
                    </ButtonContainer>
                }
            </div>
        </div>
    )
}

export default AddLevelPopup