import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card, { CardBody } from "../../components/card/card";
import Devices from "../configurtionManagment/Devices";
function Sidebar(props) {
  // let levelId = window.location.hash
  let levelId = "";
  let { pathname } = useLocation();
  const [animationType, setAnimationType] = useState('fade-in-left-onload');
  const [management, setManagment] = useState(false);
  // const [dashboardDesign, setDashboardDesign] = useState(false);
  const [configurtionmanagment, setConfigurtionManagment] = useState(false);
  // const [deviceManagement, setDeviceManagement] = useState(false);
  levelId = pathname.split("/");
  levelId = levelId[levelId.length - 1];
  let orgId = levelId;
  if (orgId.match("_")) {
    let orgIdList = orgId.split("_");
    orgId = orgIdList[0];
  }
  useEffect(() => {
    if ("onCloseBtn" in props) {
      if (props.onCloseBtn) {
        setConfigurtionManagment(props.onCloseBtn);
        setManagment(props.onCloseBtn);
      }
    }
  }, [props.onCloseBtn]);
  return (
    <div className={animationType}>
      <ul className="nav row">
        <li className="col-12 nav-item" title="Dashboard">
          <a
            id="Dashboard"
            className={
              "navbtn text-truncate" +
              (pathname === "/" || pathname.match("/organizations")
                ? " active"
                : "")
            }
            href={"#/"}
          >
            <i className="navIcon lt lt-dashboard"></i> Dashboard
          </a>
        </li>
      </ul>
      <ul className="nav row">
        <li className="col-12 nav-item" title="MQTT">
          <a
            id="Mqtt"
            className={
              "navbtn text-truncate" +
              (pathname.match("/MQTT")
                ? " active"
                : "")
            }
            href={"#/MQTT"}
          >
            <i className="navIcon fas fa-server"></i> MQTT
          </a>
        </li>
      </ul>
      <ul className="nav row">
        <li className="col-12 nav-item" title="Management">
          <a
            onClick={() => {
              setManagment(!management);
              props?.onOpenSideBar(true);
            }}
            id="Management"
            className={
              "navbtn d-flex justify-content-between text-truncate" +
              (pathname.toLowerCase().match("manage") || pathname.match("role") ? " active" : "")
            }
          >
            <div>
              <i className="navIcon lt lt-management"></i> <span>Management</span>
            </div>
            <i
              className={
                "lt lt-angle-" +
                (management ? "r" : "d") +
                " mx-1"
              }
            ></i>
          </a>
          <Card
            className="sub-navbtn mb-1 overflow-hidden"
            style={{ display: management ? "none" : "block" }}
          >
            <CardBody className="d-flex flex-column ">
              <>
                <a
                  id="UserManagement"
                  className={"text-truncate" + (pathname.match("userManagement") ? " active" : "")}
                  href={"#/userManagement"}
                // href={"#/usermanagement/" + orgId}
                >
                  <i
                    className="navIcon lt lt-user-group"

                  ></i>
                  Users
                </a>
              </>
              {/* )
            : null} */}
              {/* {deviceManagement ? ( */}
              <>
                <a
                  className={"text-truncate" + (pathname.match("levelManagement") ? " active" : "")}
                  href={"#/levelManagement"}
                >
                  <i
                    className="navIcon  lt lt-location"

                  ></i>
                  Locations
                </a>
              </>
              <>
                <a
                  className={"text-truncate" + (pathname.match("hubManagement") ? " active" : "")}
                  href={"#/hubManagement"}
                >
                  <i
                    className="navIcon  lt lt-hub"

                  ></i>
                  Hubs
                </a>
              </>
              <>
                <a
                  className={"text-truncate" + (pathname.match("deviceManagement") ? " active" : "")}
                  href={"#/deviceManagement"}
                >
                  <i
                    className="navIcon  lt lt-device"

                  ></i>
                  Devices
                </a>
              </>
              {/* ) : null} */}
            </CardBody>
          </Card>
        </li>
      </ul>
      <ul className="nav row" onClick={() => {
        setConfigurtionManagment(!configurtionmanagment);
        props?.onOpenSideBar(true);
      }}
        id="Configuration"
      >
        <li className="col-12 nav-item" title="Dashborad Configurations">
          <a
            id="Configuration"
            className={
              "navbtn d-flex justify-content-between text-truncate" +
              (pathname.match("/Configurations")
                ? " active"
                : "")
            }
          >
            <div>

              <i className="navIcon far fa-file-alt"></i><span className="ml-1">Configurations
              </span>
            </div>
            <i
              className={
                "lt lt-angle-" +
                (configurtionmanagment ? "r" : "d") +
                " mx-1"
              }
            ></i>
          </a>
          <Card
            className="sub-navbtn mb-1 overflow-hidden"
            style={{ display: configurtionmanagment ? "none" : "block" }}
          >
            <CardBody className="d-flex flex-column ">

              {!configurtionmanagment ? (<>
                <a
                  id="Devices"
                  className={"text-truncate" + (pathname.match("Roles") ? " text-primary" : "")}
                  href={"#/Configuration/Roles"}
                >
                  <i
                    className="navIcon lt lt-user-setting"

                  ></i>
                  Roles</a>

              </>) : null}
              {!configurtionmanagment ? (<>
                <a
                  id="Devices"
                  className={"text-truncate" + (pathname.match("Access") ? " text-primary" : "")}
                  href={"#/Configuration/Access"}
                >
                  <i
                    className="navIcon lt lt-lock"

                  ></i>
                  Access</a>

              </>) : null}
              {!configurtionmanagment ? (<>
                <a
                  id="Devices"
                  className={"text-truncate" + (pathname.match("Level") ? " text-primary" : "")}
                  href={"#/Configuration/Level"}
                >
                  <i
                    className="navIcon  lt lt-location"

                  ></i>
                  Locations</a>

              </>) : null}
              {!configurtionmanagment ? (<>
                <a
                  id="Devices"
                  className={"text-truncate" + (pathname.match("/Hubs") ? " text-primary" : "")}
                  href={"#/Configuration/Hubs"}
                >
                  <i
                    className="navIcon lt lt-hub"

                  ></i>
                  Hubs</a>

              </>) : null}
              {!configurtionmanagment ? (<>
                <a
                  id="Devices"
                  className={"text-truncate" + (pathname.match("/Devices") ? " text-primary" : "")}
                  href={"#/Configuration/Devices"}
                >
                  <i
                    className="navIcon lt lt-device"

                  ></i>
                  Devices
                </a>
              </>) : null}
            
            </CardBody>

          </Card></li>
      </ul>


    </div>
  );
}
export default Sidebar;
