import React, { useEffect, useState } from 'react'
//import Resource
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import './addDeviceForm.scss'
import Input from '../../components/input/input';
import { GetRoots, deviceCreatedBulk, deviceUpdate, getHubsByLevelId } from '../../services/utilities/events';
import InlineLoader from '../../components/loader/inlineLoader';
import Mypopup from '../../components/popup/mypopup';
import Select from '../../components/select/select';
import CardLoader from '../../components/loader/cardLoader';
//import libraries
import { connect, useSelector } from 'react-redux';
// import { orgAction } from '../../services/actions/orgAction';
import { fetchData, getLevelIds } from '../../functions/functions';
import { SpecialCharacters, SpecialCharacters2 } from '../../services/config/regex';
import { Heading } from '../../components/card/card';

const AddDeviceForm = (props) => {
    let configration = useSelector(state => state.configuration.config)

    let hubId = props.data ? props.data.hubId : props?.hubId
    let levelId = props.data ? props.data.levelId : props?.levelId
    //when device create in hub 
    let orgId = props?.levelId?.split("_")[0]
    //when public create 
    // let orgId1 = props?.org
    // let configration = props.configration.configration
    //All States
    // This States Loaders and redirection
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    })
    //<!---All State --> 
    const [popup, setPopup] = useState(false)
    const [list, setList] = useState([])
    const [device, setDevice] = useState(
        props.data ? props.data :
            {
                type: "",
                name: "",
                parameter: [{
                    name: "",
                    types: "", value: "", unit: "", calculated: false
                }],
                metadata: {}, // Ensure metadata is included
                hubId: hubId ? hubId : "",
                levelId: levelId ? levelId : "",
                orgId: levelId ? levelId.split("_")[0] : "",
                _id: ""
            }
    )
    const [metadataError, setMetadataError] = useState(null);
const[metadataname,setMetadataName]=useState({})
    const [rootLevel, setRootLevel] = useState([])
    const [unit, setUnit] = useState(true)
    const [loader, setLoader] = useState(true)
    const [cardLoader, setCardLoader] = useState(true)
    const [name, setName] = useState(true)
    const [type, setType] = useState(true)
    const [selectLevelId, setSelectLevelId] = useState(true)
    const [selectHubId, setSelectHubId] = useState(true)
    const [paramType, setParamType] = useState(true)
    const [paramName, setParamName] = useState(true)
    const [paramValue, setParamValue] = useState(true)
    const [paramUnit, setParamUnit] = useState(true)
    const [paramTypeList, setParamTypeList] = useState([])
    const [hubIdList, setHubIdList] = useState([])
    const [suggestName, setSuggestName] = useState([])
    const [devices, setDevices] = useState([])
    const [levelIdsList, setLevelIdsList] = useState([])
    //<!----Active Device and parameter-->
    const [activeDevice, setActiveDevice] = useState(0)
    const [activeParam, setActiveParam] = useState(0)
    const [calculatedInput, setCalculatedInput] = useState(true)
    const [testPopup, setTestPopup] = useState(null)
    const [testMsgPopup, setTestMsgPopup] = useState(null)
    const [testEvent, setTestEvent] = useState(null)
    //<!----Active Device and parameter-->

    const [deviceTypes, setDeviceTypes] = useState([])
    const [deviceUnit, setDeviceUnit] = useState(false)
    const [units, setUnits] = useState([])
    const [lengthDevice, setLengthDevice] = useState(1)
    const [lengthParam, setlengthParam] = useState(1)

    const rootLevelGet = async () => {
        let root = await GetRoots()
        let organization = []
        root.length && root.map(org => {
            organization.push({ label: org, value: org })
        })

        setRootLevel(organization)
    }
    useEffect(() => {
        console.log(device)
    }, [])
    useEffect(() => {
        let List = []
        //<!---All State -->
        //<!---Device map and put the value in types nd Unit ->
        configration?.device.map((device) => {
            let Unit = [],
                Type = []
            device.parameter.map((param) => {
                Type.push(param.type)
                Unit.push(param.unit)
            })
            List.push({ deviceType: device.type, name: device.name, unit: Unit, types: Type })
        })
        setList(List)
        setCardLoader(false)

        rootLevelGet()
        let DeviceType = []
        List.map((deviceType, key) => {
            DeviceType.push({ value: deviceType.deviceType, label: deviceType.deviceType })
        })
        setDeviceTypes(DeviceType)

        setLoader(false)
        //<!---Device map and put the value in types nd Unit ->
        //<!-----Branch add and parameter add->

        let devices1 = []
        if (props?.data) {
            // devices1 = props.data
            setDevice({
                ...props.data,
                metadata: props.data.metadata || {}
            }); let devices1 = devices
            devices1[activeDevice] = props.data
            setDevices(devices1)
        } else {
            setDevice({
                type: "",
                name: "Device 1",
                parameter: [{
                    paramName: "Param1", types: "", value: "", unit: "", calculated: false
                }],
                hubId: hubId ? hubId : "",
                levelId: levelId ? levelId : "",
                metadata: {}, // Initialize metadata
                _id: ""
            })
            for (var i = 1; i <= lengthDevice; i++) {
                devices1.push({
                    type: "",
                    name: "Device" + i,
                    parameter: [{
                        paramName: "Param1", types: "", value: "", unit: "", calculated: false
                    }],
                    hubId: hubId ? hubId : "",
                    levelId: levelId ? levelId : "",
                    _id: ""
                })
            }
            for (var j = 1; j <= lengthParam; j++) {
                if ("parameter" in devices1) {
                    devices1.map(param => param.parameter.push({ paramName: "", types: "", value: "", unit: "", calculated: false }))
                }
            }
            setDevices(devices1);
        }
    }, [])
    const branch = async () => {
        const branch = await fetchData(props.data.levelId.split("_")[0])
        setLevelIdsList(branch)
    }
    const hubGetByLevelId = async () => {
        let hubIds = []
        let hubs = await getHubsByLevelId(props.data.levelId)
        hubs.map((hub, key) => {
            if (device.levelId === hub.levelId) {
                hubIds.push({ value: hub._id, label: hub._id })
            }
        })
        setHubIdList(hubIds)
    }
    useEffect(() => {
        setLoader(true)
        if (props.data) {
            let DeviceType = []
            list.map((deviceType, key) => {
                DeviceType.push({ value: deviceType.deviceType, label: deviceType.deviceType })
            })
            setDeviceTypes(DeviceType)
            setActiveParam(0)
            setDevice({
                ...props.data, orgId: props.data.levelId.split("_")[0], metadata: props.data.metadata || {}, // Ensure metadata is included
            })
            let ParamType = [],
                Name = []
            list.map((deviceType, key) => {
                if (device.type === deviceType.deviceType) {
                    deviceType.types.map((type, key) => {
                        ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                    })
                    deviceType.name.map((name, key) => {
                        Name.push({ value: name, label: name })
                    })

                }
            })

            branch()
            hubGetByLevelId()
            ChangeParam(device.parameter[0], 0, ParamType)
            setParamTypeList(ParamType)
            setSuggestName(Name)
            setLoader(false)
        }
        setLoader(false)
    }, [props.data, list])

    //<!-----Branch add and parameter add->
    //<!----Chnage state mean Change device data->
    const ChangeState = (devicedata, key = null) => {
        Validation()
        setActiveDevice(key);

        setActiveParam(0)
        setDevice(devicedata)
        let ParamType = []
        let hubIds = []
        let Name = []
        list.map((deviceType, key) => {
            if (devicedata.type === deviceType.deviceType) {
                deviceType.types.map((type, key) => {
                    ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                })
                deviceType.name.map((name, key) => {
                    Name.push({ value: name, label: name })
                })

            }
        })
        ChangeParam(devicedata.parameter[0], 0, ParamType)
        setParamTypeList(ParamType)
        setHubIdList(hubIds)
        setSuggestName(Name)
    }
    //<!----Chnage state mean The data of which Device you In->

    //<!----Chnage params mean The data of which parameter you In->
    const ChangeParam = (paramData, key, liveParam = null) => {
        if (paramData.types) {
            let Unit = []
            if (liveParam) {
                liveParam.map(typelist => {
                    if (typelist.value === paramData.types) {
                        typelist.unit.map((unit) => {
                            Unit.push({ value: unit, label: unit })
                        })
                    }
                })
            }
            else {
                paramTypeList.map(typelist => {
                    if (typelist.value === paramData.types) {
                        typelist.unit.map((unit) => {
                            Unit.push({ value: unit, label: unit })
                        })
                    }
                })
            }
            setUnits(Unit)
        }
        setTimeout(() => {
            setActiveParam(key);
        }, 100);
    }
    //<!----Chnage params mean Change parameter data->
    const testParameter = (event) => {
        setTestMsgPopup(null)
        setTestEvent(event.target.textContent)
    }
    //<!----input validations->
    const Validation = () => {
        setUnit(true)
        setName(true)
        setType(true)
        setParamType(true)
        setParamValue(true)
        setParamUnit(true)


    }
    //<!----input validations->

    //<!------Change Handler->
    const onChangeHandler = async (e, index) => {
        let data = device
        if (e.name === "type") {
            let ParamType = [],
                Name = []
            list.map((deviceType, key) => {
                if (e.target.value === deviceType.deviceType) {
                    deviceType.types.map((type, key) => {
                        ParamType.push({ value: type, label: type, unit: deviceType.unit[key] })
                    })
                    deviceType.name.map((name, key) => {
                        Name.push({ value: name, label: name })
                    })
                }
            })
            data["name"] = null
            setParamTypeList(ParamType)
            setSuggestName(Name)
            data["parameter"] = [{ types: "", paramName: "Param1", value: "", unit: "", calculated: false }]
        }
        if (e.name === "orgId") {
            data[levelId] = ""
            data[hubId] = ""
            let levelIds1 = []
            const branch = await fetchData(e.target.value)
            if (branch.length) {
                setLevelIdsList(branch)
                setHubIdList([])
            } else {
                setLevelIdsList([])
            }
        }
        if (e.name === "levelId") {
            data["hubId"] = ""
            let hubIds = []
            let hubs = await getHubsByLevelId(e.target.value)
            hubs.map((hub, key) => {
                if (e.target.value === hub.levelId) {
                    hubIds.push({ value: hub._id, label: hub._id })
                }
            })
            setHubIdList(hubIds)
        }
        if (e.name === "types") {
            let Unit = []
            let index = null;
            paramTypeList.map((type, key) => { return (e.target.value === type.value ? index = key : null) })
            paramTypeList[index].unit.map((unit) => {
                Unit.push({ value: unit, label: unit })
            })
            setUnits(Unit)
        }
        let name = e.name
        if (name === "types" || name === "paramName" || name === "value" || name === "unit") {
            data["parameter"][activeParam][name] = e.target.value
        }
        if (e.target.name === "calculated") {
            data["parameter"][activeParam]["calculated"] = !data["parameter"][activeParam]["calculated"]
        } if (name === "func") {
            if (data["parameter"][activeParam]["value"]) {
                data["parameter"][activeParam]["func"] = "this." + data["parameter"][activeParam]["value"] + "=" + e.target.value
            } else {
                setParamValue(false)

            }
        }
        else {
            data[name] = e.target.value
        }
        if (e.name === "levelId") {
            setSelectLevelId(true)
        }
        if (e.name === "hubId") {
            setSelectHubId(true)
        }
        if (e.name === "parameter") {
            setUnit(true)
        }
        if (e.name === "name") {
            setName(true)
        }
        if (e.name === "type") {
            setType(true)
        }
        if (e.name === "types") {
            setParamType(true)
        }
        if (e.name === "value") {
            setParamValue(true)
        }
        if (e.name === "paramName") {
            setParamName(true)
        }
        if (e.name === "unit") {
            setParamUnit(true)
        }
        if (e.name === "func") {
            setCalculatedInput(true)
        }
        if(e.name==="metadata"){
            setMetadataName(true)
        }
        setDevice(data)
        setDevices(prevDevices => {
            const updatedDevices = [...prevDevices]; // Create a copy of the previous state array
            updatedDevices[activeDevice] = data; // Update the specific item
            return updatedDevices; // Return the updated state
        });
        // let devices1 = devices
        // devices1[activeDevice] = data
        // setDevices(devices1)
    }
    //<!------Change Handler->

    //<!-----all input validation Functions->

    const checkNOD = () => {
        const special = SpecialCharacters
        if (!device.name || special.test(device.name) || device.name.length > 20) {
            setName(false)
            return false
        }
        else {
            setName(true)
            return (true)
        }

    }
    const checklevelId = () => {
        if (!device.levelId) {
            setSelectLevelId(false)
            return false
        }
        else {

            setSelectLevelId(true)
            return (true)
        }

    }
    const checkHubId = () => {
        if (!device.hubId) {
            setSelectHubId(false)
            return false
        }
        else {

            setSelectHubId(true)
            return (true)
        }

    }
    const checkParameterType = () => {
        if (!device.parameter[activeParam].types) {
            setParamType(false)
            return false
        }
        else {

            setParamType(true)
            return (true)
        }
    }
    const checkParameterValue = () => {
        if (device.parameter[activeParam].value !== undefined) {
            const special = SpecialCharacters
            let param = device.parameter.length > 1 ? device.parameter.some(param => param.value !== device.parameter[activeParam].value) : true
            if (!device.parameter[activeParam].value || special.test(device.parameter[activeParam].value) || device.parameter[activeParam].value.length > 20 || !param) {
                setParamValue(false)
                return false
            }
            else {

                setParamValue(true)
                return (true)
            }
        } else {
            setParamValue(true)
            return (true)
        }
    }
    const checkParamName = () => {
        const special = SpecialCharacters2
        if (!device.parameter[activeParam].paramName || special.test(device.parameter[activeParam].paramName) || device.parameter[activeParam].paramName.length > 20) {
            setParamName(false)
            return false
        }
        else {
            setParamName(true)
            return (true)
        }
    }
    const checkParamUnit = () => {
        if (!device.parameter[activeParam].unit && units.length) {
            setParamUnit(false)
            return false
        }
        else {
            setParamUnit(true)
            return (true)
        }
    }
    const checkType = () => {
        if (!device.type) {
            setType(false)
            return false
        }
        else {
            setType(true)
            return (true)
        }
    }

    //<!-----all input validation Functions->
    //<!------Add Parameter Tab->
    const addParameter = (index) => {
        let newlength = (devices[activeDevice].parameter.length + 1);
        let devices1 = [...devices]
        devices1[index].parameter.push({
            paramName: "Param" + newlength, types: "", value: "", unit: "", calculated: false
        })
        setlengthParam(newlength)
        setDevices(devices1);
    }
    //<!------Add Parameter Tab->
    //<!------Add Device Tab->
    const addDevice = () => {
        let newlength = (lengthDevice + 1);
        let devices1 = [...devices]
        devices1.push({
            type: "",
            name: "Device" + newlength,
            parameter: [{ paramName: "", types: "", value: "", unit: "", calculated: false }],
            hubId: hubId,
            levelId: levelId,
            _id: ""
        })
        setDevices(devices1);
        setLengthDevice(newlength)
    }
    //<!------Add Device Tab->
    //<!------delete Device Tab->
    const deleteDevice = (key) => {
        let remove = [...devices]
        remove.splice(key, 1)
        setDevices(remove)
        if (activeDevice > 0) {
            ChangeState(devices[activeDevice - 1], activeDevice - 1)
        }
        else {
            ChangeState(devices[activeDevice + 1], activeDevice)
        }
    }
    //<!-----delete Device Tab->
    //<!------delete parameters Tab->
    const deleteParameter = (key) => {
        let removeParam = [...devices]
        removeParam[activeDevice].parameter.splice(key, 1)
        setDevices(removeParam)
        if (activeParam > 0) {
            ChangeParam(devices[activeDevice].parameter[activeParam - 1], activeParam - 1)
        }
        else {
            ChangeParam(devices[activeDevice].parameter[activeParam], activeParam)
        }
    }
    //<!------delete parameters Tab->

    //<!------check all conditions and create devices in bulk->
    const CreateDevice = () => {
        let filledInput = true
        let deviceKey = ""
        let paramKey = ""

        for (var i = 0; i <= (devices.length - 1); i++) {
            if (!devices[i].type || !devices[i].name || !devices[i].parameter) {
                filledInput = false
                deviceKey = i;
                break;
            }
            //check Every Device of data is full feild
            for (var j = 0; j <= (devices[i].parameter.length - 1); j++) {
                if (!devices[i].parameter[j].types || !devices[i].parameter[j].paramName || !devices[i].parameter[j].value || (units.langth ? !devices[i].parameter[j].unit : null) || (device.parameter[activeParam].calculated && !device.parameter[activeParam].func)) {
                    filledInput = false
                    deviceKey = i;
                    paramKey = j
                    break;
                }
            }

        }
        //Check  Type if it is true then go to next Conditions when All conditions true Hit API "Create Device"
        if (checkType()) {
            if (checklevelId()) {
                if (checkHubId()) {
                    if (checkNOD()) {
                        if (checkParameterValue()) {
                            if (checkParamName()) {
                                if (checkParameterType()) {
                                    if (checkParamUnit()) {
                                        let newkey = activeDevice + 1
                                        if (devices.length > activeDevice + 1) {
                                            ChangeState(devices[newkey], newkey)
                                        } else {
                                            if (filledInput) {
                                                let state1 = { ...state }
                                                state1.loginLoader = true;
                                                setState(state1)
                                                if (props?.data?._id) {
                                                    return deviceUpdate(devices[0], devices[0]._id).then(async res => {
                                                        let result = res
                                                        if (result) {
                                                            if (!result.statusCode) {
                                                                setPopup(["Success!", "Devices updated successfully.", "success"])
                                                                state1.loginLoader = false;
                                                                setState(state1)
                                                                // if (orgId1) {
                                                                //     orgAction(orgId1)
                                                                // }
                                                            }
                                                            else {
                                                                setPopup(["Failure!", "Something went wrong.", "danger"])
                                                                state1.loginLoader = false;
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    devices.forEach((index) => {
                                                        let name = index.name.replace(" ", "")
                                                        index._id = device.hubId + "_" + name
                                                    })
                                                    return deviceCreatedBulk(devices).then(async res => {
                                                        let result = res
                                                        if (result) {
                                                            if (!result.statusCode) {
                                                                setPopup(["Success!", "Devices created successfully.", "success"])
                                                                state1.loginLoader = false;
                                                                setState(state1)
                                                                // if (orgId) {
                                                                //     orgAction(orgId)
                                                                // } else {
                                                                //     orgAction(orgId1)
                                                                // }
                                                            }
                                                            else {
                                                                setPopup(["Failure!", result.message, "danger"])
                                                                state1.loginLoader = false;
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                //First device form Compelete if second device exist send to second device  
                                                ChangeState(devices[deviceKey], deviceKey)
                                                ChangeParam(devices[deviceKey].parameter[paramKey], paramKey)
                                            }
                                        }
                                    } else {
                                        setParamUnit(true)
                                    }
                                }
                            } else {
                                setParamType(true)
                                setParamUnit(true)
                            }
                        } else {
                            setParamName(true)
                            setParamType(true)
                            setParamUnit(true)
                        }
                    } else {
                        setParamValue(true)
                        setParamName(true)
                        setParamType(true)
                        setParamUnit(true)
                    }
                } else {
                    setName(true)
                    setParamValue(true)
                    setParamName(true)
                    setParamType(true)
                    setParamUnit(true)
                }
            } else {
                setSelectHubId(true)
                setName(true)
                setParamValue(true)
                setParamName(true)
                setParamType(true)
                setParamUnit(true)
            }
        }
        else {
            setSelectLevelId(true)
            setSelectHubId(true)
            setName(true)
            setParamValue(true)
            setParamName(true)
            setParamType(true)
            setParamUnit(true)

        }
    }
    //<!------check all conditions and create devices in bulk->
    const testCalculated = (body, value) => {
        if (value === '') {
            setCalculatedInput(false)
        } else {
            let data1 = body
            setTestPopup(data1)
        }
    }
    const checkTest = () => {
        const jsonString = testEvent;
        // Remove backslashes and leading/trailing spaces
        try {
            const cleanedString = jsonString.replace(/\\/g, '').trim();
            // Parse the cleaned string as JSON
            const jsonObject = JSON.parse(cleanedString);
            let event1 = jsonObject;
            // testPopup.parameter.forEach(param => {
            if (testPopup.parameter[activeParam].func) {
                new Function(testPopup.parameter[activeParam].func).call(event1);
            }

            // Accessing keys and values
            for (const key in event1) {
                if (Object.hasOwnProperty.call(event1, key)) {
                    const value = event1[key];
                }
            }
            delete event1.deviceId
            const keyValueArray = Object.entries(event1).map(([key, value]) => {
                return { key, value };
            });            // Accessing keys and values
            setTestPopup(null)
            //cosnole.log(keyValueArray)
            setTestMsgPopup(["Message", "Sucessfull", keyValueArray])
        } catch (err) {
            //cosnole.log(err.message);
            // setTestPopup(null)
            //cosnole.log()
            setTestMsgPopup(["Message", "Danger", err.message])
        }
    }
    const handleChangeMetadata = (e) => {
        const { value } = e.target;
        try {
            const parsedValue = JSON.parse(value); // Attempt to parse the value as JSON
            setMetadataError(null); // Clear any previous error message
            setDevice(prevDevice => ({
                ...prevDevice,
                metadata: parsedValue
            }));
        } catch (error) {
            setMetadataError("Invalid JSON format"); // Set error message if parsing fails
        }
    };  
    return (
        <>
            {cardLoader ? <CardLoader /> :
                loader ? <CardLoader /> :
                    <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }}>
                        <div className='row m-0 pb-4'>
                            <div className='col d-flex' style={{ width: "100%" }}>
                                {devices.map((devicedata, key) =>
                                    <div key1={key} className={'tabButton d-flex ' + (key === activeDevice ? "active" : "null")} style={{ minWidth: (key === activeDevice) ? "130px" : "100px" }} >
                                        <div className='inner-btn text-truncate' onClick={() => ChangeState(devicedata, key)} key={key}>{devices[key].name ? devices[key].name : "Name"}</div>

                                        {devices.length > 1 ?
                                            <div className='close-btn btn btn-light text-danger rounded-circle-px m-1' onClick={() => deleteDevice(key)}><i className='fas fa-trash fa-sm'></i></div>
                                            : null}
                                    </div>
                                )}
                            </div>
                            {!props?.data?._id ?
                                <div className='justify-content-end px-3'>
                                    <button className="mt-1 btn btn-dark border-0 rounded-circle-px dropshadow primary-gradient-hv " onClick={() => addDevice()}><i className="fa fa-sm fa-plus"></i></button>
                                </div> : null}
                        </div>

                        <div className='d-flex flex-column justify-content-between px-4 pb-1'>
                            <div>
                                <div className='row '>
                                    <div className='col-12'>
                                        <Select onEnter={() => CreateDevice()} dropDownHeight="150px" name="type" label="Type*" defaultValue={{ value: device.type }} onClick message={{ msg: "Please select your device type", status: !type }} options={deviceTypes} onChange={(e) => onChangeHandler(e)} />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        {props?.data?._id ?
                                            <Input dropDownHeight="100px" name="orgId" label="Organization ID*" disabled defaultValue={device.orgId} />
                                            :
                                            <Select onEnter={() => CreateDevice()} dropDownHeight="100px" name="orgId" label="Organization ID*" defaultValue={{ value: device.orgId }} message={{ msg: "Please Select your oragnization", status: !selectLevelId }} onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                                        } </div>
                                </div>
                                {/* {!levelId || props.type === "direct" ? */}
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        {props?.data?._id ?
                                            <Input dropDownHeight="100px" name="levelId" label="Level Id*" disabled defaultValue={device.levelId} />
                                            :
                                            <Select onEnter={() => CreateDevice()} dropDownHeight="100px" name="levelId" label="Level Id*" defaultValue={{ value: device.levelId }} message={{ msg: "Please Select your oragnization", status: !selectLevelId }} onClick options={levelIdsList} onChange={(e) => onChangeHandler(e)} />
                                        } </div>
                                    <div className='col-6'>
                                        {props?.data?._id ?
                                            <Input dropDownHeight="100px" name="hubId" label="Hub Id*" disabled defaultValue={device.hubId} />
                                            :
                                            <Select onEnter={() => CreateDevice()} dropDownHeight="100px" name="hubId" label="Hub Id*" defaultValue={{ value: device.hubId }} message={{ msg: "Please Select your oragnization", status: !selectHubId }} onClick options={hubIdList} onChange={(e) => onChangeHandler(e)} />
                                        } </div>
                                </div>
                                {/* : null} */}
                                {suggestName.length ? <div className='row mt-3'>
                                    <div className='col-12'>
                                        <Select onEnter={() => CreateDevice()} dropDownHeight="150px" textEditor name="name" label="Name*" defaultValue={{ value: device.name }} onClick message={{ msg: "Please enter name of this device", status: !name }} options={suggestName} onChange={(e) => onChangeHandler(e)} />
                                    </div>
                                </div> : null}
                                {suggestName.length ? <div className=' mt-3'>
                                <div col-12>
            {/* Your form fields and other components */}
        <Input
            // className='form-control'
            type="textarea"
            defaultValue={JSON.stringify(device?.metadata)} // Convert metadata object to JSON string
            onChange={ handleChangeMetadata}
            label='Enter JSON'
            message={{
                msg: metadataError, status: metadataError
            }}
        />
    </div>
            {/* Include console logs to help debug */}
            {console.log("Current device state:", device)}
                                </div> : null}
                                {
                                    paramTypeList.length && device ?
                                        <div className='split-t mt-0'>
                                            <div className='row overflow-hidden pb-2' >
                                                <div className='col d-flex' style={{ width: "100%", overflowX: "auto", overflowY: "none" }}>
                                                    {devices[activeDevice].parameter.map((paramData, key) =>
                                                        <div className={'tabButton d-flex ' + (key === activeParam ? "active" : "null")} style={{ minWidth: (key === activeParam) ? "130px" : "unset" }}>
                                                            <div className='inner-btn text-center text-truncate' onClick={() => ChangeParam(paramData, key)} key={key}>{paramData.paramName ? paramData.paramName : "param1"}</div>

                                                            {devices[activeDevice].parameter.length > 1 ?
                                                                <div className='close-btn btn btn-light text-danger rounded-circle-px ' onClick={() => deleteParameter(key)}><i className='fas fa-trash fa-sm'></i></div>
                                                                : null}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='justify-content-end px-3'>
                                                    <button className="mt-1 btn  btn-dark border-0 rounded-circle-px dropshadow primary-gradient-hv" onClick={() => addParameter(activeDevice)}><i className="fa fa-sm fa-plus"></i></button>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                {/* {//cosnole.log(device.parameter[activeParam].calculated)} */}
                                                <label className='d-flex ml-2' style={{ gap: 10 }}>

                                                    <input type="checkbox" disabled={activeParam > 0 ? false : true} checked={device?.parameter[activeParam]?.calculated} name='calculated' onChange={(e) => onChangeHandler(e)} />
                                                    <Heading size={3}> Calculated </Heading>
                                                </label>
                                            </div>
                                            <div className='row'>

                                                {activeParam < device.parameter.length ?
                                                    <div className='col-md-3'>
                                                        <Input onEnter={() => CreateDevice()} name="value" label="Key" defaultValue={device.parameter[activeParam].value} message={{ msg: "Please enter your param value", status: !paramValue }} onChange={(e) => onChangeHandler(e)} />
                                                    </div>
                                                    : null
                                                }
                                                <div className='col-md-6 col-6'>
                                                    {
                                                        activeParam < device.parameter.length ?
                                                            <Input onEnter={() => CreateDevice()} name="paramName" label="Name" defaultValue={device.parameter[activeParam]?.paramName ? device.parameter[activeParam]?.paramName : ""} message={{ msg: "Please enter a parameter name", status: !paramName }} onChange={(e) => onChangeHandler(e)} />
                                                            : null
                                                    }
                                                </div>
                                                <div className='col-md-6 col-12 mt-3'>
                                                    {activeParam < device.parameter.length ?
                                                        <Select onEnter={() => CreateDevice()} name="types" dropDownHeight="100px" label="Type" options={paramTypeList} defaultValue={{ value: device.parameter[activeParam].types }} onClick message={{ msg: "Please select a parameter type", status: !paramType }} onChange={(e) => onChangeHandler(e)} />
                                                        : null}
                                                </div>
                                                <div className='col-md-6 col-12 mt-3'>
                                                    {activeParam < device.parameter.length ?
                                                        units.length ?
                                                            <Select onEnter={() => CreateDevice()} name="unit" dropDownHeight="100px" label="Unit" defaultValue={{ value: device.parameter[activeParam].unit }} onClick options={units} message={{ msg: "Please select a parameter unit", status: !paramUnit }} onChange={(e) => onChangeHandler(e)} />
                                                            : null// : <Select disabled name="unit" dropDownHeight="100px" label="Param Unit" defaultValue={{ value: device.parameter[activeParam].unit }} onClick options={units} message={{ msg: "Please enter your param unit", status: !paramUnit }} onChange={(e) => onChangeHandler(e)} />
                                                        : null}

                                                </div>

                                            </div>
                                            {activeParam < device.parameter.length && device.parameter[activeParam].calculated ?
                                                <div className='row mt-1'>
                                                    <div className='col-md-9 mt-md-0'>
                                                        <Input disabled={device.parameter[activeParam].value ? false : true} defaultValue={device.parameter[activeParam]?.func ? (device.parameter[activeParam].func).split("=")[1] : ''} onEnter={() => CreateDevice()} name="func" label="Func" message={{ msg: "Please create function", status: !calculatedInput }} onChange={(e) => onChangeHandler(e)} />
                                                    </div>
                                                    <div className='col-md-3 mt-md-0'>
                                                        <ButtonContainer>
                                                            <Button onClick={() => testCalculated(device)} className="bg-success">Test</Button>
                                                        </ButtonContainer>
                                                    </div>
                                                </div>
                                                : null}
                                        </div> : null
                                }
                            </div>
                            {state.loginLoader ?
                                <div className='d-flex justify-content-center' style={{ height: "80px" }}>
                                    <InlineLoader />
                                </div> :
                                activeDevice + 1 === devices.length ?
                                    <ButtonContainer className="mt-2">

                                        <Button onClick={() => CreateDevice()} >{!props?.data?._id ? "Create" : "Update"}</Button>
                                    </ButtonContainer> :

                                    <ButtonContainer className="mt-2">
                                        <Button onClick={() => CreateDevice()} >Next</Button>
                                    </ButtonContainer>
                            }

                        </div>

                        {
                            popup ? <Mypopup title={popup[0]} type={popup[2]} onClose={(e) => {
                                setPopup(null);

                            }}>
                                <p className="p-3" > {popup[1]}</p>
                                <div className="d-flex justify-content-center p-3">
                                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                        setPopup(null);
                                        if (levelId && !props.type === "direct") {
                                            props.reloadDevices(popup[2])
                                        } else {
                                            props.reloadDevices(popup[2])
                                        }
                                    }}>Ok</button>
                                </div>
                            </Mypopup >
                                : null}

                        {testPopup ?
                            <Mypopup className='w-100' title={"Parameter Testing"} type={"information"} onClose={(e) => { setTestPopup(null); setTestMsgPopup(null) }} >
                                <code className='p-3' contentEditable={true} style={{ border: 'none', outline: 'none' }} onInput={(e) => { testParameter(e) }}>
                                    <div className='m-2'>
                                        {"{"}<br />
                                        <div className='ml-3'>
                                            "deviceId": "{testPopup.hubId + "_" + testPopup.name}", <br />
                                            {testPopup.parameter
                                                // Filter out parameters where calculated is true
                                                .filter(param => !param.calculated)
                                                .map((param, index, array) => (
                                                    // Render the parameter value and a default message
                                                    `"${param.value}": "Enter Value"${index !== array.length - 1 ? ',' : ''}`
                                                ))
                                            }

                                        </div>
                                        {"}"}


                                    </div>
                                </code>
                                <div>
                                    {testMsgPopup !== null && typeof testMsgPopup[2] === "string" ?
                                        <p style={{ color: "red" }}>{testMsgPopup[2]}</p>
                                        : null}

                                </div>
                                <div className="d-flex justify-content-center p-3">
                                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                        checkTest()
                                    }}>Result</button>
                                </div>
                            </Mypopup> : null}
                        {testMsgPopup !== null && typeof testMsgPopup[2] !== "string" ?
                            <Mypopup className='w-100' title={testMsgPopup[0]} type={testMsgPopup[1]} onClose={(e) => setTestPopup(null)} >
                                <code className='p-3'>
                                    <div className='m-2'>
                                        {"{"}<br />
                                        <div className='ml-3'>
                                            "deviceId": "{device.hubId + "_" + device.name}", <br />
                                            {testMsgPopup[2].map((param, key) =>
                                                // props.events !== null ?
                                                <>
                                                    {`"${param.key}": "${param.value}" ${key + 1 !== device.parameter.length ? ',' : ''}`}
                                                </>
                                                // :
                                                // <>
                                                //     {`"${param.value}": N/A` + (key + 1 !== testPopup.parameter.length ? `,` : ``)}
                                                // </>
                                            )
                                            }
                                        </div>
                                        {"}"}
                                    </div>
                                </code>
                                <div className="d-flex justify-content-center p-3">
                                    <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                                        setTestMsgPopup(null)
                                    }}>Ok</button>
                                </div>
                            </Mypopup> : null}
                    </div>
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    organization: state.organization,
    userInfo: state.userInfo
});
export default connect(mapStateToProps, null)(AddDeviceForm)

