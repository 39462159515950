
// import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
// import { configration } from '../reducers/configration';
// import { organization } from '../reducers/Org';
// import { lastEvent } from '../reducers/lastEvetReducer';

import { combineReducers, configureStore } from "@reduxjs/toolkit"
import eventReducer from "../reducers/eventReducer"
import logReducer from "../reducers/logreducer"
import notificationReducer from "../reducers/notification reducer"
import userInfo from "../reducers/userInfo"
import UserList from "../reducers/UserList"
import configration from "../reducers/configration"
import lastEventReducer from "../reducers/lastEventReducer"
import stateReducer from "../reducers/staetReducer"
// import { organizations } from "../reducers/org"

export const store = configureStore({
    reducer: combineReducers({
        notifyEvents: notificationReducer,
        varification: userInfo,
        fetchUserList: UserList,
        configuration: configration,
        getLastEvent: lastEventReducer,
        getLastLogs: logReducer,
        getState: stateReducer,
        eventReducer: eventReducer
        // organization: organizations
    })
    // {
    // },
})

// import eventReducer from '../reducers/eventReducer';
// import notifyReducer from '../reducers/notifyReducer'
// import { themeMode, userInfo, userToken } from '../reducers/userInfo';
// import { sidebarReducer } from '../reducers/sidebarReducer';
// import { userList } from '../reducers/UserList';
// import logReducer from '../reducers/logReducer';


// let composeEnhancers = compose
// export default createStore(
//     combineReducers({
//         eventReducer: eventReducer,
//         logReducer: logReducer,
//         userInfo: userInfo,
//         userToken: userToken,
//         themeMode: themeMode,
//         configration: configration,
//         lastEvent: lastEvent,
//        
//         sidebarReducer: sidebarReducer,
//         notifyReducer: notifyReducer,
//         userList: userList
//     }),
//     composeEnhancers(applyMiddleware(thunk))
// )
export default store