export const BusinessCategory = [
    { label: "Agriculture, plantations,other rural sectors", value: "Agriculture, plantations,other rural sectors" },
    { label: "Basic Metal Production", value: "Basic Metal Production" },
    { label: "Chemical industries", value: "Chemical industries" },
    { label: "Commerce", value: "Commerce" },
    { label: "Construction", value: "Construction" },
    { label: "Education", value: "Education" },
    { label: "Financial services, professional services", value: "Financial services, professional services" },
    { label: "Food, drink, tobacco", value: "Food, drink, tobacco" },
    { label: "Forestry, wood, pulp and paper", value: "Forestry, wood, pulp and paper" },
    { label: "Health services", value: "Health services" },
    { label: "Hotels, tourism, catering", value: "Hotels, tourism, catering" },
    { label: "IoT", value: "IoT" },
    { label: "Mining(coal, other mining)", value: "Mining(coal, other mining)" },
    { label: "Mechanical and electrical engineering", value: "Mechanical and electrical engineering" },
    { label: "Media, culture, graphical", value: "Media, culture, graphical" },
    { label: "Oil and gas production, oil refining", value: "Oil and gas production, oil refining" },
    { label: "Postal and telecommunications services", value: "Postal and telecommunications services" },
    { label: "Public service", value: "Public service" },
    { label: "Shipping, ports, fisheries, inland waterways", value: "Shipping, ports, fisheries, inland waterways" },
    { label: 'Textiles, clothing, leather, footwear', value: 'Textiles, clothing, leather, footwear' },
    { label: "Transport(including civil aviation, railways, road transport)", value: "Transport(including civil aviation, railways, road transport)" },
    { label: "Transport equipment manufacturing", value: "Transport equipment manufacturing" },
    { label: "Utilities(water, gas, electricity)", value: "Utilities(water, gas, electricity)" },
]
export const EmailRevoke = [
    // "aol",
    // "att",
    // "comcast",
    // "facebook",
    // // "gmail",
    // "gmx",
    // "googlemail",
    // "google",
    // "hotmail",
    // // "hotmail",
    // "mac",
    // "me",
    // "mail",
    // "msn",
    // // "live",
    // "sbcglobal",
    // "verizon",
    // // "yahoo",
    // "yahoo",
    // // "email",
    // "fastmail",
    // "games",
    // "gmx",
    // "hush",
    // "hushmail",
    // "icloud",
    // "iname",
    // "inbox",
    // "lavabit",
    // "love",
    // // "outlook",
    // "pobox",
    // "protonmail",
    // "protonmail",
    // "tutanota",
    // "tutanota",
    // "tutamail",
    // "tuta",
    // "keemail",
    // "rocketmail",
    // "safe-mail",
    // "wow",
    // "ygm",
    // "ymail",
    // "zoho",
    // "yandex",
    // "bellsouth",
    // "charter",
    // "cox",
    // "earthlink",
    // "juno",
    // "btinternet",
    // "virginmedia",
    // "blueyonder",
    // "freeserve",
    // "live",
    // "ntlworld",
    // "o2",
    // "orange",
    // "sky",
    // "talktalk",
    // "tiscali",
    // "virgin",
    // "wanadoo",
    // "bt",
    // "sina",
    // "sina",
    // "qq",
    // "naver",
    // "hanmail",
    // "daum",
    // "nate",
    // "yahoo",
    // "yahoo",
    // "yahoo",
    // "yahoo",
    // "yahoo",
    // "yahoo",
    // "163",
    // "yeah",
    // "126",
    // "21cn",
    // "aliyun",
    // "foxmail",
    // "hotmail",
    // "live",
    // "laposte",
    // "yahoo",
    // "wanadoo",
    // "orange",
    // "gmx",
    // "sfr",
    // "neuf",
    // "free",
    // "gmx",
    // "hotmail",
    // "live",
    // "online",
    // "t-online",
    // "web",
    // "yahoo",
    // "libero",
    // "virgilio",
    // "hotmail",
    // "aol",
    // "tiscali",
    // "alice",
    // "live",
    // "yahoo",
    // "email",
    // "tin",
    // "poste",
    // "teletu",
    // "mail",
    // "rambler",
    // "yandex",
    // "ya",
    // "list",
    // "hotmail",
    // "live",
    // "skynet",
    // "voo",
    // "tvcablenet",
    // "telenet",
    // "hotmail",
    // "live",
    // "yahoo",
    // "fibertel",
    // "speedy",
    // "arnet",
    // "yahoo",
    // "live",
    // "hotmail",
    // "hotmail",
    // "prodigy",
    // "yahoo",
    // "hotmail",
    // "bell",
    // "shaw",
    // "sympatico",
    // "rogers",
    // "yahoo",
    // "hotmail",
    // "outlook",
    // "uol",
    // "bol",
    // "terra",
    // "ig",
    // "itelefonica",
    // "r7",
    // "zipmail",
    // "globo",
    // "globomail",
    // "oi"
]
export const ConditionList = [
    '==', '!=', '>', '<', '>=', '<=', "f(x)"
]
export const RoleList =
    [{ label: "Dashboard Design", value: "1001" }
        , { label: "User Management", value: "1002" },
    { label: "Rule Engine", value: "1003" },
    { label: "Analytics", value: "1004" },
    { label: "Operation", value: "1005" },
    { label: "Reporting", value: "1006" },
    { label: "Level Management", value: "1007" },
    { label: "Device Management", value: "1008" },
    { label: "Device Conroller", value: "1009" },
    ]
export const ResponseMessageLocal = {
    confirmMessageDelete: "Are you sure you want to delete?"
}
export const ResponseMessageLocalupdate = {

    confirmMessageUpdate: "Are you sure you want to update password?"

}
export const ResponseMessageupdate = {

    confirmMessageUpdate: "successfully add new device"

}
export const ResponseMessageupdatelevel = {

    confirmMessageUpdate: "successfully add new device"

}
export const ResponseMessageupdatess = {

    confirmMessageUpdate: "successfully add new Hub"

}
export const organizationsType = [
    { label: "Organization", value: "organization" },
    { label: "Location", value: "level" }
]

export const ResponseMessageLocalpermenant = {
    confirmMessageHardDelete: "Are you sure you want to Parmenant delete?"
}
