import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const stateReducer = createSlice({
    name: 'STATE',
    initialState,
    reducers: {
        ADD_STATE: (state, action) => {
            state.deviceLogs = action.payload
        }
    }
})
export const { ADD_STATE } = stateReducer.actions

export default stateReducer.reducer