import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card';
import Select from '../../components/select/select';
import FullLoader from '../../components/loader/fullPageLoader';
import Mypopup from '../../components/popup/mypopup';
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import { Sort, getDevicesList } from '../../functions/functions';
import InlineLoader from '../../components/loader/inlineLoader';
import { GetRoots, deviceDelete, getDevicesByLevelId, getHubByLevelId } from '../../services/utilities/events';
import Input from '../../components/input/input';
import AddDeviceForm from './addDeviceForm';
import DeviceInfoPopup from '../../components/deviceInfoPopup/DeviceInfoPopup';
import { Tooltip } from 'bootstrap';
import moment from 'moment/moment';

const DeviceManager = (props) => {
    const [state, setState] = useState({
        loader: true,
        deviceList: []
    });
    let [deviceList, setDeviceList] = useState([]);
    let [confirmPopup, setConfirmPopup] = useState(false);
    let [inlineLoader, setInlineLoader] = useState(false);
    let [loader, setLoader] = useState(false);
    let [deviceData, setDeviceData] = useState(null);
    let [addDevice, setAddDevice] = useState(false);
    const [rootLevel, setRootLevel] = useState([]);
    const [searchHubs, setSearchHubs] = useState("");
    const [levelId, setLevelId] = useState("");
    const [deviceInfoPopup, setDeviceInfoPopup] = useState(null); // State for DeviceInfoPopup
    const [sortType, setSortType] = useState('date-dsc');
    const [sortedUsers, setSortedUsers] = useState([]);

    const [id, setId] = useState();

    useEffect(() => {
        const fetchData = async () => {
            await rootLevelGet();
        };
        fetchData();

        // Initialize Bootstrap tooltips
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new Tooltip(tooltipTriggerEl);
        });
    }, []);

    const onChangeHandler = async (e) => {
        setState((prevState) => ({
            ...prevState,
            loader: true
        }));

        let name = e.name;
        if (name === "organization") {
            const List = await getDevicesByLevelId(e.target.value);
            setLevelId(e.target.value);
            setState((prevState) => ({
                ...prevState,
                deviceList: List
            }));
        }

        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,
                loader: false
            }));
        }, 2000);
    };

    let filterHubs = [];
    if (state.deviceList.length !== 0) {
        filterHubs = state.deviceList.filter(item => {
            if (item.name) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        });
    }

    const DeviceGet = async (e) => {
        if (e === "success") {
            setState((prevState) => ({
                ...prevState,
                loader: true
            }));
            let devices = await getHubByLevelId(levelId);
            setState((prevState) => ({
                ...prevState,
                deviceList: devices
            }));
            setAddDevice(null);
        }
    };

    const rootLevelGet = async () => {
        let root = await GetRoots();
        let organization = [];
        root.length && root.map(org => {
            organization.push({ label: org, value: org });
        });

        setRootLevel(organization);
        setState((prevState) => ({
            ...prevState,
            loader: false
        }));
    };

    const addEditDevice = (data = null) => {
        if (data) {
            setAddDevice(data);
            setDeviceData(data);
        } else {
            setAddDevice(true);
            setDeviceData(null);
        }
    };

    const removeDevice = async (data, key) => {
        setInlineLoader(true);
        if (true) {
            await deviceDelete(data._id).then(() => {});
            let devices = await getDevicesByLevelId(levelId);
            setState((prevState) => ({
                ...prevState,
                deviceList: devices
            }));
            setInlineLoader(false);
        }
        setConfirmPopup(null);
    };

    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = Sort(type, filterHubs, objkey)
        console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        // Set sortedUsers when filterHubs changes
        setSortedUsers(filterHubs);
    }, [state.deviceList, searchHubs]);
    return (
        <div className='mt-2 px-3'>
            <Card>
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <Heading size={1} className="mr-3">Devices</Heading>
                        </div>
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => addEditDevice()}>Add Device</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <div className='col-12 mb-2'>
                    <Select label="Select Organization" name="organization" onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                </div>
                {state.deviceList.length ?
                    <div className='col-12 mb-3'>
                        <Input label="Search Hub" name="search" onChange={(e) => setSearchHubs(e.target.value)} />
                    </div>
                    : null}
            </Card>
            <Card className='overflow-hidden mt-3 mb-2'>
                <CardHeader className='row m-0 align-items-center'>
                    <Heading className='col-3 col-sm-3 col-lg-1'>S.No</Heading>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-id-asc' : 'text-id-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>

<Heading className="d-flex gap-2">Name
    <div className='ml-2'>

        {sortType.includes('id') ?
            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
            :
            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
        }</div>
</Heading>
</div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-type-asc' : 'text-type-' + (sortType.includes('asc') ? 'des' : 'asc')), 'type')}>

                        <Heading className="d-flex gap-2">Type
                            <div className='ml-2'>

                                {sortType.includes('type') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'levelId')}>
                        <Heading className="d-flex gap-2">Location
                            <div className='ml-2'>

                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-LC-asc' : 'text-LC-' + (sortType.includes('asc') ? 'des' : 'asc')), 'hubId')}>

                        <Heading className="d-flex gap-2">Hub Id
                            <div className='ml-2'>

                                {sortType.includes('LC') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc')), 'date')}>

                        <Heading className="d-flex gap-2">Created
                            <div className='ml-2'>

                                {sortType.includes('date') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-1 d-flex justify-content-end' style={{ width: "100px" }}><b>Actions</b></div>
                </CardHeader>
            </Card>
            {loader ? <FullLoader /> : filterHubs.length > 0 ?
                sortedUsers.map((device, key) => {
                    // Split the levelId by underscore and get the last part
                    const lastLevelId = device.levelId.split('_').pop();

                    return (
                        <Card className="overflow-hidden mt-1" key={key}>
                            <CardHeader className='row m-0 align-items-center'>
                                <div className='col-3 col-sm-3 col-lg-1'>{key + 1}</div>
                                <div className='col-3 col-sm-3 col-lg-2'>{device.name}</div>
                                <div className='col-12 col-sm-4 col-lg-2'>{device.type}</div>
                                <div 
                                    className='col-12 col-sm-4 col-lg-2'
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={device.levelId.replace(/_/g, '>')}// Tooltip with the full levelId
                                >
                                    {lastLevelId} {/* Display only the part after the last underscore */}
                                </div>
                                <div className='col-12 col-sm-4 col-lg-2'>{device.hubId}</div>
                                <div className='col-2 col-sm-3 col-lg-2'>{moment(device.created).format("ll LTS")}</div>

                                <div className='col-1 d-flex justify-content-end' style={{ width: "100px" }}>
                                    <ButtonContainer className="p-2 btn-light" flat="light">
                                        <Button className='deviceBtn deviceRoundBtn btn'>
                                            <i className="fa fa-info" onClick={() => setDeviceInfoPopup(device)}></i>
                                        </Button>
                                        <Button onClick={() => addEditDevice(device)}>
                                            <i className="lt lt-edit"></i>
                                        </Button>
                                        <Button onClick={() => setConfirmPopup({ device, key })}>
                                            <i className="lt lt-trash"></i>
                                        </Button>
                                    </ButtonContainer>
                                </div>
                            </CardHeader>
                        </Card>
                    );
                })
                : null}
            {addDevice ? <Mypopup title={addDevice ? "Edit Device" : "Add Device"} onClose={() => { setAddDevice(null); }}>
                <AddDeviceForm data={deviceData} type={"direct"} onClose={() => setAddDevice(null)} reloadDevices={DeviceGet} /></Mypopup> : null}
            {confirmPopup ? <Mypopup title={"Confirm Delete"} onClose={() => setConfirmPopup(null)} className='centered-popup'>
                <div className='text-center'>
                    <p>Are you sure you want to delete?</p>
                    <ButtonContainer className='justify-content-center'>
                        <Button onClick={() => removeDevice(confirmPopup.device, confirmPopup.key)}>Yes</Button>
                        <Button onClick={() => setConfirmPopup(null)}>No</Button>
                    </ButtonContainer>
                </div>
            </Mypopup> : null}
            {inlineLoader ? <InlineLoader /> : null}
            {deviceInfoPopup ? (
                <DeviceInfoPopup res={deviceInfoPopup} onClose={() => setDeviceInfoPopup(null)} />
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    organization: state.organization,
    configration: state.configration,
    userInfo: state.userInfo
});

export default connect(mapStateToProps, null)(DeviceManager);
