import React, { useEffect, useState, version } from 'react'
//import Resources
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
// import './AddHubForm.scss'
import Input from '../../components/input/input';
import Mypopup from '../popup/mypopup';
import Select from '../select/select';
import { useSelector } from 'react-redux';
import { Getlevel, getHubByLevelId, getHubsByLevelId, hubCreated, hubCreatedMangment, hubUpdate, levelId1, updateHubMang } from '../../services/utilities/events';
import { SpecialCharacters, WhiteSpace } from '../../services/config/regex';
import { flattenArray } from '../../functions/functions';
import { ResponseMessageupdate, ResponseMessageupdatess } from '../../services/config/generic';

const AddHub = (props) => {
    console.log(props)
    const [typeList, setTypeList] = useState([])
    const [rootLevel, setRootLevel] = useState([])
    const [levelIdsList, setLevelIdsList] = useState([])
    const [confirmPopup, setConfirmPopup] = useState(null)

    const [root, setroot] = useState([])
    const [_id, set_id] = useState(true)
    const [name, setName] = useState(true)
    const [type, setType] = useState(true)
    const [levelIdvalidation, setlevelIdValidation] = useState(true)
    const [organizationID, setOrganizationID] = useState(true)
    const [longitude, setLongitude] = useState(true);
    const [latitude, setLatitude] = useState(true);
    const [state, setState] = useState({
        loader: true,
        deviceList: []
    });
    const [branch, setBranch] = useState(
        {
            type: "",
            levelId: "",
            orgId: "",
            _id: "",
            name: "",
            version: "0.0.0",
            authToken: "",
            metadata: { coordinates: { lat: "", lng: "" } },
        }
    )
    useEffect(() => {
        if (props?.data?._id) {

            setBranch({
                type: props.data.type,
                levelId: props.data.levelId,
                orgId: props.data.levelId.split("_")[0],
                _id: props.data._id,
                name: props.data.name,
                version: "0.0.0",
                authToken: props.data._id,
                metadata: props.data.metadata
            })
            console.log(props.data.levelId.split("_")[0], props)
            Getlevels(props.data.levelId.split("_")[0])
        }
    }, [])
    const configuration = useSelector(state => state.configuration)
    useEffect(() => {
        let List = []
        configuration?.config?.hub.map((HubType) => {
            List.push({ value: HubType.type, label: HubType.type })
        })
        setTypeList(List)
    }, [])
    const rootLevelGet = async () => {
        let root = await Getlevel()
        let organization = []
        root.length && root.map(org => {
            organization.push({ label: org._id, value: org._id })
        })

        setRootLevel(organization)
    }
    useEffect(() => {
        rootLevelGet()
    }, [])
    const Getlevels = async (id) => {
        let root = await levelId1(id)
        let array = flattenArray(root.levels)
        let level = []
        if (array.length) {
            level = array.map(lvel => {
                let levelNames = array.find(arr => arr._id === lvel.levelId)?.locationName || ''
                if (levelNames) {
                    levelNames += ' > '
                }
                return ({ label: levelNames + lvel.locationName, value: lvel._id })
            })
        }
        setroot(level)
        console.log(level)
    }


    const onChangeHandler = (e, index) => {
        let data = { ...branch }
        let name = e.name
        const value = e.target.value;

        data[name] = e.target.value
        if (name === "metadataLat") {
            data.metadata.coordinates.lat = value;
            setLatitude(true);
        } else if (name === "metadataLng") {
            data.metadata.coordinates.lng = value;
            setLongitude(true);
        }
        if (e.name === "_id") {
            data["authToken"] = e.target.value
        }
        if (e.name === "_id") {
            set_id(true)
        }
        if (e.name === "orgId") {
            setOrganizationID(true)
            Getlevels(e.target.value)
        }
        if (e.name === "name") {
            setName(true)
        }
        if (e.name === "type") {
            setType(true)
        }
        if (e.name === "levelId") {
            setlevelIdValidation(true)
        }
        if (e.name === "metadataLat") {
            setLatitude(true)
        }
        if (e.name === "metadataLng") {

            setLongitude(true)
        }
        setBranch(data)
    }
    // Validation MacAddress Field
    const MacAddress = () => {
        const special = SpecialCharacters
        var whiteSpace1 = WhiteSpace
        console.log(branch._id, branch._id?.length > 30, special?.test(branch._id), whiteSpace1.test(branch._id))
        if (!branch._id && branch._id.length > 30 && special.test(branch._id) && whiteSpace1.test(branch._id)) {
            set_id(false)
            return false
        }

        else {
            set_id(true)
            return true
        }
    }


    const checkName = () => {
        const special = SpecialCharacters
        if (!branch.name || special.test(branch.name)) {
            setName(false)
            return false
        }
        else {

            setName(true)
            return (true)
        }

    }
    // Validation Type Field
    const checkType = () => {
        if (!branch.type) {
            setType(false)
            return false
        }
        else {

            setType(true)
            return (true)
        }
    }

    const checkLevelIdValidation = () => {
        if (props.type) {
            if (!branch.levelId) {
                setlevelIdValidation(false)
                return false
            }
            else {
                setlevelIdValidation(true)
                return (true)
            }
        } else {
            setlevelIdValidation(true)
            return true
        }
    }

    const validateCoordinates = () => {
        const special = SpecialCharacters;
        var whiteSpace1 = WhiteSpace;
        if (special.test(branch.metadata.coordinates.lat) || special.test(branch.metadata.coordinates.lng) ||
            whiteSpace1.test(branch.metadata.coordinates.lat) || whiteSpace1.test(branch.metadata.coordinates.lng)) {
            setLatitude(false);
            setLongitude(false);
            return false;
        } else {
            setLatitude(true);
            setLongitude(true);
            return true;
        }
    };
    // Hub Create Function 
    const createHub = () => {
        const isMacAddressValid = MacAddress();
        const isNameValid = checkName();
        const isTypeValid = checkType();
        const isLevelIdValid = checkLevelIdValidation();
        const areCoordinatesValid = validateCoordinates();

        // If any validation fails, return early
        if (!isMacAddressValid || !isNameValid || !isTypeValid || !isLevelIdValid || !areCoordinatesValid) {
            console.log("Validation failed");
            console.log(isLevelIdValid, isMacAddressValid, isNameValid, isTypeValid)
            return;
        }


        if (props.data && props?.type) {
            return updateHubMang(branch._id, branch).then(async res => {
                let result = res
                if (result) {
                    props.onResPopup(result)
                    setConfirmPopup(true)
                }


            })

        } else {
            console.log(branch)
            return hubCreatedMangment(branch).then(async res => {
                let result = res
                if (result) {
                    props?.onResPopup(result);

                    setConfirmPopup(true)
                }
            })
        }



    }

    return (
        <div className='px-4 pb-2'>
            {console.log(rootLevel)}
            <div className='row mt-4'>
                <div className='col-12'>
                    {props?.data?._id ?
                        <Input name="_id" label="Mac Address*" disabled defaultValue={branch._id.split('_')[1]} onChange={onChangeHandler} ></Input>
                        :
                        <Input name="_id" label="Mac Address*" defaultValue={branch._id.split('_')[1]} onChange={onChangeHandler} message={{ msg: "Please enter your Macaddress", status: !_id }} ></Input>}
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    {props?.data?._id ?
                        <Input dropDownHeight="100px" name="orgId" label="Organization ID*" disabled defaultValue={branch.orgId} />
                        :
                        <Select dropDownHeight="100px" name="orgId" label="Organization ID*" defaultValue={{ value: branch.orgId }} message={{ msg: "Please Select your oragnization", status: !organizationID }} onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                    }       </div>
            </div>
            <div className='row mt-4'>

                <div className='col-12'>
                    <Select dropDownHeight="100px" name="type" label="Type*" defaultValue={{ value: branch.type }} onClick options={typeList} message={{ msg: "Please select your hub type", status: !type }} onChange={(e) => onChangeHandler(e)} />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-12'>
                    <Select dropDownHeight="100px" name="levelId" label="Level ID*" defaultValue={{ value: branch.levelId }} message={{ msg: "Please select your Device level", status: !levelIdvalidation }} onClick options={root} onChange={(e) => onChangeHandler(e)} />
                </div>
            </div>

            <div className='row mt-4    '>
                <div className='col-12'>
                    <Input dropDownHeight="100px" name="name" defaultValue={branch.name} message={{ msg: "Please enter your Hub name", status: !name }} label="Name*" onChange={(e) => onChangeHandler(e)} />
                </div>
                
            </div>
            <div className='row mt-4'>
                <div className='col-6'>
                    <Input name="metadataLat" label="Latitude" value={branch.metadata.coordinates.lat} defaultValue={branch.metadata.coordinates.lat} onChange={onChangeHandler} message={{ msg: "Please enter a valid latitude", status: !latitude }} ></Input>
                </div>
                <div className='col-6'>
                    <Input name="metadataLng" label="Longitude" value={branch.metadata.coordinates.lng} defaultValue={branch.metadata.coordinates.lng} onChange={onChangeHandler} message={{ msg: "Please enter a valid longitude", status: !longitude }} ></Input>
                </div>
            </div>
            <ButtonContainer className="mt-5">
                <Button onClick={createHub}>{props?.data?._id ? "Update" : "Add Hub"}</Button>
            </ButtonContainer>
            {confirmPopup ? <Mypopup title={"Confirm!"}>
                <p className="p-3">{props?.data?._id ? "Hub updated successfully." : ResponseMessageupdatess.confirmMessageUpdate}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => { setConfirmPopup(false); props.onAddUser(); }} >ok</button>
                </div>
            </Mypopup> : null}
        </div>
    )
}

export default AddHub
