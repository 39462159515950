import React, { useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, Heading } from '../../../components/card/card'
import { Button, ButtonContainer } from '../../../components/arraybutton/arraybutton'
import { GetLogsByDeviceId, GetStateByDeviceId } from '../../../services/utilities/events'
import CardLoader from '../../../components/loader/cardLoader'
import { getDate, getTime, todayDate } from '../../../functions/functions'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from '@mui/material'
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers'



const Modal = ({ hub, ...props }) => {
    // const chartAddNode = useRef(0);
    // const [parameterConfig, setParameterConfig] = useState({
    //     id: hub._id + idGenerator(),
    //     axisX: "date",
    //     axisY: "value",
    //     scrollbar: true,
    //     series:
    //         [
    //             {
    //                 type: "type" in props ?
    //                     props.type
    //                     : "binary",
    //                 name: hub._id,
    //                 // name: primaryLetters+" Intake",
    //                 color: "#008df2",
    //                 x: "Date",
    //                 y: hub_id,
    //             }
    //         ]
    // })


    const lastLog = useSelector(state => state.getLastLogs)
    const lastState = useSelector(state => state.lastState)
    const lastEvents = useSelector(state => state.getLastEvent)
    const liveDeviceEve = useSelector(state => state.eventReducer)
    const stateEvent = useSelector(state => state.getState)
    const [startDate, setStartDate] = useState(todayDate('start'))
    const [endDate, setEndDate] = useState(todayDate('end'))

    const [viewMode, setViewMode] = useState(0)
    const [live, setLive] = useState(true)
    const [state, setState] = useState([])
    const [logs, setLogs] = useState([])
    const [connection, setConnection] = useState([])
    const [event, setEvent] = useState([])
    const [loader, setLoader] = useState(false)
    const onChangeHandler = async (e) => {
        setLive(true)
        setViewMode(e)
        if (e === 1) {
            setLoader(true)
            let response = await GetStateByDeviceId(hub._id, 1)
            setState(response)
            setLoader(false)
        }
        else if (e === 2) {
            setLoader(true)
            let response = await GetLogsByDeviceId(hub._id, 10, hub.type)
            setConnection(response)
            setLoader(false)
        }
        else if (e === 3) {
            setLoader(true)
            let response = await GetStateByDeviceId(hub._id, 10)
            setLogs(response)
            setLoader(false)
        }
    }
    const openPopup = (e) => {
        props.popup(e)
    }
    const onChangeLive = async (e = null) => {
        setLoader(true)
        if (!live) {
            if (e) {
                let response = await GetLogsByDeviceId(hub._id, 10, hub.type)
                setConnection(response)
                setStartDate(todayDate('start'))
                setEndDate(todayDate('end'))
            } else {
                let response = await GetStateByDeviceId(hub._id, 10)
                setLogs(response)
                setStartDate(todayDate('start'))
                setEndDate(todayDate('end'))
            }

        } else {
            if (e) {
                let response = await GetLogsByDeviceId(hub._id, 20, hub.type, startDate, endDate)
                setConnection(response)
            } else {
                let response = await GetStateByDeviceId(hub._id, 20, startDate, endDate)
                setLogs(response)
            }
        }
        setLoader(false)
        setLive(!live)
    }
    const handlerStartDate = async (event) => {
        setLoader(true)
        let startDate1 = moment(event.$d).format('YYYY-MM-DDTHH:mm:ss')
        setStartDate(startDate1);
        // if (!live) {
        if (viewMode === 2) {
            let response = await GetLogsByDeviceId(hub._id, 20, hub.type, startDate1, endDate)
            setConnection(response)
        } else {
            let response = await GetStateByDeviceId(hub._id, 20, startDate1, endDate)
            setLogs(response)
        }
        setLoader(false)
        // }

    }

    const handlerEndDate = async (event) => {
        setLoader(true)
        let endDate1 = moment(event.$d).format('YYYY-MM-DDTHH:mm:ss')
        setEndDate(endDate1);
        // if (!live) {
        if (viewMode === 2) {
            let response = await GetLogsByDeviceId(hub._id, 20, hub.type, startDate, endDate1)
            setConnection(response)
        } else {
            let response = await GetStateByDeviceId(hub._id, 20, startDate, endDate1)
            setLogs(response)
        }
        setLoader(false)
        // }
    }

    useEffect(() => {
        let state1 = [...state]
        if ("deviceLogs" in stateEvent) {
            if (stateEvent.deviceLogs) {
                if (stateEvent.deviceLogs.hubId === hub._id) {
                    state1.unshift(
                        {
                            data: stateEvent.deviceLogs.data,
                            hubId: stateEvent.deviceLogs.hubId,
                            type: stateEvent.deviceLogs.type,
                            created: stateEvent.deviceLogs.created,
                        }
                    )
                    if (state1.length > 10) {
                        state1.splice(9, 10)
                    }
                }
            }
        }
        setState(state1)
    }, [stateEvent])

    useEffect(() => {
        if (live) {
            let connection1 = [...connection]
            if ("lastEventConnection" in lastEvents) {
                if (lastEvents.lastEventConnection) {
                    if (lastEvents.lastEventConnection.deviceId === hub._id) {
                        connection1.unshift(
                            {
                                value: lastEvents.lastEventConnection.value,
                                created: lastEvents.lastEventConnection.created,
                            }
                        )
                        if (connection1.length > 10) {
                            connection1.splice(9, 10)
                        }
                    }
                }
            }
            setConnection(connection1)
        }
    }, [lastEvents])

    useEffect(() => {
        if (live) {
            let log1 = [...logs]
            if ("deviceLogs" in lastLog) {
                if (lastLog.deviceLogs) {
                    if (lastLog.deviceLogs.deviceId === hub._id) {
                        log1.unshift(
                            {
                                message: lastLog.deviceLogs.message,
                                created: lastLog.deviceLogs.created,
                            }
                        )
                        if (log1.length > 10) {
                            log1.splice(9, 10)
                        }
                    }
                }
            }
            setLogs(log1)
        }
    }, [lastLog])


    useEffect(() => {
        let event1 = [...event]
        if ("deviceEvents" in liveDeviceEve) {
            if (liveDeviceEve.deviceEvents) {
                const devciceId = liveDeviceEve.deviceEvents.deviceId.split("_")
                if (devciceId[0] + "_" + devciceId[1] === hub._id) {
                    event1.unshift(
                        {
                            eve: liveDeviceEve.deviceEvents,
                            created: liveDeviceEve.deviceEvents.created,
                        }
                    )
                    if (event1.length > 10) {
                        event1.splice(9, 10)
                    }
                }
            }
        }
        setEvent(event1)
    }, [liveDeviceEve])

    return (
        <Card className='w-100 m-3' >
            <CardHeader>
                <ButtonContainer className="m-3">
                    <Button onClick={() => onChangeHandler(0)} active={viewMode === 0 ? true : false}>Recent Events</Button>
                    <Button onClick={() => onChangeHandler(1)} active={viewMode === 1 ? true : false}>State</Button>
                    <Button onClick={() => onChangeHandler(2)} active={viewMode === 2 ? true : false}>Connection</Button>
                    <Button onClick={() => onChangeHandler(3)} active={viewMode === 3 ? true : false}>Logs</Button>
                </ButtonContainer>
            </CardHeader>
            <CardBody>
                {viewMode === 0 ?
                    <Card>
                        <CardHeader className="row m-0">
                            <div className="col-6 col-sm-6 col-lg-6">
                                <Heading >
                                    Event
                                </Heading>
                            </div>
                            {/* <div className="col-3 col-sm-3 col-lg-3">
                                <Heading >
                                    Value
                                </Heading>
                            </div> */}

                            <div className="col-2 col-sm-2 col-lg-2 " >
                                <Heading
                                    align="center">
                                    Format
                                </Heading>
                            </div>
                            <div className="col-4 col-sm-4 col-lg-4" >
                                <Heading
                                    align="center">
                                    Last Received
                                </Heading>
                            </div>
                        </CardHeader>
                        {event.length ?
                            event.map(eve =>
                                <div className='d-flex w-100' style={{ minHeight: 200, maxHeight: 400, overflow: "auto" }}>

                                    <div style={{ cursor: "pointer" }} onClick={() => openPopup(eve)} className="col-6 col-sm-6 col-lg-6 text-truncate" title={JSON.stringify(eve)} >
                                        <p >
                                            {JSON.stringify(eve.eve)}
                                        </p>
                                    </div>
                                    {/* <div className="col-3 col-sm-3 col-lg-3" >
                                        <p >
                                            {eve?.value}
                                        </p>
                                    </div> */}
                                    <div className="col-2 col-sm-2 col-lg-2" >
                                        <p>
                                            JSON
                                        </p>
                                    </div>
                                    <div className="col-4 col-sm-4 col-lg-4">
                                        <p
                                            align="center">
                                            {moment(eve.created).format("ll LTS")}
                                            {/* {getTime(eve.created) + " " + getDate(eve.created)} */}
                                        </p>
                                    </div>
                                </div>
                            )
                            : <p>No Events Available</p>}
                    </Card>
                    : viewMode === 1 ?
                        <Card>
                            <CardHeader className="row m-0">
                                <div className="col-6 col-sm-6 col-lg-6">
                                    <Heading >
                                        Event
                                    </Heading>
                                </div>
                                <div className="col-3 col-sm-3 col-lg-3">
                                    <Heading >
                                        Type
                                    </Heading>
                                </div>

                                <div className="col-3 col-sm-2 col-lg-3" >
                                    <Heading
                                        align="center">
                                        Received
                                    </Heading>
                                </div>
                            </CardHeader>
                            <CardBody style={{ minHeight: 200, maxHeight: 400, overflow: "auto" }}>
                                {loader ? <CardLoader /> : state.length ?
                                    state.map(st =>
                                        <div className='d-flex w-100'>

                                            <div style={{ cursor: "pointer" }} onClick={() => openPopup(st)} className="col-6 col-sm-6 col-lg-6 text-truncate" title={JSON.stringify(hub.st)} >
                                                <p >
                                                    {JSON.stringify(st.data)}
                                                </p>
                                            </div>
                                            <div className="col-3 col-sm-3 col-lg-3" >
                                                <p >
                                                    {st?.type}
                                                </p>
                                            </div>

                                            <div className="col-3 col-sm-3 col-lg-3">
                                                <p
                                                    align="center">
                                                    {moment(st.created).format("ll LTS")}
                                                </p>
                                            </div>
                                        </div>
                                    ) :
                                    <p>No Logs Available</p>
                                }
                            </CardBody>
                        </Card>
                        : viewMode === 2 ?
                            <Card>
                                <div className='row mx-md-0 mt-3 justify-content-start'>
                                    <div className='col-lg-2 col-6'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                style={{ fontSize: 16 }}
                                                disableFuture={true}
                                                label="Start Date"
                                                disabled={live ? true : false}
                                                value={dayjs(startDate)}
                                                onAccept={(event) => handlerStartDate(event)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className='col-lg-2 col-6'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                style={{ fontSize: 16 }}
                                                disableFuture={true}
                                                label="End Date"
                                                disabled={live ? true : false}
                                                value={dayjs(endDate)}
                                                onAccept={(event) => handlerEndDate(event)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className='d-flex mr-3 justify-content-end'>
                                        <ButtonContainer>
                                            <Button onClick={() => onChangeLive("con")} active={live}>
                                                Live
                                            </Button>
                                            <Button onClick={() => onChangeLive("con")} active={!live}>
                                                History
                                            </Button>
                                        </ButtonContainer>
                                    </div>
                                </div>

                                <CardHeader className="row m-0">
                                    <div className="col-6 col-sm-6 col-lg-6">
                                        <Heading >
                                            Message
                                        </Heading>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-6" >
                                        <Heading
                                            align="center">
                                            Date
                                        </Heading>
                                    </div>
                                </CardHeader>
                                <CardBody style={{ minHeight: 200, maxHeight: 400, overflow: "auto" }}>
                                    {loader ? <CardLoader /> : connection.length ?
                                        connection.map(connection =>
                                            <div className='d-flex w-100'>
                                                <div className="col-6 col-sm-6 col-lg-6 text-truncate" title={connection.value === 1 ? "Device Connected" : "Device Disconnected"} >
                                                    <p >
                                                        {connection.value === 1 ? "Device Connected" : "Device Disconnected"}
                                                    </p>
                                                </div>

                                                <div className="col-6 col-sm-6 col-lg-6">
                                                    <p
                                                        align="center">
                                                        {moment(connection.created).format("ll LTS")}
                                                    </p>
                                                </div>
                                            </div>
                                        ) :
                                        <p>No connection Available</p>
                                    }
                                </CardBody>
                            </Card>
                            : viewMode === 3 ?
                                <Card>
                                    <div className='row mx-md-0 mt-3 justify-content-start'>
                                        <div className='col-lg-2 col-6'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    style={{ fontSize: 16 }}
                                                    disableFuture={true}
                                                    label="Start Date"
                                                    disabled={live ? true : false}
                                                    value={dayjs(startDate)}
                                                    onAccept={(event) => handlerStartDate(event)}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className='col-lg-2 col-6'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    style={{ fontSize: 16 }}
                                                    disableFuture={true}
                                                    label="End Date"
                                                    disabled={live ? true : false}
                                                    value={dayjs(endDate)}
                                                    onAccept={(event) => handlerEndDate(event)}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className='d-flex mr-3 justify-content-end'>
                                            <ButtonContainer>
                                                <Button onClick={() => onChangeLive()} active={live}>
                                                    Live
                                                </Button>
                                                <Button onClick={() => onChangeLive()} active={!live}>
                                                    History
                                                </Button>
                                            </ButtonContainer>
                                        </div>
                                    </div>
                                    <CardHeader className="row m-0">
                                        <div className="col-6 col-sm-6 col-lg-6">
                                            <Heading >
                                                Message
                                            </Heading>
                                        </div>
                                        <div className="col-6 col-sm-6 col-lg-6" >
                                            <Heading
                                                align="center">
                                                Date
                                            </Heading>
                                        </div>
                                    </CardHeader>
                                    <CardBody style={{ minHeight: 200, maxHeight: 400, overflow: "auto" }}>
                                        {loader ? <CardLoader /> : logs.length ?
                                            logs.map(logs =>
                                                <div className='d-flex w-100' >
                                                    <div className="col-6 col-sm-6 col-lg-6 text-truncate" title={logs.message} >
                                                        <p >
                                                            {logs.message}
                                                        </p>
                                                    </div>

                                                    <div className="col-6 col-sm-6 col-lg-6">
                                                        <p
                                                            align="center">
                                                            {moment(logs.created).format("ll LTS")}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) :
                                            <p>No connection Available</p>
                                        }
                                    </CardBody>
                                </Card>
                                : null}
            </CardBody>
        </Card>
    )
}

export default Modal
