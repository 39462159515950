import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Mypopup from "../../components/popup/mypopup";
import { ConfigrationDevice, delHubs, getResource } from "../../services/utilities/events";
import { getImgUrl } from "../../services/config/serverAddresses";
import { ResponseMessageLocal } from "../../services/config/generic";
import Input from "../../components/input/input";
import { Icon } from "@mui/material";

const Devices = (props) => {
    const history = useNavigate();
    const [loader, setLoader] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [iconPopup, setIconPopup] = useState({ visible: false, iconUrl: null, message: null });
    const [typeSearchTerm, setTypeSearchTerm] = useState("");
    const [parameterPopup, setParameterPopup] = useState({ visible: false, parameterData: null });
    
    const [sortCriteria, setSortCriteria] = useState('name'); // Default sorting by 'name'
    const [sortOrder, setSortOrder] = useState('asc'); 
    const DeviceGet = async () => {
        setLoader(true);
        try {
            const devices = await ConfigrationDevice("device");
            setDeviceList(devices);
        } catch (error) {
            // console.error("Error fetching devices:", error);
            // Handle error state or logging as needed
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        DeviceGet();
    }, []);

    const deleteAccess = async (id, key) => {
        try {
            await delHubs(id);
            setDeviceList(prevList => prevList.filter((item, index) => index !== key));
        } catch (error) {
            // console.error("Error deleting device:", error);
            // Handle error state or logging as needed
        } finally {
            setConfirmPopup(null);
        }
    };

    const userDetail = (data) => {
        history('/Configuration/Devices/AddDevicePopup', { state: data });
    };

    const handleViewIcon = async (iconSrc, _id, type) => {
        if (!iconSrc) {
            setIconPopup({ visible: true, iconUrl: null, message: "Icon source is not available" });
            return;
        }

        try {
            const iconData = await imgRenderFunc(iconSrc, _id, type);
            const iconUrl = imgReturnFunc(iconData);
            setIconPopup({ visible: true, iconUrl });
        } catch (error) {
            // console.error("Error handling icon:", error);
            setIconPopup({ visible: true, iconUrl: null, message: "Error displaying icon" });
        }
    };

    const handleCloseIconPopup = () => {
        setIconPopup({ visible: false, iconUrl: null, message: null });
    };

    const imgRenderFunc = async (src, _id, type) => {
        let image = null;
        if (src) {
            if (src.endsWith(".svg")) {
                image = await getResource(src);
                image = { icon: image, type: 'svg' };
            } else if (src.endsWith(".js")) {
                let imagecheck = await getResource(src)
                let imageFunction = eval("(" + imagecheck + ")")
                if (typeof imageFunction === 'function') {
                    image = imageFunction({ _id, type});
                }
                if (typeof image === 'object' && image !== null && 'image' in image) {
                    image = image.image;
                }
                image = { icon: image, type: 'js' }
            } else {
                image = { icon: getImgUrl + src, type: 'img' };
            }
        }
        return image;
    };

    const imgReturnFunc = (data) => {
        if (data) {
            let img = data;
            if (img.type === "svg") {
                return "data:image/svg+xml;base64," + btoa(img.icon);
            } else if (img.type === "js") {
                if (typeof img.icon === "function") {
                    const svgString = img.icon({ value: 0 }); // Generate SVG string
                    // console.log(svgString)
                    return "data:image/svg+xml;base64," + btoa(svgString); // Convert SVG string to base64
                } else {
                    // console.error("img.icon is not a function for type 'js'");
                    return null;
                }
            } else {
                return img.icon;
            }
        }
        return null;
    };

    // Filtering logic for type search
    const filteredDeviceList = deviceList.filter((device) =>
        device.config && device.config.type &&
        device.config.type.toLowerCase().includes(typeSearchTerm.toLowerCase())
    );
    const handleViewParameter = (parameterData) => {
        setParameterPopup({ visible: true, parameterData: JSON.stringify(parameterData, null, 2) });
    };

    const handleCloseParameterPopup = () => {
        setParameterPopup({ visible: false, parameterData: null });
    };
    const sortHubList = (list) => {
        return list.sort((a, b) => {
            let aValue = (a.config[sortCriteria] || '').toString().toLowerCase();
            let bValue = (b.config[sortCriteria] || '').toString().toLowerCase();

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });
    };

    // Filtered and sorted hub list
    const sortedAndFilteredHubList = sortHubList(
        deviceList.filter(level =>
            level.config?.type.toLowerCase().includes(typeSearchTerm.toLowerCase())
        )
    );
    return (
        <div className='mt-2 px-3'>
            <Card  className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Device</Heading>
                        </div>
                        <div>
                <Input
                    type="text"
                    // placeholder="Search by type..."
                    label="Search Device"
                    value={typeSearchTerm}
                    onChange={(e) => setTypeSearchTerm(e.target.value)}
                />
            </div>
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => userDetail()}>Add Device</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                </CardHeader>
            </Card>
            <Card  className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row align-items-center'>
                    <div className='col-1 col-sm-1 col-lg-1'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>S.No</Heading></div>
                    {/* <div className='col-4 col-sm-3 col-lg-4'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>NameSuggetion</Heading></div> */}
                    <div className='col-4 col-sm-6 col-lg-4' style={{ cursor: 'pointer' }} onClick={() => {
                        const newOrder = sortCriteria === 'type' ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
                        setSortCriteria('type');
                        setSortOrder(newOrder);
                    }}>
                        <Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }} className="d-flex gap-2">NameSuggetion
                            <div className='ml-2'>
                                {sortCriteria === 'type' ? (
                                    sortOrder === 'asc' ? (
                                        <i className="fas fa-sort-down"></i>
                                    ) : (
                                        <i className="fas fa-sort-up"></i>
                                    )
                                ) : (
                                    <i style={{ color: 'lightgrey' }} className="fas fa-sort"></i>
                                )}
                            </div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-2 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => {
                        const newOrder = sortCriteria === 'name' ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
                        setSortCriteria('name');
                        setSortOrder(newOrder);
                    }}>
                        <Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }} className="d-flex gap-2">Type
                            <div className='ml-2'>
                                {sortCriteria === 'name' ? (
                                    sortOrder === 'asc' ? (
                                        <i className="fas fa-sort-down"></i>
                                    ) : (
                                        <i className="fas fa-sort-up"></i>
                                    )
                                ) : (
                                    <i style={{ color: 'lightgrey' }} className="fas fa-sort"></i>
                                )}
                            </div>
                        </Heading>
                    </div>
                    
                    {/* <div className='col-2 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Type</Heading></div> */}
                    <div className='col-2 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Icon</Heading></div>
                    <div className='col-1 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Parameter</Heading></div>
                    <div className='col-2 col-sm-2 col-lg-1 d-flex'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Action</Heading></div>
                </CardHeader>
            </Card>
            {sortedAndFilteredHubList.length > 0 ?
                sortedAndFilteredHubList.map((device, key) =>
                    <Card className="overflow-hidden mt-1" key={key}>
                        {/* {console.log(device)} */}
                        <CardHeader className='row align-items-center'>
                            <div className='col-1 col-sm-1 col-lg-1'>{key + 1}</div>
                            <div className='col-3 col-sm-3 col-lg-4'>
                                
                                    <div className="tooltip-content">
                                        {device?.config.name}
                                    </div>
                                </div>
                            <div className='col-2 col-sm-2 col-lg-2'>{device?.config?.type}</div>
                            <div className='col-2 col-sm-2 col-lg-2 d-flex'>
                                <ButtonContainer className="mr-2 p-2 btn-light" flat="light">
                                    <Button onClick={() => handleViewIcon(device?.config?.icon, device._id, device?.config?.type)}>VIEW</Button>
                                </ButtonContainer>
                            </div>
                            <div className='col-2 col-sm-2 col-lg-2 d-flex'>
                                <ButtonContainer className="mr-2 p-2 btn-light" flat="light">
                                    <Button onClick={() => handleViewParameter(device?.config?.parameter)}>VIEW</Button>
                                </ButtonContainer>
                            </div>
                            <div className='col-2 col-sm-2 col-lg-1 d-flex'>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button>
                                        <i className="lt lt-edit" onClick={() => userDetail(device)}></i>
                                    </Button>
                                    <Button>
                                        <i className="lt lt-trash" onClick={() => { setConfirmPopup({ data: device._id, key }) }}></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : <p>No devices found</p>}
            {confirmPopup && <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                    <button style={{ width: 100 }} className="btn btn-light" onClick={() => { setConfirmPopup(null) }}>No</button>
                </div>
            </Mypopup>}
                {iconPopup.visible && <Mypopup title={"Icon"} onClose={handleCloseIconPopup}>
                    <div className="d-flex justify-content-center p-3">
                        {iconPopup.message ? (
                            <p>{iconPopup.message}</p>
                        ) : (
                            typeof iconPopup.iconUrl === 'string' ? (
                                <img src={iconPopup.iconUrl} alt="Device Icon" style={{ height: '300px', width: "300px", objectFit: "contain" }} />
                                
                            ) : (
                                <div className="alert alert-danger" role="alert">
                                    Error displaying icon. Please check the icon source.
                                </div>
                            )
                        )}
                    </div>

                </Mypopup>}
            {parameterPopup.visible && <Mypopup title={"Parameter"} onClose={handleCloseParameterPopup}>
            <div className="p-3" style={{ maxWidth: "800px", maxHeight: "400px", overflow: "auto" }}>
                    <pre>{parameterPopup.parameterData}</pre>
                </div>
            </Mypopup>}
        </div>
    );
};

export default Devices;
