import { createSlice } from "@reduxjs/toolkit";
 const initialState={}

 export const fetchUserList=createSlice({
    name:'Lists',
    initialState, 
    reducers: {
        USER_List:(state,action)=>{
            state.UserLists=action.payload
        }
    }
 })
export const {USER_List}=fetchUserList.actions

export default fetchUserList.reducer




