import { useSelector } from "react-redux";
import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { useEffect, useState } from "react";
import { GetAdminRoles, Harddelroles, delRoleconfig } from "../../services/utilities/events";
import Mypopup from "../../components/popup/mypopup";
import AddrolePopup from "../../components/RolePopup/RolePopup";
import Input from "../../components/input/input"; // Assuming you have an Input component
import { ResponseMessageLocal, ResponseMessageLocalpermenant } from "../../services/config/generic";
import { Sort } from "../../functions/functions";

const Devices = (props) => {
    const [loader, setLoader] = useState(false);
    const [addRole, setAddRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [msgPopup, setMsgPopup] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [confirmedPopup, setConfirmedPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const configuration = useSelector(state => state.configuration);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');

    const RolesGet = async () => {
        setAddRole(null);
        setLoader(true);
        let roles = await GetAdminRoles();
        const role = matchConfigurationWithName(roles, configuration);
        const role1 = role.reverse();
        setRoleList(role1);
        setLoader(false);
    }

    useEffect(() => {
        RolesGet();
    }, []);

    const matchConfigurationWithName = (roleList, configuration) => {
        return roleList.map(role => {
            return {
                ...role,
                access: role.access.map(accessItem => {
                    const matchingConfig = configuration.config.access.find(configItem => configItem._id === accessItem);
                    return {
                        _id: accessItem,
                        label: matchingConfig ? matchingConfig.label : "Unknown Configuration"
                    };
                })
            };
        });
    }

    const deleteAccess = async (id, key) => {
        await delRoleconfig(id);
        setRoleList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    }

    const deleteRoles = async (id, key) => {
        await Harddelroles(id);
        setRoleList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    }

    // Filtered role list based on search term
    const filteredRoleList = roleList.filter(role =>
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = Sort(type, filteredRoleList, objkey)
        console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        // Set sortedUsers when filterHubs changes
        setSortedUsers(filteredRoleList);
    }, [roleList, searchTerm]);
    return (
        <div className='mt-2 px-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Roles</Heading>
                        </div>
                        <div >
                            <Input
                                type="text"
                                placeholder="Search by name..."
                                label="Search Role"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className='d-flex mr-1'>
                            <ButtonContainer>
                                <Button onClick={() => setAddRole("data")}>Add Roles</Button>
                            </ButtonContainer>
                        </div>
                    </div>
                </CardHeader>
            </Card>

            {/* Search input field */}


            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col-2 col-sm-2  col-lg-1'><Heading>S.No</Heading></div>
                    <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>
                        <Heading className="d-flex gap-2">Roles
                            <div className='ml-2'>

                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                        <div className='col-3 col-sm-3 col-lg-4'><Heading>Access</Heading></div>
                        <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-CF-asc' : 'text-CF-' + (sortType.includes('asc') ? 'des' : 'asc')), 'createdFor')}>
                        <Heading className="d-flex gap-2">Created For
                            <div className='ml-2'>

                                {sortType.includes('CF') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>                    <div className='col-2 col-sm-2  col-lg-2 d-flex justify-content-end'><Heading>Action</Heading></div>
                </CardHeader>
            </Card>

           {filteredRoleList.length > 0 ?
    sortedUsers.filter(role => !role.isDeleted).map((roles, key1) => (
        <Card className="overflow-hidden mt-1" key={roles._id}>
            <CardHeader className='row m-0 align-items-center'>
                <div className='col-1 col-sm-2 col-lg-1'>{key1 + 1}</div>
                <div className='col-2 col-sm-2 col-lg-3'>{roles.name}</div>
                <div className='col-2 col-sm-3 col-lg-4'>
                    {roles.access.length ? roles.access.map((acc, index) => (
                        <span key={index}>{acc.label}</span> // Add a unique key here
                    )) : "Access Not Available"}
                </div>
                <div className='col-3 col-sm-3 col-lg-2'>{roles.createdFor ? roles.createdFor : "Generic"}</div>
                <div className='col-2 col-sm-2 col-lg-2 d-flex justify-content-end' style={{ width: "100px" }}>
                    <ButtonContainer className="p-2 btn-light" flat="light">
                        <Button>
                            <i className="lt lt-edit" onClick={() => setAddRole(roles)}></i>
                        </Button>
                        <Button>
                            <i className="lt lt-trash" onClick={() => setConfirmPopup({ data: roles._id, key1 })}></i>
                        </Button>
                        <Button>
                            <i className="lt lt-trash text-danger" onClick={() => setConfirmedPopup({ data: roles._id, key1 })}></i>
                        </Button>
                    </ButtonContainer>
                </div>
            </CardHeader>
        </Card>
    ))
    : null}

            {addRole && (
                <Mypopup title={addRole._id ? "Edit Role" : "Add Role"} onAddRole={() => setAddRole(null)} onClose={() => setAddRole(null)}>
                    <AddrolePopup data={addRole} onResPopup={(e) => setMsgPopup(e)} onAddRole={() => { setAddRole(null); RolesGet() }} />
                </Mypopup>
            )}

            {confirmPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmPopup(null)}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}

            {confirmedPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmedPopup(null)}>
                    <p className="p-3">{ResponseMessageLocalpermenant.confirmMessageHardDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteRoles(confirmedPopup.data, confirmedPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmedPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}
        </div>
    )
}

export default Devices;
